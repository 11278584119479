body {
  overflow-x: hidden;
}

:root {
  --anchor-link-color--: #000;
  --text-dark--: #000;
  --text-darklight--: #575759;
  --text-white--: #fff;
  --text-danger--: #ff0101;
  --bg-white--: #fff;
  --box-shadow--: 0px 8px 10px 0px rgba(0, 0, 0, 0.15);
  --customer-say-box-shadow--: 5px 12px 12px 0px rgba(0, 0, 0, 0.1);
  --form-border--: #ccc;
  --font-weight-600--: 600;
  --font-weight-400--: 400;
  --font-weight-700--: 700;
  --title-color--: #01358f;
  --btn-bg--: #01358f;
  --anchor-hover--: #01358f;
  --form-boder-gray--: #d9d9d9;
  --btn-bg-danger--: #ff0101;
  --text-gray--: #626465;
  --border-bottom-gray--: #d9d9d9;
  --footer-link--: #7a7a7a;
  --subscribe-btn-bg--: #01358f;
  --text-blue--: #01358f;
  --border-gray--: #929292;
  --text-dark-blue--: #01358f;
  --book-now-bg--: #dae0fc;
}
ul {
  list-style: none;
  padding: 0;
}
a {
  text-decoration: none;
  color: var(--anchor-link-color--);
}
a:hover {
  color: var(--anchor-hover--) !important;
  text-decoration: none;
}
.text-danger1 {
  color: var(--text-danger--) !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}
p {
  margin-bottom: 0;
  font-size: 16px;
}
h1 {
  font-size: 36px;
}
h2 {
  font-size: 30px;
}
.card {
  border: 0;
  box-shadow: var(--box-shadow--);
  border-radius: 0 !important;
  box-shadow: none !important;
}

.card-header,
.card-footer,
.card-title,
.card-body {
  border: 0;
  border-radius: 0;
  background: transparent;
  padding: 0;
}

.accordion .accordion-item {
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid var(--border-gray--);
}

.accordion .accordion-button:focus {
  border-radius: 0;
  box-shadow: none;
  border: 0;
}
.accordion .accordion-button:not(.collapsed) {
  background: transparent;
  box-shadow: none;
}
.over-hid {
  padding: 0 !important;
}
.dropdown-menu {
  z-index: 9999;
}
.form-control,
.form-control:focus {
  box-shadow: none;
  border: 1px solid var(--form-border--);
}
.btn,
.btn-primary {
  background: var(--btn-bg--);
  border: 1px solid var(--form-boder-gray--);
  color: #fff !important;
  box-shadow: none;
  margin: 0 2px 4px 0;
}

.btn:focus,
.btn:hover,
.btn:active {
  background: var(--btn-bg--);
  border: 0;
  box-shadow: none !important;
}

.btn:not(.btn-check) + .btn:active {
  border: 0;
}
/* INDEX */

/* NAV */
.coollah_header_wrapper {
  /* height: 100%; */
  min-height: 70px;
  padding: 0 35px 0 69px;
  /* z-index: 9999; */
  background: var(--bg-white--);
  box-shadow: none;
}

.navbar-toggler-icon {
  background-image: url(../assets/images/logo/tog.png);
  background-repeat: no-repeat;
  width: 32px;
}
.navbar-toggler:focus {
  box-shadow: none;
}

.collah_header_search_wrapper {
  position: relative;
  /* min-width: 260px; */
  margin-left: 57px;
  margin-top: 8px;
  /* margin-right: 57px; */
}

.collah_header_search_wrapper > input[type="text"] {
  border-radius: 30px;
  height: 50px;
  background: var(--bg-white--);
  /* width: 260px; */
}

.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 8px 12px 8px 12px !important;
}

.collah_header_search_wrapper .auto_complete_search {
  width: 260px;
}
.mb-0{margin-bottom: 0!important;}
.collah_header_search_wrapper > svg {
  position: absolute;
  top: 16px;
  right: 16px;
}

.coollah_header_nav_links {
  display: flex;
  /* width: 100%;
  max-width: 729px;
  justify-content: space-evenly; */
}

.coollah_header_nav_links > li > a {
  color: var(--text-darklight--);
  /* font-family: "Inter"; */
  font-size: 15px;
  font-style: normal;
  /* font-weight: var(--font-weight-600--); */
  line-height: normal;
  margin-bottom: 0;
  height: 70px;
  align-items: center;
  display: flex !important;
  transition: all 0.5s ease;
}

.collah_nav_hover_link:hover {
  /* background-color: var(--anchor-hover--); */
  color: var(--text-blue--) !important;
  transition: all 0.5s ease;
}

.collah_nav_hover_active,
.collah_nav_hover_active:hover {
  background-color: var(--anchor-hover--);
  color: var(--text-white--) !important;
  transition: all 0.5s ease;
}
/* NAV */
.navbar .dropdown-menu a {
  padding: 10px;
  font-size: 15px !important;
  font-weight: 300;
}
.navbar .dropdown-menu a:not(.active) {
  color: var(--text-darklight--);
}
/* .row > * {
  margin-top: 5px;
} */
.mt-3 {
  margin-top: 15px;
}
/* BANNER */

.collah_banner_sec {
  position: relative;
}
.coollah_banner_img_sec {
  width: 100%;
  height: 506px;
  margin-top: 70px;
}
.coollah_contact_banner_sec {
  width: 100%;
  margin-top: 70px;
}
.coollah_banner_img_sec > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.7);
}

.collah_banner_content {
  position: absolute;
  top: 142px;
  left: 178px;
  max-width: 436px;
}

.collah_banner_content > * {
  color: var(--text-white--);
}

.collah_banner_content > h5 {
  color: var(--text-white--);
  font-size: 18px;
  font-style: normal;
  font-weight: var(--font-weight-600--);
  line-height: normal;
}

.collah_banner_content > h2 {
  color: var(--text-white--);
  font-size: 35px;
  font-style: normal;
  font-weight: var(--font-weight-600--);
  line-height: normal;
  margin-top: 21px;
}

.collah_banner_content > .btn {
  width: auto;
  border-radius: 6px;
  padding: 15px 60px;
  border: 0;
  margin-top: 9px;
  align-items: center;
  /* display: flex; */
  justify-content: center;
}

.collah_banner_content > .btn:hover,
.collah_banner_content > .btn:active {
  color: var(--text-white--) !important;
  background-color: var(--btn-bg--);
  border: 0;
}

.coollah_banner_card_sec {
  width: 100%;
  max-width: 1113px;
  margin: auto;
  border-radius: 30px !important;
  /* position: absolute;
  bottom: -60px;
  left: 100px; */
  margin-top: -70px;
  padding: 21px 0px 30px 0px;
  box-shadow: var(--box-shadow--) !important;
}

/* MAP */
.map-frame {
  width: 100%;
  height: 433px;
}

.coollah_map_sec_wrapper {
  margin-top: 73px;
  margin-bottom: 47px;
}
.coollah_map_sec_wrapper1 {
  margin-bottom: 47px;
}
.coollah_freq_ask_sec_wrapper {
  margin-bottom: 47px;
}
/* MAP */

.coollah_banner_services_card_sec {
  display: block;
}
.coollah_services_img_sec {
  display: block;
  width: 100px;
  margin: auto;
}
.coollah_services_img_sec > img {
  width: 100%;
}
.coollah_banner_services_card_sec > a > p {
  text-align: center;
  color: var(--text-gray--);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 5px;
}
/* BANNER */

/* FIND-SERVICE-SEC */

.coollah_service_provider_sec {
  margin-top: 91px;
}

.coollah_find_service_title p {
  color: var(--text-blue--);
  font-size: 25px;
  font-style: normal;
  font-weight: var(--font-weight-600--);
  line-height: normal;
  text-align: center;
}

.coollah_service_provider_sec p {
  /* border-top:1px solid black; */
  position: relative;
}

.coollah_service_provider_img_sec {
  margin-top: 43px;
}

.coollah_find_service_title {
  margin-bottom: 30px;
}

.coollah_find_service_title:nth-child(2) > p::before {
  content: "";
  border-bottom: 1px solid red;
}

.coollah_find_serv_sec_1 {
  width: 100%;
  max-width: 213px;
  background-image: url(../assets/images/find-services/icon-bg\ 1.png);
  background-repeat: no-repeat;
  min-height: 200px;
  position: relative;
}

.coollah_find_serv_sec_1 > img {
  position: absolute;
  /* left: 46px;
  top: 40px; */
  left: 0px;
  top: 0px;
  width: 100%;
  object-fit: scale-down;
}

.coollah_find_serv_sec_conten_sec {
  margin-top: 19px;
}

.coollah_find_serv_sec_conten_sec > h6 {
  color: var(--text-blue--);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: var(--font-weight-600--);
  line-height: normal;
}
.coollah_find_serv_sec_conten_sec > p {
  color: var(--text-gray--);
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: var(--font-weight-400--);
  line-height: normal;
  margin-top: 9px;
  margin-bottom: 0;
}

/* FIND-SERVICE-SEC */

/* ACHIEVEMENT-AWARD */
.coollah_achievement_sec_wrapper {
  margin-top: 40px;
}

.coollah_achieve_img_sec ul {
  flex-wrap: wrap;
  display: flex;
  width: 100%;
  /* gap: 39px; */
}

.coollah_service_provider_img_sec {
  flex-wrap: wrap;
  display: flex;
  width: 100%;
}

.coollah_service_provider_img_sec > .find_serv_sec:nth-child(1),
.coollah_service_provider_img_sec > .find_serv_sec:nth-child(3),
.coollah_service_provider_img_sec > .find_serv_sec:nth-child(5) {
  flex: 0 1 22%;
  width: 22%;
}

.coollah_service_provider_img_sec > .find_serv_sec:nth-child(2),
.coollah_service_provider_img_sec > .find_serv_sec:nth-child(4) {
  flex: 0 1 16%;
  width: 16%;
}

.coollah_achieve_img_sec ul li {
  flex: 0 1 20%;
  width: 20%;
}

.coollah_achievement_sec p {
  color: var(--text-blue--);
  font-size: 25px;
  font-style: normal;
  font-weight: var(--font-weight-600--);
  line-height: normal;
  text-align: center;
}
/* ACHIEVEMENT-AWARD */
.service-list1 {
  margin-top: 70px;
}
.list-who {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  padding: 0;
}
.list-itema {
  display: flex;
  width: 100%;
}
.service-list h4 {
  font-size: 26px;
  font-weight: 600;
  color: #01358f;
  margin-bottom: 10px;
}
.service-list h5 {
  font-size: 20px;
  font-weight: 600;
  color: #292828;
  margin-bottom: 4px;
}
.service-list h5 span {
  color: #01358f;
}
.service-list p {
  font-size: 16px;
  font-weight: 400;
  color: #292828;
  margin-bottom: 8px;
}
.service-list .button-boo {
  padding: 10px 22px;
  margin: 8px 0;
  display: inline-table;
  color: #fff;
  background: #ff0101;
  border: 0;
  border-radius: 30px;
}
.service-list .button-boo:hover {
  color: #fff!important;
  background: #b81515;
}

/* HAPPY-CUSTOMERS */
.coollah_happy_customer_sec_wrapper {
  background-color: var(--subscribe-btn-bg--);
  margin-top: 52px;
  padding: 34px 0 14px 0;
}

.coollah_happer_customer_sec {
  margin: 10px 0px 0px 0px;
  text-align: center;
}

.coollah_happy_cus_img_wrap {
  width: 100px;
  height: 100px;
  display: flex;
  margin: auto;
}

.coollah_happy_cus_img_wrap > img {
  width: 100%;
}

.coollah_happy_cus_content_sec {
  margin-top: 10px;
  margin-bottom: 24px;
}

.coollah_happy_cus_content_sec > h2 {
  color: #8ac3ff;
  font-size: 40px;
  font-style: normal;
  font-weight: var(--font-weight-600--);
  line-height: normal;
}

.coollah_happy_cus_content_sec > p {
  color: var(--text-white--);
  font-size: 20px;
  font-style: normal;
  font-weight: var(--font-weight-600--);
  line-height: normal;
}
/* HAPPY-CUSTOMERS */

/* WHY-CHOOSE-COOLLAH */
.collah_why_choose_coollah_sec {
  margin-top: 93px;
}

.coollah_why_choose_title > h5 {
  color: var(--text-blue--);
  font-size: 25px;
  font-style: normal;
  font-weight: var(--font-weight-600--);
  line-height: normal;
  text-align: center;
}
.coollah_why_choose_title > p {
  line-height: normal;
  text-align: center;
}
.collah_why_choose_coollah_img_sec {
  width: 100%;
  height: 100%;
  min-height: 550px;
}

.why-choose_coollah_margin {
  margin-top: 25px;
}
.collah_why_choose_coollah_img_sec > img {
  width: 100%;
  margin-top: 10px;
}
.coollah_why_choose_content_img_sec {
  width: 90px;
  height: 90px;
  background-color: var(--btn-bg--);
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  padding: 10px;
  margin: 0 15px;
}
.coollah_test {
  width: 30px;
  height: 30px;
  background-color: var(--btn-bg--);
  align-items: center;
  color: var(--text-white--);
  display: flex;
  justify-content: center;
  border-radius: 50px;
  padding: 6px;
  margin: 0;
}
.service-list{display: inline-flex;flex-direction: column;margin-bottom: 40px;/* padding-bottom: 20px; */position: static;}
.bo-set{bottom: 35px;position: absolute;}
.coollah_why_choose_content > h6 {
  color: var(--text-gray--);
  font-size: 20px;
  font-style: normal;
  font-weight: var(--font-weight-600--);
  line-height: normal;
}

.collah_why_choose_coollah_content_sec {
  margin-top: 15px;
}

.coollah_why_choose_content > p {
  margin-top: 6px;
  color: var(--text-gray--);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
/* WHY-CHOOSE-COOLLAH */

/* CUSTOMER_SAYS */
.coollah_customer_services_sec {
  margin-top: 99px;
}

.coollah_cus_serv_title {
  text-align: center;
}

.coollah_cus_serv_title h6 {
  color: #929292;
  font-size: 15px;
  font-style: normal;
  font-weight: var(--font-weight-600--);
  line-height: normal;
  text-transform: uppercase;
}

.coollah_cus_serv_title h2 {
  color: var(--text-blue--);
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* HAPPY-CUSTOMERS */
.coollah_cust_serv_slider {
  margin-top: 24px;
}

.coollah_cust_serv_slider .swiper-wrapper .swiper-slide {
  padding: 10px;
}

.coollah_customer_card {
  padding: 18px 32px 45px 25px !important;
  box-shadow: var(--customer-say-box-shadow--) !important;
}

.coollah_customer_rating_sec {
  width: 150px;
  height: 30px;
}

.coollah_customer_rating_sec > img {
  width: 100%;
}

.coollah_customer_card {
  border-radius: 0px 0px 80px 0px !important;
  background-color: var(--bg-white--) !important;
}
.coollah_customer_card .card-header > p {
  color: var(--text-gray--);
  font-size: 18px;
  font-style: normal;
  font-weight: var(--font-weight-600--);
  line-height: normal;
  margin-top: 9px;
}

.coollah_customer_card .card-body {
  margin-top: 3px;
}

.coollah_cust_serv_content > p {
  color: var(--footer-link--);
  font-size: 13px;
  font-style: normal;
  font-weight: var(--font-weight-400--);
  line-height: 22px; /* 169.231% */
}

.coollah_cus_ser_wrapper {
  display: flex;
  gap: 19px;
  align-items: center;
  margin-top: 9px;
}
.coollah_cus_ser_wrapper > h5 {font-size:16px;}
.coollah_cus_ser_wrapper > p {
  color: var(--text-gray--);
  font-size: 13px;
  font-style: normal;
  font-weight: var(--font-weight-400--);
  line-height: 22px; /* 169.231% */
}

.coollah_cust_serv_card_footer_img {
  width: 38px;
  height: 38px;
  border-radius: 38px;
  border: 2px solid var(--subscribe-btn-bg--);
}

.coollah_cust_serv_card_footer_img > img {
  width: 100%;
  clip-path: circle(50%);
}

.coollah_swiper_btn_next {
  position: absolute;
  right: 0;
  top: 40%;
  z-index: 1;
  border: 0;
  background: var(--text-blue--);
  width: 45px;
  height: 45px;
  border-radius: 10px;
}

.coollah_swiper_btn_next > svg,
.coollah_swiper_btn_prev > svg {
  fill: var(--bg-white--);
}

.coollah_swiper_btn_prev {
  position: absolute;
  left: 0;
  top: 40%;
  z-index: 1;
  border: 0;
  background: var(--text-blue--);
  width: 45px;
  height: 45px;
  border-radius: 10px;
}
/* HAPPY-CUSTOMERS */

/* GURANTEE */
.coollah_gurantee_sec_wrapp {
  background: var(--subscribe-btn-bg--);
  margin-top: 99px;
}

.coollah_gurantee_sec {
  padding: 26px 157px 24px 143px;
}

.coollah_gurantee_img_sec {
  width: 100%;
  max-width: 420px;
  height: 300px;
  align-items: center;
  display: flex;
}

.coollah_gurantee_content_sec {
  width: 100%;
  max-width: 469px;
}

.coollah_gurantee_content_sec > p {
  color: var(--text-white--);
  opacity: 0.9;
  font-size: 18px;
  font-style: normal;
  font-weight: var(--font-weight-400--);
  line-height: 26px; /* 144.444% */
  margin-top: 19px;
}

.coollah_gurantee_content_sec > h6 {
  color: var(--text-white--);
  font-size: 25px;
  font-style: normal;
  font-weight: var(--font-weight-600--);
  line-height: normal;
}

.coollah_gurantee_img_sec > img {
  width: 100%;
  border-radius: 40px;
}
/* GURANTEE */

/* OUR-SUPPORTS */
.coollah_major_support_sec_wrapper {
  margin-top: 84px;
}

.coollah_major_support_title_sec > h5 {
  color: var(--text-blue--);
  font-size: 25px;
  font-style: normal;
  font-weight: var(--font-weight-600--);
  line-height: normal;
  text-align: center;
}

.coollah_major_support_sec {
  margin: 64px 0px 44px 0px;
}

.coollah_major_support_img_wrap {
  width: 100%;
  height: 80px;
}

.coollah_major_support_sec .coollah_major_support_img_wrap img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
/* OUR-SUPPORTS */

/* APP-STORE */
.coollah_app_store_sec {
  padding: 62px 0px 54px 89px;
}

.coollah_app_store_sec_wrapper {
  background-image: url(../assets/images/app-store/app-store.png);
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.coollah_app_store_content_sec > h6 {
  color: var(--text-white--);
  font-size: 25px;
  font-style: normal;
  font-weight: var(--font-weight-600--);
  line-height: normal;
}

.coollah_app_store_content_sec > p {
  color: var(--text-white--);
  font-size: 16px;
  font-style: normal;
  font-weight: var(--font-weight-600--);
  line-height: 22px; /* 157.143% */
  opacity: 0.9;
  margin-top: 22px;
}

.coollah_app_play_store_sec {
  margin-top: 23px;
}

.coollah_app_play_store_sec > .col > img {
  width: 100%;
}
/* APP-STORE */

/* FREQUENT-ASK-SEC */
.coollah_freq_ask_title_sec {
  margin-top: 73px;
}

.coollah_freq_ask_title_sec > h5 {
  color: var(--text-blue--);
  font-size: 25px;
  font-style: normal;
  font-weight: var(--font-weight-600--);
  line-height: 26px; /* 100% */
  text-align: center;
}

.coollah_freq_ask_accoridan_sec {
  /* margin-top: 36px; */
  margin: 36px 232px 0px 213px;
}

.coollah_freq_ask_accoridan_sec
  .accordion
  .accordion-item
  .accordion-header
  button {
  color: var(--text-darklight--);
  font-size: 18px;
  font-style: normal;
  font-weight: var(--font-weight-600--);
  line-height: 26px; /* 144.444% */
}

.coollah_freq_ask_accoridan_sec .accordion .accordion-item .accordion-body p {
  color: var(--text-darklight--);
  font-style: normal;
  font-weight: var(--font-weight-400--);
  line-height: 26px; /* 185.714% */
}

/* FREQUENT-ASK-SEC */

/* LOGIN */
.coollah_back_sec_wrap {
  width: 100%;
  justify-content: end;
  display: flex;
  margin-top: 20px;
}

.coollah_back_sec_wrap button:hover {
  color: var(--anchor-hover--);
}

.coollah_back_sec_wrap button {
  color: var(--text-darklight--);
  font-size: 16px;
  font-style: normal;
  font-weight: var(--font-weight-600--);
  line-height: normal;
  margin-bottom: 0;
  align-items: center;
  display: flex !important;
  transition: all 0.5s ease;
  background: transparent;
  border: 0;
}
.coollah_login_card_col {
  width: 100%;
  height: 730px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coollah_login_sec_wrapper {
  background-image: url(../assets/images/login/login-bg.png);
  width: 100%;
  height: 730px;
  background-size: cover;
  background-repeat: no-repeat;
}

.coollah_login_card {
  width: 100%;
  max-width: 600px;
  /* height: 500px; */
  border-radius: 0;
  padding: 46px 85px 40px 86px;
}

.coollah_login_card .card-title h2 {
  color: var(--title-color--);
  font-size: 28px;
  font-style: normal;
  font-weight: var(--font-weight-600--);
  line-height: normal;
  text-align: center;
}

.coollah_login_card_body {
  margin-top: 29px;
}

.coollah_login_form_input_wrapper {
  position: relative;
  margin-bottom: 13px;
}

.coollah_login_form_input_wrapper > input {
  width: 100%;
  border-radius: 20px;
  border: 1px solid var(--form-boder-gray--);
  padding: 14px 0px 14px 60px;
}

.coollah_login_form_input_wrapper > input:focus-visible {
  /* border: 1px solid var(--form-boder-gray--); */
  outline: none;
}

.coollah_login_form_input_wrapper > img {
  position: absolute;
  left: 26px;
  top: 14px;
  height: 18px;
}

.coollah_login_btn_sec {
  width: 100%;
}

.coollah_login_btn_sec > button:first-child {
  border-radius: 20px;
  width: 100%;
  height: 45px;
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: var(--font-weight-600--);
  margin-bottom: 14px;
  box-shadow: none;
}

.coollah_login_btn_sec > a {
  border-radius: 20px;
  background: var(--btn-bg-danger--);
  width: 100%;
  height: 45px;
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: var(--font-weight-600--);
  line-height: normal;
  margin-bottom: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coollah_login_btn_sec > a:hover {
  border: 0;
  color: var(--text-white--) !important;
}

.coollah_login_btn_sec > button:nth-child(2):active {
  background: var(--btn-bg-danger--);
  color: #fff;
}

.collah_login_link_sec {
  display: flex;
  justify-content: space-between;
}

.collah_login_link_sec > a {
  color: var(--anchor-link-color--);
  font-size: 14px;
  font-style: normal;
  font-weight: var(--font-weight-400--);
  line-height: normal;
}
/* LOGIN */

/* MEMBER REGISTRATION*/

.coollah_register_card {
  display: flex;
  width: 100%;
  max-width: 909px;
  /* height: auto; */
  flex-shrink: 0;
  margin: 112px 186px 118px 185px;
  padding: 25px 0px;
  background: var(--bg-white--);
  box-shadow: var(--register-box-shadow);
}

.coollah_register_card_col {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coollah_register_card .card_title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 38px;
}

.coollah_register_card .card_title h2 {
  color: var(--title-color--);
  font-size: 28px;
  font-style: normal;
  font-weight: var(--font-weight-600--);
  line-height: normal;
}

.coollah_register_card_body {
  margin-top: 29px;
}

.coollah_row_gaps {
  --bs-gutter-x: 24px;
  --bs-gutter-y: 1px;
  padding: 0px 35px 0px 40px;
}

.coollah_register_form_input_wrapper {
  width: 100%;
  max-width: 400px;
  /* height: 50px; */
  gap: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.coollah_register_form_input_wrapper > input,
.coollah_register_form_input_wrapper > select {
  width: 100%;
  border-radius: 24px;
  border: 1px solid var(--form-boder-gray--);
  padding: 15px 20px 15px 60px;
  margin-bottom: 11px;
  height: 100%;
}

.coollah_register_form_input_wrapper > img {
  width: 18px;
}

.coollah_register_form_input_wrapper > input:focus-visible {
  /* border: 1px solid var(--form-boder-gray--); */
  outline: none;
}

.coollah_register_form_input_wrapper > img {
  position: absolute;
  left: 26px;
  top: 16px;
}

.coollah_register_btn_sec {
  width: 400px;
  height: 100%;
}

.coollah_register_btn_sec {
  border-radius: 20px;
  width: 400px;
  height: 50px;
  color: var(--bg-white--);
  font-size: 16px;
  font-style: normal;
  font-weight: var(--font-weight-600--);
  line-height: normal;
  /* margin-bottom: 14px; */
  /* border: 1px solid #d9d9d9; */
}

.coollah_register_card .coollah_register_btn_sec > button {
  background-color: var(--btn-bg--) !important;
  border-radius: 20px;
  border: 1px solid #d9d9d9;
}

.coollah_register_card .register_text {
  color: var(--anchor-hover--);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.coollah_register_link_sec > p > a {
  color: var(--btn-bg-danger--);
  font-size: 14px;
  font-style: normal;
  font-weight: var(--font-weight-600--);
  line-height: normal;
  padding-bottom: 35px;
}
/* MEMBER REGISTRATION*/

/* FOOTER */
footer {
  background: #fff;
  padding: 40px 0 0;
}
.coollah_footer_sec_wrapper {
  margin: 0 0 30px 0;
}

.coollah_mini_footer_sec_wrapper {
  margin: 30px 69px 0px 69px;
}

.coollah_mini_footer_sec {
  background-color: var(--subscribe-btn-bg--);
}

.coollah_mini_footer_copy_right_sec {
  margin: 25px 0px;
}

.coollah_mini_footer_copy_right_sec > p {
  color: var(--text-white--);
  font-size: 15px;
  font-style: normal;
  font-weight: var(--font-weight-400--);
  line-height: 25px; /* 166.667% */
}

.coollah_footer_social_link {
  margin-top: 8px;
}

.coollah_footer_social_link ul {
  display: flex;
  gap: 10px;
}

.coollah_footer_social_link ul li a {
  width: 35px;
  display: flex;
  height: 35px;
  justify-content: center;
  color: var(--text-white--);
  border-radius: 5px;
  align-items: center;
  text-align: center;
}

.coollah_footer_social_link ul li:first-child a {
  background: #3b5998;
}

.coollah_footer_social_link ul li:nth-child(2) a {
  background: #000000;
}

.coollah_footer_social_link ul li:nth-child(3) a {
  background: #007bb6;
}
.coollah_footer_social_link ul li:nth-child(4) a {
  background: #db4a39;
}

.coollah_footer_social_link ul li:nth-child(5) a {
  background: #a70202;
}

.coollah_footer_social_link ul li:nth-child(6) a {
  background: #f02009;
}


.coollah_footer_social_link ul li:hover {
  box-shadow: var(--box-shadow--);
  width: 40px;
  height: 40px;
}

.coollah_footer_social_link ul li a:hover i {
  color: var(--text-white--);
}

/* sec-1 */
.coollah_footer_sec_1 > p {
  color: var(--text-gray--);
  font-size: 12px;
  font-style: normal;
  font-weight: var(--font-weight-400--);
  line-height: 20px;
}
/* sec-1 */

/* sec-2 */
.coollah_footer_sec_2 > h6 {
  color: var(--text-gray--);
  font-size: 20px;
  font-style: normal;
  font-weight: var(--font-weight-700--);
  line-height: normal;
  border-bottom: 2px solid var(--border-bottom-gray--);
  padding-bottom: 14px;
}

.coollah_footer_services_sec_2 {
  margin-top: 6px;
}

.coollah_footer_services_sec_2 > ul > li > a {
  color: var(--footer-link--);
  font-size: 15px;
  font-style: normal;
  font-weight: var(--font-weight-400--);
  line-height: 25px;
}
/* sec-2 */

/* sec-3 */
.coollah_footer_sec_3 > h6 {
  color: var(--text-gray--);
  font-size: 20px;
  font-style: normal;
  font-weight: var(--font-weight-700--);
  line-height: normal;
  border-bottom: 2px solid var(--border-bottom-gray--);
  padding-bottom: 14px;
}

.coollah_footer_services_sec_3 {
  margin-top: 17px;
}

.coollah_footer_subscribe_sec {
  width: 100%;
  max-width: 409px;
}

.coollah_footer_subscribe_sec .input-group {
  height: 55px;
}

.coollah_footer_subscribe_sec .input-group > input[type="email"] {
  border-radius: 10px;
  padding: 19px 0px 19px 28px;
  color: var(--text-darklight--);
  font-size: 14px;
  font-style: normal;
  font-weight: var(--font-weight-400--);
  line-height: normal;
  /* width: 100%;
  max-width: 249px; */
  height: 55px;
}

.coollah_footer_subscribe_sec .input-group > input[type="email"]::placeholder {
  color: var(--text-darklight--);
  font-size: 14px;
  font-style: normal;
  font-weight: var(--font-weight-400--);
  line-height: normal;
}

.coollah_footer_subscribe_sec > form > .input-group > button {
  width: 160px;
  border-radius: 10px;
  border: 1px solid var(--form-border--);
  background: var(--subscribe-btn-bg--);
  color: var(--text-white--);
}

.coollah_footer_subscribe_sec > form > .input-group > button:active {
  background: var(--subscribe-btn-bg--);
  color: var(--text-white--);
  border: 1px solid var(--form-border--);
}

.coollah_app_store_wrapp {
  margin-top: 7px;
}

.coollah_app_store_wrapp > h6 {
  color: var(--footer-link--);
  font-size: 15px;
  font-style: normal;
  font-weight: var(--font-weight-400--);
  line-height: 25px;
}

.coollah_mini_footer_payment_col_sec {
  display: flex;
  justify-content: end;
  align-items: center;
}
/* FOOTER */

/* OTP */
.coollah_login_form_input_wrapper .row input[type="password"] {
  width: 100%;
  max-width: 56px;
  height: 60px;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  text-align: center;
  font-size: 100px;
  padding-top: 15px;
}

.coollah_login_form_input_wrapper .row {
  display: flex;
  gap: 12px;
  justify-content: center;
}

.coollah_login_form_input_wrapper .row input[type="password"]:focus-visible {
  outline: none;
}
/* OTP */

/* BOOK-NOW-SECTION */

.coollah_booking_sec_wrapper {
  position: relative;
  width: 100%;
  /* min-height: 880px; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(./images/booknow/book_bg.png);
  background-color: lightgrey 50%;
  background-color: var(--book-now-bg--);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 70px;
  padding-top: 80px;
  padding-bottom: 100px;
}

.coollah_booking_man {
  width: 100%;
  max-width: 525px;
  /* height: 716px; */
  position: absolute;
  top: 166px;
  right: 699px;
  bottom: 2px;
  left: 56px;
  background-image: url(./images/booknow/booking_man.png);
  background-repeat: no-repeat;
  background-position: center;
  background-position: cover;
}
.coollah_booking_card_sec {
  display: flex;
  justify-content: start;
  align-items: start;
}
.coollah_booking_card {
  width: 85%;
  /* min-height: 666px; */
  left: 30px;
  flex-shrink: 0;
  border-radius: 40px !important;
  background: rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.25) !important;
  position: relative;
}
.coollah_booking_card_title {
  color: #165fac;
  font-size: 28px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  margin-bottom: 23px;
}
.coollah_booking_card_body {
  padding: 31px 27px 18px 30px;
}

.coollah_booking_row_gaps {
  padding: 43px 27px 0px 30px;
}

.coollah_booking_label,
.coollah_booking_unreq_label {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 8px 0px 2px 14px;
  margin-bottom: 2px;
}
.coollah_booking_label:after {
  content: "*";
  color: #ff0101;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
}

.coollah_booking_unreq_label:after {
  content: "";
  color: #ff0101;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
}
.coollah_booking_input,
.coollah_booking_select_input {
  width: 100%;
  /* height: 40px;margin-right: 23px;*/
  flex-shrink: 0;
  border-radius: 16px;
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 12px 10px 12px 18px;
}

.coollah_booking_input:focus,
.coollah_booking_select_input:focus,
.coollah_booking_textarea:focus {
  outline: none;
}
.coollah_booking_input::placeholder,
.coollah_booking_textarea::placeholder {
  color: #929292;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.coollah_booking_select_input {
  color: #929292;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 10px 10px 18px;
}

.coollah_booking_textarea {
  width: 100%;
  height: 80px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 12px 0px 12px 18px;
}
.coollah_booking_btn {
  width: 400px;
  height: 45px;
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 20px;
  border: 1px solid #d9d9d9;
  background: #165fac;
  margin-top: 29px;
  margin-bottom: 33px;
}

/* BOOK-NOW-SECTION */

/* CONTACT-US-SECTION */
.coollah_contact_us_sec {
  width: 100%;
  max-width: 325px;
  position: absolute;
  right: 110px;
  z-index: 1;
}

.coollah_contact_us_sec .card {
  height: 433px;
  background-color: #01358f;
  position: relative;
  overflow: hidden;
}

.coollah_contact_us_sec .card-img-overlay p {
  color: var(--text-dark-blue--);
  font-size: 25px;
  font-style: normal;
  font-weight: var(--font-weight-700--);
  line-height: normal;
  padding: 20px 80px 0px 0px;
}

.coollah_contact_us_sec .card-header {
  /* background: white;
  height: 283px;
  width: 325px;
  transform: rotate(142deg);
  position: absolute;
  top: -187px;
  left: -32px; */
}

.coollah_contact_us_sec .card-header p {
  text-align: start;
  transform: rotate(218deg);
  position: absolute;
  top: 72px;
  right: 15px;
  color: var(--subscribe-btn-bg--);
  font-size: 25px;
  font-weight: 600;
}

.coollah_contact_us_sec .card-body {
  margin: 35px 14px 17px 26px;
}

.coollah_contact_us_sec .card-body ul li {
  display: flex;
  color: var(--text-white--);
  gap: 10px;
  margin-bottom: 16px;
}

.coollah_contact_us_sec .card-body ul li span p,
.coollah_contact_us_sec .card-body ul li span a {
  color: var(--text-white--) !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 11px;
  display: block;
}

.coollah_contact_us_sec .card-body ul li span a:hover {
  color: var(--text-white--) !important;
}

.coollah_contact_us_sec .card-body ul li .contact_collah_img_wrap {
  width: 55px;
  height: 55px;
}

.coollah_contact_us_sec .card-body ul li span h3 {
  color: var(--text-white--);
  font-size: 16px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* MY-SERVICES */
.collah_services_list_wrapp {
  padding: 20px;
}

/* .coollah_residential_height_wrapper {
  height: 100%;
  max-height: 1790px;
  overflow-y: auto;
  overflow-x: hidden;
} */

.coollah_residential_height_wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
}

.coollah_residential_height_wrapper::-webkit-scrollbar {
  width: 5px;
  background-color: #e0e3eb;
  border-radius: 10px;
}

.order_book_sec_coollah_residential_height_wrappertable_tbody::-webkit-scrollbar-track {
  -webkit-box-shadow: #e0e3eb;
  border-radius: 10px;
}

.coollah_residential_height_wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
}

.coollah_service_circles .bg-dot {
  /* background-image: url(../assets/images/services/bg-dot.png); */
  background-repeat: no-repeat;
  position: absolute;
  top: 35px;
  height: 180px;
  width: 100%;
  z-index: 1;
  left: 234px;
}

.coollah_service_circles {
  margin-bottom: 80px;
}
/* MY-SERVICES */
.coollah_service_sec_wrapper {
  width: 100%;
  height: 600px;
  flex-shrink: 0;
  background: url(./images/services/service-1.jpg),
    lightgray 50% / cover no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}
.coollah_service_sec_wrapper2 {
  width: 100%;
  height: 600px;
  flex-shrink: 0;
  background: url(./images/services/service-2.jpg),
    lightgray 50% / cover no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}
.coollah_service_sec_wrapper3 {
  width: 100%;
  height: 600px;
  flex-shrink: 0;
  background: url(./images/services/service-3.jpg),
    lightgray 50% / cover no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.coollah_service_sec_wrapper4 {
  width: 100%;
  height: 600px;
  flex-shrink: 0;
  background: url(./images/services/service-4.jpg),
    lightgray 50% / cover no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.coollah_service_sec_wrapper5 {
  width: 100%;
  height: 600px;
  flex-shrink: 0;
  background: url(./images/services/service-5.jpg),
    lightgray 50% / cover no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.coollah_service_sec_wrapper6 {
  width: 100%;
  height: 600px;
  flex-shrink: 0;
  background: url(./images/banner/banner-door.png),
    lightgray 50% / cover no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.coollah_service_sec_wrapper7 {
  width: 100%;
  height: 600px;
  flex-shrink: 0;
  background: url(./images/banner/banner-dril.png),
    lightgray 50% / cover no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.coollah_service_sec_wrapper8 {
  width: 100%;
  height: 600px;
  flex-shrink: 0;
  background: url(./images/banner/banner-plumbing.png),
    lightgray 50% / cover no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.coollah_service_main_content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 776px;
  height: 776px;
  top: 180px;
  left: -170px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.75);
}

.coollah_service_absolute_content {
  position: inherit;
  left: 50px;
  bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}
.coollah_service_absolute_content > p {
  width: 353px;
  margin-bottom: 8px;
}

.coollah_service_realtive_div {
  position: relative;
}

.coollah_service_absolute_content > h1 {
  color: #01358f;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.coollah_service_absolute_content > p {
  color: #626465;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.coollah_service_main_form {
  display: flex;
  align-items: start;
  justify-content: center;
}

.coollah_service_main_form > input {
  width: 249px;
  height: 55px;
  outline: none;
  border-radius: 10px 0px 0px 10px;
  border: 1px solid #ccc;
  background: #fff;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.coollah_service_main_form > input::placeholder {
  padding: 19px 54px 19px 28px;
}
.coollah_service_main_form > button {
  width: 160px;
  height: 55px;
  border-radius: 0px 10px 10px 0px;
  border: 1px solid #ccc;
  background: #01358f;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.coollah_service_lhs_wrapper {
  padding-left: 35px;
}

.coollah_service_lhs {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.coollah_service_main_page {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 80px;
  border-radius: 6px;
  border: 1px solid #8ac3ff;
  background: #fff;
}

.coollah_service_main_page > p {
  position: absolute;
  top: -10px;
  right: 192px;
  bottom: 129px;
  left: 40px;
  width: 114px;
  height: 30px;
  flex-shrink: 0;
  color: #000;
  background: white;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.sidebar-sicky {
  position: sticky;
  top: 5%;
}
.coollah_service_explain {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 8px;
}
.coollah_service_explain > img {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #01358f;
}

.coollah_service_explain > p {
  color: #01358f;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
}

.coollah_service_aircon_list {
  position: relative;
}

.coollah_rhs {
  position: relative;
  margin-top: 80px;
}

.coollah_service_services > .nav > .nav-item > .nav-link.active,
.coollah_service_services > .nav-pills > .show > .nav-link {
  border-radius: 10px 10px 0px 0px;
  background-color: #165fac;
}

.coollah_service_services > ul > li > .residental {
  width: 200px;
  height: 40px;
  padding: 12px 23px 11px 23px;
  border-radius: 10px 10px 0px 0px;
  background: #f5f5f5;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.coollah_service_services > ul > li > .commercial {
  /* width: 200px; */
  height: 40px;
  padding: 12px 20px 11px 20px;
  flex-shrink: 0;
  border-radius: 10px 10px 0px 0px;
  background: #f5f5f5;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.coollah_service_chemical {
  /* width: 820px; */
  padding-top: 18px;
  padding-bottom: 10px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  align-items: center;
  justify-content: space-evenly;
}

.coollah_service_chemical_content {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 12px;
  padding-left: 20px;
}
.coollah_service_chemical_content > h3 {
  width: 100%;
  color: #01358f;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 8px;
}
.coollah_service_chemical_content > .heading_border {
  width: 100%;
  height: 2px;
  flex-shrink: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
}
.coollah_service_chemical_content > p {
  color: #626465;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.coollah_service_chemical_content > .price {
  color: #7a7a7a;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 2px;
  padding-bottom: 8px;
}
.coollah_service_chemical_content > .price > span {
  color: #01358f;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.centered_columns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 22px;
  padding-bottom: 28px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.coollah_service_chem_img_container {
  position: relative;
  text-align: center;
}

.coollah_service_chem_img_container > img {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  border: 1px solid #b81515;
}

.coollah_service_chem_img_container > button {
  background: #ff0101;
    border: 1px solid #b81515;
    border-radius: 6px;
    bottom: 15px;
    color: #fff;
    height: 30px;
    outline: none;
    position: relative;
    display: block;
    text-align: center;
    width: 60%;
    margin: 0px 20%;
  /* top: 136px;
  right: 20px;  
  left: 20px; */
}

.coollah_service_container_border {
  width: 820px;
  height: 1px;
  background: rgba(0, 0, 0, 0.2);
}

.coollah_service_over {
  text-align: center;
  padding-top: 88px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.coollah_service_over > h2 {
  color: #1a6dc6;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.coollah_service_doubled_border {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 30px;
}

.coollah_service_doubled_border > .border_first {
  width: 160px;
  height: 4px;
  flex-shrink: 0;
  background: #ff0101;
}

.coollah_service_doubled_border > .border_second {
  width: 147px;
  height: 4px;
  flex-shrink: 0;
  background: #1a6dc6;
}
.coollah_service_card_sec {
  max-width: 1280px;
  width: 100%;
  display: flex;
  margin: 42px 35px 109px 36px;
}
.coollah_service_card_slide {
  /* margin-left: px; */
  position: relative;
}
.coollah_service_card_slide > .card {
  width: 100%;
  max-width: 280px;
  /* height: 420px; */
  min-height: 420px;
  border-radius: 0px 0px 80px 0px;
  border: 1px solid #ccc;
  background: #fff;
}
.coollah_service_card_slide > .card-img-top {
  width: 280px;
  height: 168px;
  flex-shrink: 0;
}

.coollah_service_card_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 17px;
  /* margin: 20px; */
  padding: 17px 20px;
}

.coollah_service_body_wrapper {
  display: flex;
  flex-direction: column;
  gap: 17px;
}
.coollah_service_card_list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
}
.coollah_service_card_list img {
  vertical-align: top;
}
.coollah_service_card_list li {
  position: relative;
  padding-left: 30px;
  line-height: 22px;
  margin-bottom: 4px;
}
.coollah_service_card_list li:before {
  content: url("../assets/images/services/card_svg.png");
  position: absolute;
  font-weight: 300;
  font-size: 14px;
  top: 2px;
  left: 0;
}
.coollah_service_card_list > li {
  list-style: none;
  /* width: 212px; */
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.coollah_service_card_img {
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  margin: 12px;
}

.coollah_service_card_title {
  position: absolute;
  top: 0px;
  left: 0px;
}
.coollah_service_card_title h5 {
  padding: 15px 0 0 12px;
  font-size: 15px;
  color: #fff;
  font-weight: 600 !important;
  position: absolute;
  top: 0;
}
.coollah_service_book {
  background: #01358f;
  position: relative;
}

.coollah_service_booking_man {
  display: flex;
  justify-content: center;
  margin-top: -80px;
  width: 292px;
  height: 213px;
  margin-left: auto;
  margin-right: auto;
}

.coollah_service_booking_man img {
  width: 100%;
}

.coollah_service_connect_form {
  display: grid;
  gap: 18px;
  position: relative;
  margin: 36px 10px;
}

.coollah_service_connect_form > p {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.coollah_service_booking_form {
  display: flex;
}
.coollah_service_booking_form > input {
  width: 100%;
  height: 55px;
  border-radius: 10px 0px 0px 10px;
  background: #fff;
  outline: none;
  border: none;
}

.coollah_service_booking_form > input::placeholder {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 19px 254px 19px 26px;
}

.coollah_service_booking_form > button {
  width: 120px;
  height: 55px;
  flex-shrink: 0;
  outline: none;
  border: none;
  border-radius: 0px 10px 10px 0px;
  background: #b4d8ff;
  color: #01358f;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.coollah_service_started {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.coollah_service_circles {
  position: relative;
  /* background: url(assets/images/services/connection1.png); */
  background-image: url(../assets/images/services/connection1.png);
  background-repeat: no-repeat;
  background-position: top center;
}
.coollah_circle_connector_waves {
  position: absolute;
  top: 90px;
  left: 218px;
  z-index: -1;
}
.coollah_service_circle_location > img,
.coollah_service_circle_calender > img,
.coollah_service_circle_telecalling > img,
.coollah_service_circle_success > img {
  z-index: 9;
}

.coollah_service_started > h2 {
  color: #1a6dc6;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.coollah_service_content {
  display: flex;
  flex-direction: column;
  gap: 11px;
}

.coollah_service_content > h3 {
  color: #01358f;
  font-size: 20px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.coollah_service_content > p {
  /* width: 216px; */
  color: #626465;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.coollah_service_circle_location {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  gap: 20px;
}

.coollah_service_circle_calender {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 70px;
  gap: 20px;
}
.coollah_service_circle_telecalling {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  gap: 20px;
}
.coollah_service_circle_success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  gap: 20px;
}

/* SERVICE */

/* CONTACT-US */
.coollah_contact_content_sec {
  border: 1px solid #ccc;
  background-color: var(--bg-white--);
  padding: 48px 32px 48px 43px;
  width: 100%;
  max-width: 320px;
  text-align: center;
  min-height: 290px;
  margin: auto;
}

.coollah_contact_banner_sec img {
  width: 100%;
}

.coollah_img_sec {
  width: 60px;
  height: 73px;
  margin: auto;
}

.coollah_img_sec img {
  width: 100%;
}

.coollah_contact_content_sec_wrapper {
  margin: 95px 0px 95px 0px;
}

.coollah_contact_contents {
  margin-top: 45px;
}

.coollah_contact_content_sec_wrapper .row {
  row-gap: 20px;
}
.list-who-ban {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  padding: 0;
}
.list-itema-ban {
  display: flex;
  padding: 0.5em;
  width: 16.666667%;
  margin-bottom: 20px;
}
.list-contenta-ban {
  width: 100%;
}
@media (min-width: 320px) and (max-width: 480px) {
  .coollah_service_chem_img_container > button {width: 95% !important;
    margin: 0px 2% !important;}
    .coollah_service_absolute_content > h1 {
    color: #01358f;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.coollah_service_absolute_content > p {
  color: #626465;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 2px 20px 0px 0px;
}
  }
@media only screen and (min-width: 992px) {
.coollah_service_chem_img_container > button {width: 60%;
  margin: 0px 20%;}
  .chat-header{padding: 0px !important;}
  }
  @media only screen and (min-width: 1200px) {
.coollah_service_chem_img_container > button {width: 45%;
  margin: 0px 28%;}
  .list-sec {
    height: 560px !important;}
    .chat-body {
      height: 420px !important;
  }
}


