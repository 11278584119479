body,
html {
  color: rgb(113, 113, 113) !important;
  font-family: "Jost", sans-serif !important;
  font-weight: 400;
  line-height: 1.2em !important;
  overflow-x: hidden;
  background: rgb(250, 250, 250) !important;
}

/* COMMON-HTML-INPUTS */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

a:focus {
  outline: thin dotted;
}

a:active,
a:hover {
  outline: 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: 700;
}

dfn {
  font-style: italic;
}

hr {
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

mark {
  background: #ff0;
}

code,
kbd,
pre,
samp {
  font-size: 1em;
}

pre {
  white-space: pre-wrap;
}

q {
  quotes: "\201C""\201D""\2018""\2019";
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  -webkit-appearance: listbox !important;
}

button,
input {
  line-height: normal;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  top: 6px;
  vertical-align: middle;
}

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1 {
  font-size: 72px;
  /* color: #fff; */
}

h2 {
  font-size: 58px;
  color: #3f4b5e;
}

.main-block {
  padding: 35px 0;
}

header {
  padding: 8px 0 0;
}

select {
  border: 1px solid #ced4da;
  flex: 1;
  padding: 0 0.5em;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  border-radius: 0.45em;
}

h1,
h2,
h3,
h4,
h3,
h6 {
  clear: both;
  padding: 0;
  margin: 0 0 10px 0;
  line-height: 1.2em;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 26px;
}

h3 {
  font-size: 22px !important;
  color: rgb(68, 68, 68);
}

h4 {
  font-size: 20px;
  font-weight: 600 !important;
}

h5 {
  font-size: 18px;
}
h6 {
  font-size: 14px;
}

p {
  margin-bottom: 17px;
  line-height: 24px;
}

ul {
  margin: 0 0 15px 0;
  padding-left: 15px;
  list-style: square outside none;
}

ol {
  padding-left: 15px;
  margin-bottom: 15px;
}

dfn,
cite,
em,
i {
  font-style: italic;
}

blockquote {
  margin: 0 15px;
}

address {
  margin: 0 0 15px;
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
}

a {
  color: inherit;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

a:hover {
  text-decoration: none;
  color: #ff8a73;
}

hr {
  margin: 0;
  border-color: rgba(208, 208, 226, 0.6);
  width: 100%;
}

blockquote {
  margin: 25px 0px;
  background-color: #f9fbff;
  border: 1px solid #d0d0e2;
  border-left: 5px solid #ef7f1a;
  font-size: 18px;
  color: #767676;
  padding: 20px 30px;
  border-radius: 2px;
  line-height: 1.6em;
}

table,
th,
td,
tr {
  border: 1px solid #ddd;
}

table,
tbody,
tr,
td {
  border: 1px solid #ddd !important;
}
thead[data-test="table-foot"] {
  display: none !important;
}

thead[data-test="datatable-head"] {
  text-align: center;
}

strong {
  font-weight: 700;
}
/* COMMON-HTML-INPUTS */

/* BOOTSTRAP-CLASSES */

.table-bordered > thead > tr > th {
  border-bottom: 1px solid #d6d6d6 !important;
  border-bottom-width: 1px !important;
  font-weight: 400;
}

/*BUTTON */
.btn_old{
  font-size: 13px !important;
  margin: 0 1px !important;
}

.float-end .btn-success {
  color: #fff;
  background: #ed0000 !important;
}

.card .btn-primary {
  color: #fff;
  background-color: #014f8b !important;
}

.card1 .btn-secondary {
  color: #fff;
  background-color: #ef5105 !important;
}

.wthree-field button.btn {
  background: #023182;
  border: none;
  color: #fff;
  padding: 11px 15px;
  text-transform: uppercase;
  font-size: 16px;
  width: 100%;
  margin-top: 10px;
  cursor: pointer;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -ms-transition: 0.5s all;
  border-radius: 35px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -ms-border-radius: 35px;
  -o-border-radius: 35px;
}

.wthree-field1 button.btn {
  background: #023182;
  border: none;
  color: #fff;
  padding: 11px 15px;
  margin-left: 170px;
  text-transform: uppercase;
  font-size: 16px;
  width: 300px;
  margin-top: 10px;
  letter-spacing: 2px;
  cursor: pointer;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -ms-transition: 0.5s all;
  border-radius: 35px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -ms-border-radius: 35px;
  -o-border-radius: 35px;
}

.btn-info {
  background-color: #023182;
  border-color: #023182;
  color: #fff;
}

.btn-warning {
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-info:hover {
  background: #0050db;
}

.wthree button.btn {
  background: #023182;
  border: none;
  color: #fff;
  padding: 11px 15px;
  text-transform: uppercase;
  font-size: 16px;
  width: 50%;
  margin-top: 10px;
  letter-spacing: 2px;
  cursor: pointer;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -ms-transition: 0.5s all;
  border-radius: 35px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -ms-border-radius: 35px;
  -o-border-radius: 35px;
}

.btn-group {
  width: 100%;
}

.btn-group input {
  border: 1px solid #e8e8e8;
}

.btn-group1 {
  padding: 26px;
  width: 48%;
}

.btn-group2 {
  padding: 26px;
  width: 20%;
}

.btn-success:hover {
  background: #1fb35f;
}

.btn-form {
  font-size: 30px;
  padding: 17px;
  width: 32%;
  background: #45c3d3;
  color: #fff;
  border: solid 1px #2ea8b7;
  cursor: pointer;
}

.btn-form:focus {
  outline: none;
}
/*BUTTON */

/* CONTAINER */
.container {
  padding-top: 1px;
  padding-right: var(--bs-gutter-x, 0.45rem);
  padding-left: var(--bs-gutter-x, 0.45rem);
  margin-top: -1px;
}
/* CONTAINER */

/* PROGRESS */
.progress {
  width: 100%;
  padding: 5px;
  border-radius: 4px;
  display: inline;
  margin: auto;
  align-items: center;
  color: #ffffff;
  border: none;
  outline: none;
  background-color: #6c4ab6;
  justify-content: center;
  background: linear-gradient(
    to right,
    #0041b3 0%,
    #0041b3 40%,
    #fff 40%,
    #fff 100%
  );
  border: solid 2px #82cfd0;
  border-radius: 8px;
  height: 7px;
  width: 400px;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
}

.progress::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-appearance: none;
  cursor: ew-resize;
  background: #434343;
}
/* PROGRESS */

/* TAB NAV-LINKS */
.nav-fill .nav-item,
.nav-fill > .nav-link {
  color: #023182;
  background-color: #aac4f2;
  font-weight: 500;
  border-radius: 30px;
  margin: 0 4px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  font-weight: 600;
  color: #ffffff !important;
  background-color: #ed0000 !important;
  border-color: 0px #dee2e6 #dee2e6 #fff !important;
}

.nav-tabs {
  border-bottom: 0px solid #dee2e6 !important;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
  margin-bottom: 4px !important;
}

.nav {
  display: inline-flex !important;
}
.navbar-light .navbar-nav .nav-link {
  color: #3f4b5e;
  padding-right: 36px;
}

.navbar-light .navbar-nav .active > .nav-link {
  color: #45c3d3;
}
/* TAB NAV-LINKS */

/* UTILITIES */

.ptb-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.ptb-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.ptb-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.ptb-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.mtb-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mtb-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mtb-60 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.mtb-80 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.mb-1 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}

.font-20 {
  font-size: 20px;
  color: #353535;
}

.font-bold {
  font-weight: 600;
}

.text-red {
  color: #ed0000 !important;
}

.bg-cray {
  background: #ebebeb;
}

.bg-cray .img-responsive {
  height: 200px !important;
  width: auto;
}

.bg-white {
  background: #ffffff;
}

.bg-red {
  background: #ed0000;
}

.bg-blue {
  background: #01358f;
  border-bottom: 1px solid #ffffff;
}

.bg-blue h5 {
  margin-bottom: 0 !important;
}

.bg-dark {
  background: #2c2c2c;
}

.font-weight {
  font-weight: 600;
}

.w-100 {
  width: 100% !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.fs-35 {
  font-size: 35px;
}

.font-w600 {
  font-weight: 600;
}

.mr-1 {
  margin-right: 10px;
}
/* UTILITIES */

/* GRID */
.grid {
  position: relative;
  width: 100%;
  max-width: 100%;
  list-style: none;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.grid figure {
  position: relative;
  float: left;
  overflow: hidden;
  min-width: 100%;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.grid figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}
/* GRID */

/* FORM-INPUTS */
.form-group {
  margin-bottom: 8px !important;
}
/* FORM-INPUTS */
