body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* login */
.social-login {
  display: flex;
  justify-content: center; /* Horizontal center */
  align-items: center; /* Vertical center */
 
}


.login-text {
  font-size: 18px; /* Adjust font size as needed */
  margin-bottom: 10px; /* Space between text and buttons */
}

.icon-buttons {
  display: flex; /* Use flexbox to arrange buttons in a row */
  justify-content: center; /* Center buttons */
}

.btnr {
  background-color: transparent; /* Transparent background */
  border: none; /* Remove default button border */
  margin: 0 10px; /* Space between buttons */
  cursor: pointer; /* Change cursor on hover */
}

.btn i {
  font-size: 24px; /* Adjust icon size */
  color: #fff; /* Icon color; adjust as necessary */
}
