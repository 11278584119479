@media only screen and (min-width: 1200px) {
  .st-hero-slide.st-style2 {
    height: 520px;
  }

  .nav-link {
    display: block;
    padding: 0.5rem 1.45rem !important;
    font-size: 13px;
  }

  .btn.btn-sm {
    padding: 0.3rem 0.8rem !important;
    font-size: 0.64rem;
    margin: 0.175rem;
  }

  .btn_back {
    margin: 0.375rem;
    text-transform: capitalize !important;
    word-wrap: break-word;
    white-space: normal;
    cursor: pointer;
    border: 0;
    border-radius: 6px !important;
    box-shadow: none !important;
    padding: 6px 10px !important;
    font-size: 14px;
  }
}

@media only screen and (min-width: 1600px) {
  .nav-link {
    padding: 0.5rem 1rem !important;
    font-size: 15px;
  }

  .btn {
    margin: 0 0.375rem !important;
    text-transform: capitalize !important;
    word-wrap: break-word;
    white-space: normal;
    cursor: pointer;
    border: 0;
    border-radius: 6px !important;
    box-shadow: none !important;
    padding: 6px 10px !important;
    font-size: 14px !important;
  }
  .st-hero-slide.st-style2.st-type1 {
    width: 100%;
    padding-left: 40px;
  }

  .st-hero-slide.st-style2.st-type1 .st-hero-img {
    width: 600px;
    left: 58%;
  }
}

@media only screen and (max-width: 767px) {
  .lrblock ul {
    flex-direction: column;
  }

  .hero-slied .details {
    text-align: left;
    margin: 30px;
  }

  .st-hero-text.st-style1 h1 {
    font-size: 32px;
    padding-top: 30px;
  }
}

@media only screen and (min-width: 768px) {
  .lrblock ul li {
    width: calc(100% / 2);
  }

  .hero-slied .details {
    max-width: 500px;
    text-align: left;
    margin-left: 42px;
  }

  .hero-slied ul li .figure {
    width: 100%;
  }
}

@media only screen and (min-width: 889px) {
  .st-hero-slide.st-style2 {
    height: 510px;
  }
}

@media only screen and (min-width: 600px) {
  .serviceList ul {
    max-width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .serviceNumbers .serviceList {
    margin-bottom: 16px;
  }

  .serviceList ul li.singleService:hover {
    background: #e5e5e5;
  }

  .serviceList ul li.singleService:hover .serviceTitle {
    color: #262626;
  }

  .serviceList ul li.singleService .serviceTitle {
    font-size: 10px;
    line-height: 9.6px;
  }

  .serviceList ul li.singleService:hover a svg path {
    fill: #fff;
  }

  .serviceList ul li.singleService a svg path {
    fill: #bdbdbd;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }

  .serviceTitle:hover {
    color: #fff;
  }

  .serviceList ul li.singleService {
    margin-bottom: 0;
  }

  .serviceList ul li.singleService {
    padding: 13px 0 21px;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }

  .serviceList ul li.singleService .serviceTitle {
    font-size: 15px;
    line-height: 20px;
  }

  .serviceList ul {
    flex-wrap: nowrap;
    padding: 0;
  }

  .serviceList ul li.singleService {
    margin-bottom: 0;
  }

  .serviceList ul li.singleService {
    padding: 10px 6px;
    height: auto;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }

  .serviceIcon img {
    width: 80px;
    height: 80px;
    object-fit: cover;
  }
}

@media screen and (min-width: 991px) {
  .st-mob-nav {
    display: none !important;
  }
}

@media screen and (max-width: 991px) {
  .st-nav-toggle {
    display: block;
  }

  .st-desktop-nav {
    display: none !important;
  }

  .st-header .container {
    max-width: 100%;
  }

  .st-mobile-nav {
    border-top: 1px solid #eaeaea;
  }

  .st-header.st-style2 .st-main-header-in {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media screen and (max-width: 1199px) {
  .owl-carousel.st-owl-controler2 .owl-nav {
    max-width: 960px;
  }

  .st-hero-slide.st-style2.st-type1 {
    height: initial;
    padding-top: 150px;
    text-align: center;
  }

  .st-hero-slide.st-style2.st-type1 .st-btn-group.st-style1 {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .st-hero-slide.st-style2.st-type1 .st-hero-img {
    position: relative;
    -webkit-transform: initial;
    -ms-transform: initial;
    transform: initial;
    left: 0;
    top: 0;
    width: 100%;
    max-width: 800px;
    margin-top: 50px;
    display: block;
  }
}

@media screen and (max-width: 991px) {
  .owl-carousel.st-owl-controler2 .owl-nav {
    max-width: 720px;
  }

  .st-hero-text.st-style1 h1 {
    font-size: 42px;
  }

  .st-hero-text.st-style1 .st-hero-subtitle {
    font-size: 18px;
  }

  .st-btn.st-style1 {
    padding: 0.8em 2.4em;
  }

  .st-btn.st-style2 i {
    font-size: 42px;
  }

  .st-hero-slide.st-style1 {
    height: 600px;
  }

  .owl-carousel.st-owl-controler2 .owl-nav {
    bottom: 20px;
  }

  .st-section-top {
    padding-top: 80px;
  }

  .st-section {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .st-section img {
    border-radius: 8px;
  }

  .st-cta-img {
    display: none;
  }

  .st-section-heading.st-style2 {
    margin-bottom: 40px;
  }

  .st-contact-info-in {
    max-width: 400px;
  }

  .st-section-heading.st-style2 p br {
    display: none;
  }

  .st-contact-form input,
  .st-contact-form textarea {
    max-width: 100%;
  }

  .owl-carousel.st-owl-controler3 .owl-nav {
    display: none !important;
  }

  .owl-carousel.st-owl-controler3 .owl-dots,
  .owl-carousel.st-client-carousel .owl-dots {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .owl-carousel.st-owl-controler3.st-service-carousel .owl-dots {
    margin-top: 15px;
  }

  .st-hero-slide.st-style2.st-type1 {
    border-right-width: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 767px) {
  .owl-carousel.st-owl-controler2 .owl-nav {
    max-width: 540px;
  }

  .st-right-newsletter {
    display: none;
  }

  .st-footer-social-btn li {
    margin: 0 10px;
  }

  .st-iconbox-wrap.st-style1 {
    margin-top: 80px;
  }

  .st-hero-slide.st-style1.st-type2 {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 575px) {
  .st-hero-text.st-style1 br {
    display: none;
  }

  .st-hero-img {
    display: none;
  }

  .st-service-background-shap {
    display: none;
  }

  .st-svg-animation1 {
    display: none;
  }

  .st-contact-info.st-style1 {
    padding: 0;
    min-height: initial;
  }

  .st-hero-slide.st-style2 {
    height: 620px;
  }

  .st-wave-wrap {
    display: none;
  }

  .st-hero-slide.st-style2.st-type1 {
    border: 0;
    padding-left: 0;
    border-radius: 0;
    padding-bottom: 80px;
  }

  .st-full-width .container {
    padding: 0 15px;
  }
}

@media screen and (max-width: 991px) {
  .st-port-col-4 .st-grid-sizer,
  .st-port-col-4 .st-isotop-item {
    width: 50%;
  }

  .st-port-col-4 .st-w50 {
    width: 100%;
  }

  .st-section-heading.st-style1 h2,
  .st-section-heading.st-style2 h2,
  .st-cta-title {
    font-size: 30px;
  }

  .st-about-text,
  .st-btn.st-style1,
  .st-btn.st-style2 {
    font-size: 14px;
  }

  .st-section-heading.st-style2 p,
  .st-cta-text,
  .st-testimonial-text,
  .st-newsletter-text,
  .st-hero-text.st-style1 .st-hero-subtitle {
    font-size: 16px;
  }

  .st-funfact-number,
  .st-price h3 {
    font-size: 50px;
  }

  .st-page-header-text h1 {
    font-size: 30px;
    margin-bottom: 7px;
    margin-top: -5px;
  }

  .st-page-header {
    height: 300px;
  }

  .st-content {
    margin-bottom: 0 !important;
  }

  .st-sticky-footer {
    position: relative;
  }

  .st-hero-slide.st-style2 {
    height: 550px;
  }
}

@media screen and (max-width: 767px) {
  .st-port-col-3 .st-grid-sizer,
  .st-port-col-3 .st-isotop-item {
    width: 50%;
  }

  .st-port-col-5 .st-w50,
  .st-port-col-4 .st-w50,
  .st-port-col-3 .st-w50,
  .st-port-col-2 .st-w50,
  .st-port-col-1 .st-w50,
  .st-port-col-5 .st-w66,
  .st-port-col-4 .st-w66,
  .st-port-col-3 .st-w66,
  .st-port-col-2 .st-w66,
  .st-port-col-1 .st-w66 {
    width: 100%;
  }
}

@media screen and (max-width: 575px) {
  .st-port-col-5 .st-grid-sizer,
  .st-port-col-5 .st-isotop-item {
    width: 100%;
  }

  .st-port-col-4 .st-grid-sizer,
  .st-port-col-4 .st-isotop-item {
    width: 100%;
  }

  .st-port-col-3 .st-grid-sizer,
  .st-port-col-3 .st-isotop-item {
    width: 100%;
  }

  .st-port-col-2 .st-grid-sizer,
  .st-port-col-2 .st-isotop-item {
    width: 100%;
  }

  .st-footer-text br {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .st-right-sidebar {
    padding-left: 0;
  }

  .st-left-sidebar {
    padding-right: 0px;
  }
}

@media screen and (max-width: 575px) {
  .commenst-area .children {
    padding-left: 0;
  }

  .comment-author .avatar {
    height: 50px;
    width: 50px;
  }

  .comment-body {
    margin-left: 65px;
  }

  .children .comment-author .avatar,
  .comment-author .avatar {
    left: -65px;
  }

  .comment-form-author,
  .comment-form-email,
  .comment-form-url {
    width: 100%;
  }

  .comment-form-author {
    padding-right: 0;
  }

  .comment-form-url {
    padding-left: 0;
  }
}

@media screen and (max-width: 991px) {
  .st-black-version .st-nav-list ul a {
    color: rgba(255, 255, 255, 0.7);
  }
}

@media (max-width: 576px) {
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 34px;
  }
  .topics li {
    width: 100%;
    padding-right: 0;
  }
  .sm-box {
    width: 100%;
    max-width: 100% !important;
    -webkit-box-flex: 100% !important;
    -ms-flex: 100% !important;
    flex: 100% !important;
    margin: 0;
  }
  .payment-icon {
    float: none;
    margin: 10px 0 0;
  }
  .guests h6 {
    width: 100%;
  }
  .footer-style2 p {
    width: 100%;
  }
  .landing-2 .slider-content h1 {
    font-size: 40px;
  }
  .landing-2 .slider-content h2 {
    font-size: 70px;
    margin: 20px 0;
  }
  .landing-2 .slider-content a {
    font-size: 18px;
    padding: 17px 16px;
  }
  .landing-2 .slider-content a span {
    font-size: 28px;
  }
  .service-title h2 {
    font-size: 30px;
  }
  .testi-title {
    margin: 27px 0 32px;
  }
  .btn-form {
    padding: 16px;
    font-size: 20px;
    width: 100%;
  }
  .center-block h6 {
    width: 100%;
  }
  .slider-content {
    top: 164px;
    margin: 0 12px;
  }
  .slider-content h3 {
    font-size: 24px;
  }
  .form-wrap {
    margin-top: 50px;
  }
}

@media (max-width: 480px) {
  .countdown li i {
    font-size: 16px;
  }
  .complete-booking {
    font-size: 18px;
  }
  .landing-2 .slider-content h2 .oldprice {
    display: block;
  }
  .landing-2 .slider-content h1 span:after {
    display: none;
  }
  .countdown li {
    font-size: 16px;
  }
  .countdown li p {
    font-size: 16px;
  }
}

@media (max-width: 1200px) {
  .affix {
    margin-right: 20px;
  }
  .preference-title h4 {
    font-size: 20px;
  }
  .our-client ul li {
    width: 20%;
    margin: 0;
  }
}

@media (max-width: 992px) {
  .form-wrap .btn-group {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .form-wrap .btn-form {
    width: 100%;
  }
  .btn-group1 {
    padding: 16px;
    width: 100%;
    margin-bottom: 1rem;
  }
  .btn-group2 {
    padding: 16px;
    width: 100%;
    margin-bottom: 1rem;
  }
  .btn-form {
    font-size: 20px;
    line-height: 1.8;
  }
  .center-block h6 {
    width: 70%;
  }
  .input-group-addon {
    padding: 0.5rem 0.75rem;
  }
  h1 {
    font-size: 52px;
  }
  .speciality-description {
    width: 90%;
  }
  .landing-2 .slider-content h1 {
    font-size: 50px;
  }
  .set-sm-fit {
    max-width: 100%;
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
  }
  .booking h6 {
    width: 80%;
  }
  .subscribe-btn span {
    font-size: 25px;
  }
  .service-title h2 {
    font-size: 38px;
  }
  .testi-block p {
    font-size: 15px;
  }
  .testi-block {
    padding: 12px;
  }
}

@media (max-width: 480px) {
  .our-client ul li {
    width: 100% !important;
  }
  .client-small {
    width: 100% !important;
    margin-bottom: 10px !important;
  }
  .header-style2 span {
    font-size: 19px;
    margin: 6px 0 0;
  }
}

@media (max-width: 320px) {
  .header-style2 img {
    display: block;
  }
  .header-style2 span {
    float: left;
  }
}

@media (max-width: 768px) {
  .landing-2 .slider-content h3:before {
    display: none;
  }
  .booking h6 {
    width: 100%;
  }
  .img-objectfit_1 {
    width: 100%;
    position: inherit;
  }
  .service-link {
    margin-left: 0;
  }
  .howit-wrap {
    margin: 28px 0 0;
  }
  .howit-wrap p {
    padding: 0 20px;
  }
  .img-objectfit {
    width: 100%;
    position: inherit;
  }
  .landing-2 .slider-content h3:after {
    display: none;
  }
  .client-small {
    width: 50% !important;
    margin-bottom: 0 !important;
  }
  .our-client ul li {
    width: 33.3%;
    margin-bottom: 10px;
  }
  h3 {
    font-size: 27px;
  }
  .guests h2 {
    font-size: 32px;
  }
  .why-choose-list {
    margin: 0;
  }
  .reviewer-name {
    margin: 18px 0 22px;
  }
  .guests h6 {
    width: 70%;
  }
  .footer-style2 p {
    width: 70%;
  }
}

@media (max-width: 568px) {
  .speciality h6 {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .topics li {
    width: 50%;
  }

  .topics li:nth-child(2n) {
    padding-right: 0;
  }
}
