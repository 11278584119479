.custom-scroll::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.hero-slied {
  background: #ceddf7;
}
.mb-3 {margin-bottom: 15px;}
.flexCenter {
  display: flex;
  align-items: center;
}

select::-ms-expand {
  display: none;
}

.select {
  position: relative;
  display: flex;
  width: 100%;
  height: 55px;
  line-height: 3;
  overflow: hidden;
}

.select::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 0.45em 0.45em 0;
  padding: 7px 1em;
  color: #ffffff;
  background: #01358f;
  cursor: pointer;
  pointer-events: none;
  transition: 0.25s all ease;
}

.select:hover::after {
  color: #ffffff;
}
.date-picker {
  width: 100%;
  height: auto;
  max-height: 50px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s 0s ease-in-out;
  border-radius: 0.45em;
  border: 1px solid #ced4da;
}

.date-picker .input {
  width: 100%;
  height: 55px;
  font-size: 0;
  cursor: pointer;
}

.date-picker .input .result,
.date-picker .input button {
  display: inline-block;
  vertical-align: top;
}

.date-picker .input .result {
  width: calc(100% - 50px);
  height: 55px;
  line-height: 55px;
  font-size: 16px;
  padding: 0 10px;
  color: grey;
  box-sizing: border-box;
}

.date-picker .input button {
  width: 50px;
  height: 50px;
  background-color: #01358f;
  color: white;
  line-height: 50px;
  border: 0;
  font-size: 18px;
  padding: 0;
}

.date-picker .input button:hover {
  background-color: #01358f;
}

.date-picker .input button:focus {
  outline: 0;
}

.date-picker .calendar {
  position: relative;
  width: 100%;
  background: #fff;
  border-radius: 0px;
  overflow: hidden;
}

.date-picker .ui-datepicker-inline {
  position: relative;
  width: 100%;
}

.date-picker .ui-datepicker-header {
  height: 100%;
  line-height: 50px;
  background: #8392a7;
  color: #fff;
  margin-bottom: 10px;
}

.date-picker .ui-datepicker-prev,
.date-picker .ui-datepicker-next {
  width: 20px;
  height: 20px;
  text-indent: 9999px;
  border: 2px solid #fff;
  border-radius: 100%;
  cursor: pointer;
  overflow: hidden;
  margin-top: 12px;
}

.date-picker .ui-datepicker-prev {
  float: left;
  margin-left: 12px;
}

.date-picker .ui-datepicker-prev:after {
  transform: rotate(45deg);
  margin: -43px 0px 0px 8px;
}

.date-picker .ui-datepicker-next {
  float: right;
  margin-right: 12px;
}

.date-picker .ui-datepicker-next:after {
  transform: rotate(-135deg);
  margin: -43px 0px 0px 6px;
}

.date-picker .ui-datepicker-prev:after,
.date-picker .ui-datepicker-next:after {
  content: "";
  position: absolute;
  display: block;
  width: 4px;
  height: 4px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.date-picker .ui-datepicker-prev:hover,
.date-picker .ui-datepicker-next:hover,
.date-picker .ui-datepicker-prev:hover:after,
.date-picker .ui-datepicker-next:hover:after {
  border-color: #68768a;
}

.date-picker .ui-datepicker-title {
  text-align: center;
}

.date-picker .ui-datepicker-calendar {
  width: 100%;
  text-align: center;
}

.date-picker .ui-datepicker-calendar thead tr th span {
  display: block;
  width: 100%;
  color: #8392a7;
  margin-bottom: 5px;
  font-size: 13px;
}

.date-picker .ui-state-default {
  display: block;
  text-decoration: none;
  color: #b5b5b5;
  line-height: 40px;
  font-size: 12px;
}

.date-picker .ui-state-default:hover {
  background: rgba(0, 0, 0, 0.02);
}

.date-picker .ui-state-highlight {
  color: #68768a;
}

.date-picker .ui-state-active {
  color: #68768a;
  background-color: rgba(131, 146, 167, 0.12);
  font-weight: 600;
}

.date-picker .ui-datepicker-unselectable .ui-state-default {
  color: #eee;
  border: 2px solid transparent;
}

.date-picker.open {
  max-height: 400px;
}

.date-picker.open .input button {
  background: #68768a;
}

.figure {
  margin: 0;
}

.st-about-wr img {
  width: 100%;
  object-fit: contain;
}

.bottom-po {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
  background: #4285f4;
  border-radius: 5px;
}

/* Handle on hover */
.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #4285f4;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.width-100 {
  width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*Google Fonts*/

@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&amp;display=swap");

/*Custom fonts*/

/*--------------------------------------------------------------
1. Typography
----------------------------------------------------------------*/
#color-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: #000;
   opacity: 0.4;*/
}

.primaryBtn {
  border: 2px solid #023182;
  padding: 14px 24px;
  border-radius: 5px;
  color: #023182 !important;
  background: transparent;
}

.star__image {
  width: 13px;
  height: 13px;
}

.star1 img {
  display: inline-block !important;
  width: auto !important;
}

.secondaryBtn {
  border: 2px solid #023182;
  padding: 14px 24px;
  border-radius: 5px;
  color: #fff !important;
  background: #023182;
}

.serviceNumbers {
  background: #d0e2ef;
  padding-bottom: 0px;
}

.serviceList ul li.singleService {
  width: calc(100% / 3);
  margin-bottom: 16px;
}

.serviceList ul {
  justify-content: space-evenly;
  flex-wrap: wrap;
  background: #fff;
  margin: 0 auto;
  text-align: center;
  border-radius: 5px;
  padding: 13px 0 21px;
  top: -16px;
  position: relative;
  list-style: none;
}

.flexCenter {
  display: flex;
  align-items: center;
}

.serviceList ul li.singleService .serviceTitle {
  font-size: 8px;
  line-height: 9.6px;
  font-weight: 400;

  margin-top: 4px;
}

.text-t {
  color: #ed0000 !important;
}

.text-s {
  color: #00b011 !important;
}

.font-600 {
  font-weight: 600;
  font-size: 16px;
}

.st-logo-link img {
  height: 40px !important;
}

.container1 .row {
  min-width: 85%;
}

.container1 .row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

.react-multi-carousel-item {
  cursor: pointer;
}

ul.list-foot {
  display: inline;
}

ul.list-foot li a {
  padding-left: 25px;
}

ul.list-foot li a:before,
ul.list-foot li a:before {
  position: absolute;
  font-family: FontAwesome;
  content: "\f178";
  top: -1px;
  left: 0;
  font-size: 14px;
}

ul.list-foot li {
  display: inline-block;
  float: left;
  padding: 0 0 9px 0;
  position: relative;
}

ul.list-foot li,
.ul.list-foots li {
  color: rgba(255, 255, 255, 0.8);
}

ul.list-foot1 li {
  padding: 0 0 10px 0;
  position: relative;
}

ul.list-foot1 li:last-child {
  padding: 0;
}

ul.list-foot1 li a {
  padding-left: 25px;
}

ul.list-foot1 li a:before,
ul.list-foot1 li a:before {
  position: absolute;
  font-family: FontAwesome;
  content: "\f178";
  top: 1px;
  left: 0;
  font-size: 14px;
}

ul.list-foot1 li {
  padding: 0 0 9px 0;
  position: relative;
}

ul.list-foot1 li,
ul.list-foot1 li {
  color: rgba(255, 255, 255, 0.8);
}

.p-10 {
  padding: 12px 8px 8px 8px;
  margin-bottom: 8px;
}

.p-20 {
  padding: 20px;
}

.p-30 {
  padding: 30px;
}

.p1 {
  padding: 0;
}
/*--------------------------------------------------------------
2. Default Style
----------------------------------------------------------------*/
.st-zoom {
  position: relative;
  overflow: hidden;
}

.st-zoom-in {
  -webkit-transition: -webkit-transform 0.5s
    cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: -webkit-transform 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -o-transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55),
    -webkit-transform 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.st-zoom:hover .st-zoom-in {
  -webkit-transform: scale(1.07);
  -ms-transform: scale(1.07);
  transform: scale(1.07);
}

.st-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.st-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.st-section {
  padding-top: 40px;
  padding-bottom: 40px;
}

.st-sectionh {
  padding-top: 40px;
  padding-bottom: 40px;
}

.st-section6 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.contentSection {
  padding: 20% 0 0;
}

.st-section-help {
  background: url(assets/img/Mobile.jpg) no-repeat;
  background-position: bottom center;
  background-size: cover;
  height: 470px;
}

.st-section-top {
  padding-top: 60px;
}

.st-section h4 {
  margin-bottom: 15px;
  padding-bottom: 10px;
}

.st-section h4.border1 {
  border-bottom: 4px solid #ed0000;
  text-align: center;
  width: 15%;
  display: inline-block;
}

.gau {
  padding: 10% 0;
}

.st-gray-bg {
  background-color: #f9fbff;
}

.st-mp0 {
  list-style: none;
  margin: 0;
  padding: 0;
}

.st-vertical-middle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

.st-vertical-middle-in {
  width: 100%;
}

/*Owl Controller*/
.owl-nav button:focus {
  outline: none;
}

/*Style1*/
.owl-carousel.st-style1 .owl-stage-outer,
.owl-carousel.st-style2 .owl-stage-outer {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: -30px;
  margin-bottom: -30px;
}

.owl-carousel.st-style2 .owl-stage-outer {
  padding-left: 15px;
  margin-left: -15px;
  margin-right: -15px;
  padding-right: 15px;
}

.owl-carousel.st-owl-controler1 .owl-nav {
  display: none !important;
}

.owl-carousel.st-owl-controler1 .owl-dots,
.owl-carousel.st-owl-controler3 .owl-dots,
.owl-carousel.st-client-carousel .owl-dots {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.owl-carousel.st-client-carousel .owl-dots {
  display: none !important;
}

.owl-carousel.st-owl-controler1 .owl-dots button,
.owl-carousel.st-owl-controler3 .owl-dots button,
.owl-carousel.st-client-carousel .owl-dots button {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: #ffc4b9;
  margin: 0 5px;
}

.owl-carousel.st-owl-controler1 .owl-dots button:focus,
.owl-carousel.st-owl-controler3 .owl-dots button:focus,
.owl-carousel.st-client-carousel .owl-dots button:focus {
  outline: none;
}

.owl-carousel.st-owl-controler1 .owl-dots button.active,
.owl-carousel.st-owl-controler3 .owl-dots button.active,
.owl-carousel.st-client-carousel .owl-dots button.active {
  background-color: #ef7f1a;
}

/*Style 2*/
.owl-carousel.st-owl-controler2 .owl-nav {
  max-width: 1140px;
  padding: 0 15px;
  position: absolute;
  width: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 45px;
}

.owl-carousel.st-owl-controler2 .owl-prev i,
.owl-carousel.st-owl-controler2 .owl-next i {
  font-size: 12px;
}

.owl-carousel.st-owl-controler2 .owl-prev,
.owl-carousel.st-owl-controler2 .owl-next {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.owl-carousel.st-owl-controler2 .owl-prev:hover,
.owl-carousel.st-owl-controler2 .owl-next:hover {
  color: #ff8a73;
}

.owl-carousel.st-owl-controler2 .owl-prev i {
  margin-right: 10px;
}

.owl-carousel.st-owl-controler2 .owl-next i {
  margin-left: 10px;
}

.owl-carousel.st-owl-controler2 .owl-prev {
  margin-right: 30px;
}

/*Style3*/
.owl-carousel.st-owl-controler3 .owl-nav {
  display: block !important;
}

.owl-carousel.st-owl-controler3 .owl-dots,
.owl-carousel.st-owl-controler4 .owl-dots {
  display: none !important;
}

.owl-carousel.st-owl-controler3 .owl-nav .owl-prev,
.owl-carousel.st-owl-controler3 .owl-nav .owl-next,
.owl-carousel.st-owl-controler4 .owl-nav .owl-prev,
.owl-carousel.st-owl-controler4 .owl-nav .owl-next {
  position: absolute;
  height: 56px;
  width: 56px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(106, 106, 106, 0.15);
  box-shadow: 0px 5px 10px 0px rgba(106, 106, 106, 0.15);
  color: #ef7f1a;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 20px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  opacity: 0;
}

.owl-carousel.st-owl-controler3 .owl-nav .owl-prev {
  left: -35px;
}

.owl-carousel.st-owl-controler3 .owl-nav .owl-next {
  right: -35px;
}

.st-owl-controler3-hover:hover
  .owl-carousel.st-owl-controler3
  .owl-nav
  .owl-prev {
  left: -75px;
  opacity: 1;
}

.st-owl-controler3-hover:hover
  .owl-carousel.st-owl-controler3
  .owl-nav
  .owl-next {
  right: -75px;
  opacity: 1;
}

.owl-carousel.st-owl-controler3 .owl-nav .owl-prev:hover,
.owl-carousel.st-owl-controler3 .owl-nav .owl-next:hover,
.owl-carousel.st-owl-controler4 .owl-nav .owl-prev:hover,
.owl-carousel.st-owl-controler4 .owl-nav .owl-next:hover {
  color: #ff8a73;
}

/*Style4*/
.owl-carousel.st-owl-controler4 .owl-nav .owl-prev,
.owl-carousel.st-owl-controler4 .owl-nav .owl-next {
  position: initial;
  opacity: 1;
  -webkit-transform: initial;
  -ms-transform: initial;
  transform: initial;
}

.owl-carousel.st-owl-controler4 .owl-nav .owl-prev {
  margin-right: 10px;
}

.owl-carousel.st-owl-controler4 .owl-nav {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  margin-top: 23px;
}

.text-boxa {
  height: 210px;
  padding: 18px;
}

.text-boxa h6 {
  font-weight: 600;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.text-boxa p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

/*End Owl Controller*/
/*--------------------------------------------------------------
3. Button Style
----------------------------------------------------------------*/
/* Button Group */
.st-btn-group.st-style1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -10px;
}

.st-btn-group.st-style1 .st-btn {
  margin: 0 10px;
  margin-bottom: 10px;
}

/* Button Type */
.st-btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.st-btn:hover {
  -webkit-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
  transform: translateY(-1px);
  -webkit-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
}

.st-btn i {
  margin-right: 10px;
}

.st-btn.st-style2 i {
  font-size: 46px;
  color: #5257a6;
  background-color: rgba(82, 87, 166, 0.15);
  border-radius: 50%;
  position: relative;
  font-weight: initial;
}

.st-btn.st-style1 {
  padding: 0.8em 2.6em;
  font-weight: 500;
  border-radius: 1.8em;
  font-size: 15px;
}

.st-btn.st-style1.st-size1 {
  padding: 0.6em 2.2em;
}

.st-btn.st-style1:focus {
  outline: none;
}

.st-btn.st-style2 {
  font-weight: 500;
  font-size: 15px;
}

.st-btn.st-style2:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.alert-danger {
  margin-bottom: 10px;
}

/* Button Color */
.st-btn.st-color1 {
  color: #fff;
  background-color: #5257a6;
}

.st-btn.st-color1:hover {
  background-color: #ff8a73;
}

.st-btn.st-color2 {
  color: #fff;
  background-color: #ff8a73;
}

.st-btn.st-color2:hover {
  background-color: #5257a6;
}

/*--------------------------------------------------------------
4. Header
----------------------------------------------------------------*/
.st-header {
  position: relative;
}

.st-header.st-sticky-menu {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
}

.st-header.st-style1.st-other-page-header {
  background: #fff;
}

.st-header.st-style1 .st-main-header-in {
  padding: 4px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.st-header.st-sticky-menu.st-sticky-active {
  background: #fff;
  -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
}

.st-header.st-sticky-menu.st-sticky-active.st-color1 {
  background: #023182;
}

.st-header.st-style1 .st-main-header {
  position: relative;
}

/*=== Logo Area ===*/
.st-site-title {
  font-size: 20px;
  font-weight: 700;
  color: #111;
}

.st-site-title a {
  color: inherit;
}

/*End Logo Area*/

/*Horizontal Menu Area*/
.st-nav-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.st-nav-wrap,
.st-desktop-nav.st-nav,
.st-desktop-nav .st-nav-list,
.st-desktop-nav .st-nav-list > li,
.st-desktop-nav .st-nav-list > li > a {
  height: auto;
}

.st-solid-header .st-desktop-nav .st-nav-list > li > a {
  color: #666;
}

.st-solid-header .st-desktop-nav .st-nav-list > li > a.active {
  color: #023182;
}

.st-header.st-color1 .st-desktop-nav .st-nav-list > li > a {
  color: rgba(255, 255, 255, 0.7);
}

.st-header.st-color1 .st-desktop-nav .st-nav-list > li > a.active {
  color: #fff;
}

.st-nav-list > li > a > i {
  font-size: 14px;
  margin-right: 8px;
}

.st-nav.st-desktop-nav {
  display: block;
}

.st-desktop-nav .st-nav-list {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
}

.st-desktop-nav .st-nav-list > li {
  margin-left: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.st-desktop-nav .st-nav-list > li:first-child {
  margin-left: 0;
}

.st-desktop-nav .st-nav-list > li > a {
  list-style: none;
  padding: 4px 8px;
  text-transform: capitalize;
  /*display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;*/
}

.st-mob-nav .st-nav-list > li {
  margin-left: 4px;
  display: -webkit-box;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.st-mob-nav .st-nav-list > li:first-child {
  margin-left: 0;
}

.st-mob-nav .st-nav-list > li > a {
  list-style: none;
  padding: 4px 8px;
  text-transform: capitalize;
  /*display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;*/
}

.st-nav-list > li > a {
  color: #5257a6;
  font-size: 15px;
  font-weight: 400;
}

.st-nav-label {
  color: #fff;
  font-size: 9px;
  font-weight: 500;
  line-height: 1.7em;
  padding: 0 4px;
  border-radius: 2px;
  display: inline-block;
}

.st-nav-label.st-style1 {
  background: #de3618;
  position: relative;
  top: -6px;
  margin-left: 5px;
}

.st-nav-label.st-style2 {
  background-color: #50b83c;
  margin-left: auto;
}

/*=== Horizontal Dropdown ===*/
.st-has-children {
  position: relative;
}

.st-desktop-nav .st-nav-list > .st-has-children > Link {
  position: relative;
  padding-right: 13px;
}

.st-desktop-nav .st-nav-list > li > Link.active {
  color: #ff8a73;
}

.st-desktop-nav .st-nav-list .st-has-children > ul {
  list-style: none;
  position: absolute;
  width: 250px;
  background: #fff;
  border-bottom: 1px solid #cfcfcf;
  right: 0;
  top: 40px;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  display: block !important;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}

.st-mob-nav .st-nav-list > .st-has-children > Link {
  position: relative;
  padding-right: 13px;
}

.st-mob-nav .st-nav-list > li > Link.active {
  color: #ff8a73;
}

.st-mob-nav .st-nav-list .st-has-children > ul {
  list-style: none;
  position: absolute;
  width: 250px;
  background: #fff;
  border-bottom: 1px solid #cfcfcf;
  right: 0;
  top: 40px;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  display: block !important;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
}

.st-iconbox-text p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.st-nav-list ul a {
  color: #666;
  padding: 10px 15px;
  display: block;
  font-size: 14px;
  border-bottom: 1px solid #cfcfcf;
}

.dropdown-item i {
  /* margin-right: 8px; */
  width: 20px;
  text-align: center;
}

.st-desktop-nav .st-nav-list .st-sub-megamenu-list li a:hover {
  background: transparent;
}

.st-desktop-nav .st-nav-list ul a:hover,
.st-desktop-nav .st-nav-list .st-sub-megamenu-list li.active a {
  background-color: #f5f5f5;
}

.st-desktop-nav .st-nav-list .st-has-children > ul ul {
  left: 100%;
  top: 0;
}

.st-desktop-nav .st-has-children:hover > ul {
  opacity: 1;
  visibility: visible;
}

.st-mob-nav .st-nav-list .st-sub-megamenu-list li a:hover {
  background: transparent;
}

.st-mob-nav .st-nav-list ul a:hover,
.st-mob-nav .st-nav-list .st-sub-megamenu-list li.active a {
  background-color: #f5f5f5;
}

.st-mob-nav .st-nav-list .st-has-children > ul ul {
  left: 100%;
  top: 0;
}

.st-mob-nav .st-has-children:hover > ul {
  opacity: 1;
  visibility: visible;
}

.st-nav-list ul .st-has-children > a {
  padding-right: 25px;
}

/*=== Hovizontal Mega Menu ===*/
.st-desktop-nav .st-megamenu {
  position: initial;
}

.st-desktop-nav .st-has-children .st-megamenu-in {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 100%;
  max-width: 1170px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 2px;
  border-bottom-width: 2px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
}

.st-desktop-nav .st-has-children:hover .st-megamenu-in {
  opacity: 1;
  visibility: visible;
}

.st-mob-nav .st-megamenu {
  position: initial;
}

.st-mob-nav .st-has-children .st-megamenu-in {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 100%;
  max-width: 1170px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 2px;
  border-bottom-width: 2px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
}

.st-mob-nav .st-has-children:hover .st-megamenu-in {
  opacity: 1;
  visibility: visible;
}

.st-megamenu-title {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.65em;
  margin-bottom: 0;
}

.st-desktop-nav .st-megamenu-title {
  padding: 6px 20px;
}

.st-megamenu-list {
  padding: 0;
  list-style: none;
}

.st-megamenu.st-style1 .row {
  margin: 0;
}

.st-megamenu.st-style1 .container,
.st-megamenu.st-style1 .row > div {
  padding: 0;
}

.st-desktop-nav .st-nav-list .st-megamenu ul a {
  padding: 7px 20px;
}

.st-desktop-nav .st-megamenu-col {
  padding: 10px 0;
  border-right: 1px solid #eaeaea;
}

.st-desktop-nav .row > div:last-child > .st-megamenu-col {
  border-right: 0;
}

.st-solid-header {
  background: #fff;
}

.st-height-80 {
  height: 80px;
}

.st-height-70 {
  height: 70px;
}

.st-full-width .container {
  max-width: 100%;
  padding: 0 50px;
}

/*End Horizontal Mega menu*/

/*=== Horizontal Mobile Menu Style ===*/

.st-mobile-nav {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  background-color: #fff;
  padding: 20px 0;
}

.st-mobile-nav ul {
  margin: 0;
  list-style: none;
  padding: 0;
  padding-left: 15px;
}

.st-mobile-nav .st-nav-list ul {
  display: none;
}

.st-mobile-nav .st-nav-list > li > a {
  padding: 8px 0px;
  display: block;
}

.st-mobile-nav .st-nav-list ul a {
  padding-left: 0;
  padding-right: 0;
}

.st-sub-megamenu-in {
  display: none;
}

.st-sub-megamenu-in.active {
  display: block;
}

.st-nav.st-mobile-nav {
  max-height: calc(100vh - 80px);
  overflow: auto;
}

.st-mobile-nav .st-nav-list .st-megamenu-list {
  display: block;
}

.st-mobile-nav .st-megamenu-title {
  padding: 7px 15px;
}

.st-mobile-nav .st-nav-list .st-megamenu-list a {
  padding-left: 10px;
  padding-right: 10px;
}

.st-mobile-nav .st-megamenu-in {
  display: none;
}

.st-nav-toggle {
  display: none;
}

.st-mobile-nav .st-dropdown-btn {
  height: 20px;
  width: 20px;
  position: absolute;
  right: 15px;
  top: 9px;
  cursor: pointer;
  z-index: 1;
}

.st-mobile-nav .st-dropdown-btn:before,
.st-mobile-nav .st-dropdown-btn:after {
  content: "";
  position: absolute;
  height: 2px;
  width: 8px;
  background-color: #666;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.st-mobile-nav .st-dropdown-btn:before {
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
  -ms-transform: translate(-50%, -50%) rotate(90deg);
  transform: translate(-50%, -50%) rotate(90deg);
}

.st-mobile-nav .st-dropdown-btn.st-active:before {
  -webkit-transform: translate(-50%, -50%) rotate(0deg);
  -ms-transform: translate(-50%, -50%) rotate(0deg);
  transform: translate(-50%, -50%) rotate(0deg);
}

/*End Horizontal Mobile Menu*/

/*=== Mobile Menu Button ===*/
.st-nav-toggle.st-style1.st-active span:nth-child(1),
.st-nav-toggle.st-style1.st-active span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.st-nav-toggle.st-style1.st-active span:nth-child(2),
.st-nav-toggle.st-style1.st-active span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.st-nav-toggle.st-style1.st-active span:nth-child(1) {
  left: 2px;
  top: 4px;
}

.st-nav-toggle.st-style1.st-active span:nth-child(2) {
  left: calc(50% - 2px);
  top: 4px;
}

.st-nav-toggle.st-style1.st-active span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

.st-nav-toggle.st-style1.st-active span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

.st-nav-toggle.st-style1.st-active span:nth-child(5) {
  left: 2px;
  top: 11px;
}

.st-nav-toggle.st-style1.st-active span:nth-child(6) {
  left: calc(50% - 2px);
  top: 11px;
}

.st-nav-toggle.st-style1 {
  width: 22px;
  height: 14px;
  vertical-align: middle;
  position: relative;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  position: relative;
  top: -2px;
}

.st-nav-toggle.st-style1 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 50%;
  background: #666;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.st-header.st-color1 .st-nav-toggle.st-style1 span {
  background: #fff;
}

.st-nav-toggle.st-style1 span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

.st-nav-toggle.st-style1 span:nth-child(odd) {
  left: 0px;
  border-radius: 9px 0 0 9px;
}

.st-nav-toggle.st-style1 span:nth-child(1),
.st-nav-toggle.st-style1 span:nth-child(2) {
  top: 0px;
}

.st-nav-toggle.st-style1 span:nth-child(3),
.st-nav-toggle.st-style1 span:nth-child(4) {
  top: 6px;
}

.st-nav-toggle.st-style1 span:nth-child(5),
.st-nav-toggle.st-style1 span:nth-child(6) {
  top: 12px;
}

/*Ene Mobile Menu Button*/

/*=== Horizontal Menu Animation Efect ===*/
.st-fade-up .st-desktop-nav .st-nav-list .st-has-children > ul {
  margin-top: 5px;
}

.st-fade-up .st-desktop-nav .st-has-children:hover > ul {
  margin-top: -10px;
}

.st-fade-up .st-desktop-nav .st-has-children .st-megamenu-in {
  margin-top: 5px;
}

.st-fade-up .st-desktop-nav .st-has-children:hover .st-megamenu-in {
  margin-top: -10px;
}

.st-fade-down .st-desktop-nav .st-nav-list .st-has-children > ul {
  margin-top: -20px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.st-fade-down .st-desktop-nav .st-has-children:hover > ul {
  margin-top: -10px;
  z-index: 10;
}

.st-fade-down .st-desktop-nav .st-has-children .st-megamenu-in {
  margin-top: -20px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.st-fade-down .st-desktop-nav .st-has-children:hover .st-megamenu-in {
  margin-top: -10px;
}

/*--------------------------------------------------------------
5. Hero Style
----------------------------------------------------------------*/
.st-hero-slide.st-style1 {
  height: 820px;
  background-color: #f9fbff;
  position: relative;
  overflow: hidden;
}

.st-hero-slide.st-style1.st-type2 {
  padding-bottom: 70px;
}

.st-hero-slide.st-style1 .container,
.st-hero-slide.st-style2 .container {
  margin: 0;
  position: relative;
  z-index: 2;
}

.st-hero-slide.st-style1 .container {
  z-index: 2;
}

.st-hero-slide.st-style1 .st-hero-img,
.st-hero-slide.st-style2 .st-hero-img {
  position: absolute;
  left: 62.5%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

.st-hero-slide.st-style1 .st-hero-img img,
.st-hero-slide.st-style2 .st-hero-img img {
  -webkit-animation: 1.7s infinite alternate imageUpAnimation;
  animation: 1.7s infinite alternate imageUpAnimation;
}

.st-section h2,
.st-about-wrap h2,
.st-section h4,
.st-site-footer h5 {
  font-weight: 600;
}

.st-hero-text.st-style1 h1 {
  font-size: 45px;
  margin-bottom: 15px;
  line-height: 54px;
  font-weight: 600;
}

.st-hero-text.st-style1 h1 span {
  color: #ff8a73;
}

.st-hero-text.st-style1 b,
.st-hero-text.st-style1 i {
  font-weight: inherit;
  font-style: initial;
}

.st-hero-text.st-style1 .st-hero-subtitle {
  font-size: 17px;
  line-height: 1.8em;
  margin-bottom: 20px;
  font-weight: 400;
}

.st-hero-text.st-style1 {
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  transition-delay: 0.6s;
  z-index: 1;
}

.st-adjust-middle .st-hero-text.st-style1 {
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  transition-delay: 0.6s;
  padding-top: 85px;
  z-index: 1;
}

.st-hero-slider1 .st-hero-text.st-style1 {
  position: relative;
  top: -100px;
  opacity: 0;
}

.owl-carousel.st-hero-slider1 .active .st-hero-text.st-style1 {
  top: 0px;
  opacity: 1;
}

.st-hero-slide.st-style1 .st-hero-img,
.st-hero-slide.st-style2 .st-hero-img {
  top: 54%;
  -webkit-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  transition: all 0.7s ease;
  -webkit-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

.st-hero-slider1 .st-hero-slide.st-style1 .st-hero-img {
  top: 65%;
  opacity: 0;
}

.owl-carousel.st-hero-slider1 .active .st-hero-slide.st-style1 .st-hero-img {
  top: 50%;
  opacity: 1;
}

.st-video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 2;
}

.st-pattern-animation-wrap {
  position: absolute;
  top: 60%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 480px;
}

.st-pattern-animation {
  -webkit-animation: paternAnimation 5s alternate infinite linear;
  animation: paternAnimation 5s alternate infinite linear;
}

.st-hero-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: #000;
  opacity: 0.5;
}

.st-overflow-hidden {
  overflow: hidden;
}

@keyframes paternAnimation {
  0% {
    -webkit-transform: matrix(1, 0, 0, 1, -1, -9);
    transform: matrix(1, 0, 0, 1, -1, -9);
  }

  25% {
    -webkit-transform: matrix(1, 0, 0, 1, -5, 4);
    transform: matrix(1, 0, 0, 1, -5, 4);
  }

  50% {
    -webkit-transform: matrix(1, 0, 0, 1, -9, 9);
    transform: matrix(1, 0, 0, 1, -9, 9);
  }

  75% {
    -webkit-transform: matrix(1, 0, 0, 1, 5, -4);
    transform: matrix(1, 0, 0, 1, 5, -4);
  }

  100% {
    -webkit-transform: matrix(1, 0, 0, 1, -1, -9);
    transform: matrix(1, 0, 0, 1, -1, -9);
  }
}

@keyframes paternAnimation {
  0% {
    -webkit-transform: matrix(1, 0, 0, 1, -1, -9);
    transform: matrix(1, 0, 0, 1, -1, -9);
  }

  25% {
    -webkit-transform: matrix(1, 0, 0, 1, -5, 4);
    transform: matrix(1, 0, 0, 1, -5, 4);
  }

  50% {
    -webkit-transform: matrix(1, 0, 0, 1, -9, 9);
    transform: matrix(1, 0, 0, 1, -9, 9);
  }

  75% {
    -webkit-transform: matrix(1, 0, 0, 1, 5, -4);
    transform: matrix(1, 0, 0, 1, 5, -4);
  }

  100% {
    -webkit-transform: matrix(1, 0, 0, 1, -1, -9);
    transform: matrix(1, 0, 0, 1, -1, -9);
  }
}

.st-video-btn-icon {
  display: inline-block;
  position: relative;
  z-index: 2;
}

.tfvideo-button {
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 13px;
  left: 13px;
}

.tfvideo-button span {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: block;
  border-radius: 50%;
  -webkit-animation: tfvideo-animaion 2s linear infinite;
  animation: tfvideo-animaion 2s linear infinite;
  -webkit-box-shadow: 0 0 0 10px #023182;
  box-shadow: 0 0 0 10px #023182;
}

.tfvideo-button.st-color1 span {
  -webkit-box-shadow: 0 0 0 10px #ff8a73;
  box-shadow: 0 0 0 10px #ff8a73;
}

.tfabout-video img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@keyframes tfvideo-animaion {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.75;
  }

  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.75;
  }

  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes tfvideo-animaion {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.75;
  }

  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.75;
  }

  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

/*Style Two*/
/*Circle Animation*/
.st-circle {
  background-color: #fff;
  opacity: 0.031;
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
}

.st-circle-first {
  left: 2%;
  top: 2%;
  -webkit-animation: circleAnimation 13s alternate infinite linear;
  animation: circleAnimation 13s alternate infinite linear;
}

.st-circle-second {
  right: 10%;
  top: -3%;
  -webkit-animation: circleAnimation 18s alternate infinite linear;
  animation: circleAnimation 18s alternate infinite linear;
}

@keyframes circleAnimation {
  0% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }

  20% {
    -webkit-transform: translate(20px, -20px);
    transform: translate(20px, -20px);
  }

  40% {
    -webkit-transform: translate(40px, 0px);
    transform: translate(40px, 0px);
  }

  60% {
    -webkit-transform: translate(20px, 20px);
    transform: translate(20px, 20px);
  }

  80% {
    -webkit-transform: translate(-10px, 40px);
    transform: translate(-10px, 40px);
  }

  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}

@keyframes circleAnimation {
  0% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }

  20% {
    -webkit-transform: translate(20px, -20px);
    transform: translate(20px, -20px);
  }

  40% {
    -webkit-transform: translate(40px, 0px);
    transform: translate(40px, 0px);
  }

  60% {
    -webkit-transform: translate(20px, 20px);
    transform: translate(20px, 20px);
  }

  80% {
    -webkit-transform: translate(-10px, 40px);
    transform: translate(-10px, 40px);
  }

  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}

/*End Circle Animation*/
/*Wave Animation*/
.st-wave-wrap .st-wave-first .st-wave-in {
  -webkit-animation: move_wave 15s linear infinite;
  animation: move_wave 15s linear infinite;
}

.st-wave-wrap .st-wave-second .st-wave-in {
  -webkit-animation: move_wave 20s linear infinite;
  animation: move_wave 20s linear infinite;
  bottom: -35px;
}

.st-wave-wrap {
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}

.st-wave {
  position: absolute;
  width: 100%;
  overflow: hidden;
  height: 100%;
  bottom: 0;
}

.st-wave-in {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 0 bottom;
  -webkit-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  transform-origin: center bottom;
  /* background-repeat: no-repeat;  */
  /* background-attachment: fixed; */
  background-position: top center;
  background-size: cover;
}

@keyframes move_wave {
  0% {
    -webkit-transform: translateX(0) translateZ(0);
    transform: translateX(0) translateZ(0) scaleY(1);
  }

  50% {
    -webkit-transform: translateX(-25%) translateZ(0);
    transform: translateX(-25%) translateZ(0);
  }

  100% {
    -webkit-transform: translateX(-50%) translateZ(0);
    transform: translateX(-50%) translateZ(0);
  }
}

.img-responsive {
  width: 100%;
}

/*End Wave Animaiton*/
.st-hero-slide.st-style2 {
  background-color: #ffffff;
  position: relative;
  top: 0;
  left: 0;
}

.st-hero-slide.st-style2.st-type1 {
  height: 100vh;
  background-color: #023182;
  position: relative;
  border-radius: 0 0 100% 0;
  width: calc(100% - 120px);
  border: 40px solid #edf1fa;
  border-left: none;
  border-top: none;
  padding-left: 150px;
}

.st-hero-slide.st-style2.st-type3 {
  height: 100vh;
  background-color: #666;
  position: relative;
  border-radius: 0 0 100% 0;
  border: 40px solid #edf1fa;
  border-left: none;
  border-top: none;
  padding-left: 33px;
  width: calc(100% + 15px);
}

.st-hero-slide.st-style2.st-type1 .st-hero-img {
  width: 800px;
  left: 61%;
  z-index: 1;
}

.st-hero-slide.st-style2.st-type1 .st-hero-img img {
  -webkit-animation: none;
  animation: none;
  width: 100%;
}

.st-hero-slide.st-style2 .st-hero-text.st-style1 h1 {
  color: #023182;
}

.st-hero-slide.st-style2 .st-hero-text.st-style1 .st-hero-subtitle {
  color: rgba(0, 0, 0, 0.8);
}

.st-hero-slide.st-style2.st-type2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: initial;
  margin-bottom: 60px;
  border-radius: 0 0 100% 100%;
  border-bottom: 40px solid #edf1fa;
  width: 110%;
  margin-left: -5%;
}

.st-hero-slide.st-style2.st-type2 .st-hero-text.st-style1 {
  padding-top: 110px;
  padding-bottom: 80px;
}

.st-hero-img2 {
  position: relative;
  margin-bottom: -110px;
}

.st-hero-slide.st-style2.st-type2 .st-wave-wrap {
  margin-bottom: -50px;
}

.st-folow-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.st-folow-icon a {
  display: inline-block;
  position: relative;
  font-size: 18px;
  color: #b5b5b5;
  overflow: hidden;
  margin-right: 16px;
  padding: 0 2px;
}

.st-folow-icon a:last-child {
  margin-right: 0;
}

.st-folow-icon a:hover {
  color: #b5b5b5;
}

.st-folow-icon i {
  display: inline-block;
  line-height: inherit;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.st-folow-icon i:last-child {
  position: absolute;
  top: 0;
  left: -25px;
  color: #111;
}

.st-folow-icon a i:first-child {
  color: #b5b5b5;
}

.st-folow-icon a:hover i:last-child {
  left: 0;
}

.st-folow-icon a:hover i:first-child {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

.st-hero-slide-wrap {
  position: relative;
}

.st-hero-slide-wrap .st-folow-icon {
  position: absolute;
  right: 50px;
  bottom: 27px;
}

/*===Video Popup===*/
.st-pd-video .st-video-open,
.st-sample-img .st-video-open {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 68px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  line-height: 48px;
}

.st-pd-video .st-video-open:hover,
.st-sample-img .st-video-open:hover {
  color: rgba(255, 255, 255, 0.7);
}

.st-video-popup {
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  height: 100%;
  left: -100%;
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.st-video-popup.active {
  left: 0;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  left: 0;
}

.st-video-popup-overlay {
  position: absolute;
  left: 0;
  right: 0;
  background: #000;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  opacity: 0;
}

.st-video-popup.active .st-video-popup-overlay {
  opacity: 0.8;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.st-video-popup-content {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  font-size: 0;
  text-align: center;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
  opacity: 0;
  visibility: hidden;
  padding: 15px;
}

.st-video-popup.active .st-video-popup-content {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.st-video-popup-content:after {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.st-video-popup-container {
  display: inline-block;
  position: relative;
  text-align: left;
  background: #fff;
  max-width: 1100px;
  width: 100%;
  vertical-align: middle;
}

.st-video-popup-container .embed-responsive {
  width: 100%;
}

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
}

.st-video-popup-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background: #d90d0d;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.st-video-popup iframe {
  width: 100%;
  height: 100%;
  position: absolute;
}

.st-video-popup-close:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 1px;
  background: #fff;
  margin-left: -10px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.st-video-popup-close:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 1px;
  background: #fff;
  margin-left: -10px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.st-video-popup-close:hover:before,
.st-video-popup-close:hover:after {
  background: #000;
}

.st-video-popup-layer {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}

.st-video-popup-align {
  overflow: hidden;
}

/*End Video Popup*/

/*--------------------------------------------------------------
6. Icon Box
----------------------------------------------------------------*/
.st-iconbox-wrap.st-style1 {
  /* margin-top: -140px; */
  position: relative;
  z-index: 3;
}

.st-iconbox-wrap.st-style1 .st-iconbox.st-style1 {
  background-color: #fff;
}

.st-style2 {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.st-style3 {
  border-top: 8px solid #014f8b;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.st-style3 img {
  height: 35px;
  margin-bottom: 6px;
}

.st-iconbox.st-style1 h5 {
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.st-iconbox.st-style1 p {
  margin-bottom: 4px;
}

.st-iconbox.st-style1 {
  -webkit-box-shadow: 0px 5px 10px 0px rgba(106, 106, 106, 0.12);
  box-shadow: 0px 5px 10px 0px rgba(106, 106, 106, 0.12);
  padding: 6px 6px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-bottom: 10px;
  background: #ffffff;
  min-height: 200px;
  border-radius: 8px;
  position: relative;
}

.st-iconbox.st-style1:hover {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
}

.st-iconbox.st-style1 .st-iconbox-icon i {
  position: relative;
  z-index: 1;
}

.st-iconbox.st-style1 .st-iconbox-icon {
  margin-bottom: 17px;
  position: relative;
  font-size: 56px;
  color: #ef7f1a;
  height: 70px;
  width: 85px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.st-iconbox.st-style1:hover .st-iconbox-icon {
  color: #ff8a73;
}

.st-iconbox.st-style1 .st-iconbox-icon:before,
.st-iconbox.st-style1 .st-iconbox-icon:after {
  content: "";
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  width: 85px;
  height: 70px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

/* .st-iconbox.st-style1 .st-iconbox-icon:before {
    background-image: url(../img/light-img/shap1.png);
}

.st-iconbox.st-style1 .st-iconbox-icon:after {
    background-image: url(../img/light-img/shap2.png);
    opacity: 0;
} */

.st-iconbox.st-style1:hover .st-iconbox-icon:before {
  opacity: 0;
  -webkit-transform: translate(-50%, -50%) skewX(15deg) scale(1.02);
  -ms-transform: translate(-50%, -50%) skewX(15deg) scale(1.02);
  transform: translate(-50%, -50%) skewX(15deg) scale(1.02);
}

.st-iconbox.st-style1:hover .st-iconbox-icon:after {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) skewX(15deg) scale(1.02);
  -ms-transform: translate(-50%, -50%) skewX(15deg) scale(1.02);
  transform: translate(-50%, -50%) skewX(15deg) scale(1.02);
}

.st-iconbox.st-style1 .st-iconbox-title,
.st-image-box.st-style1 .st-image-box-title {
  font-size: 15px !important;
  margin-bottom: 6px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.st-iconbox.st-style1:hover .st-iconbox-title {
  color: #ff8a73;
}

.st-iconbox.st-style1 .st-iconbox-text,
.st-image-box.st-style1 .st-image-box-text {
  margin-bottom: -5px;
}

.st-service-section {
  position: relative;
}

.st-service-section .container {
  position: relative;
  z-index: 1;
}

.st-service-background-shap {
  position: absolute;
  top: 6%;
  right: -9%;
  -webkit-animation: borderAnimation 4s linear infinite;
  animation: borderAnimation 4s linear infinite;
}

.st-image-box-lavel {
  position: absolute;
  z-index: 2;
  top: 10px;
  right: 10px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  padding: 0px 6px 1px;
  background-color: #666;
  border-radius: 2px;
}

.cleans {
  text-align: center;
  margin-bottom: 8px;
}

.cleans img {
  padding-bottom: 8px;
  border-radius: 8px;
}

/*--------------------------------------------------------------
7. Section Heading
----------------------------------------------------------------*/
.st-section-heading.st-style1 {
  margin-bottom: 14px;
}

.st-about-text {
  font-size: 16px;
  line-height: 1.6em;
}

.st-about-text img {
  height: 150px;
}

.st-style1 h3 {
  color: #ed0000;
}

.st-section-heading.st-style1 h3 {
  font-size: 16px;
  color: #666;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: -3px;
}

.st-section-heading.st-style1 h2 {
  font-size: 32px;
  margin-bottom: 0;
  color: #ed0000;
}

.st-seperator {
  width: 172px;
  margin: auto;
  position: relative;
  margin-bottom: 9px;
  line-height: 10px;
  margin-top: 4px;
}

.st-seperator-left-bar,
.st-seperator-right-bar {
  position: absolute;
  height: 1px;
  width: 70px;
  background: #b5b5b5;
  top: 52%;
}

.st-seperator-left-bar {
  left: 0;
}

.st-seperator-right-bar {
  right: 0;
}

.st-section-heading.st-style2 {
  margin-bottom: 30px;
  margin-top: 30px;
}

.st-section-heading.st-style2 h2 {
  font-size: 32px;
  margin-bottom: 0;
  padding-bottom: 4px;
  margin-top: -7px;
}

.st-section-heading.st-style2 p {
  margin-bottom: 0;
  padding-top: 0;
  font-size: 18px;
  line-height: 1.6em;
  margin-top: 0;
}

.st-section-heading.st-style1.st-type1 {
  border-bottom: 1px solid #d0d0e2;
  margin-bottom: 14px;
  padding-bottom: 10px;
}

.st-section-heading.st-style2.st-type2 h2 {
  padding-bottom: 14px;
}

.st-section-heading.st-style2.st-type2 {
  margin-bottom: 28px;
}

.st-seperator-icon {
  -webkit-animation: spin-animaiton 6s linear infinite;
  animation: spin-animaiton 6s linear infinite;
}

@keyframes spin-animaiton {
  0% {
    -webkit-transform: rotateY(-20deg) rotateX(0deg);
    transform: rotateY(-20deg) rotateX(0deg);
  }

  75% {
    -webkit-transform: rotateY(-20deg) rotateX(360deg);
    transform: rotateY(-20deg) rotateX(360deg);
  }

  100% {
    -webkit-transform: rotateY(-20deg) rotateX(360deg);
    transform: rotateY(-20deg) rotateX(360deg);
  }
}

/*--------------------------------------------------------------
8. About Section
----------------------------------------------------------------*/
.st-about-text p {
  margin-bottom: 25px;
  text-align: justify;
}

.tr-list {
  padding: 0;
  margin: 0;
  margin-bottom: 33px;
}

.tr-list li {
  list-style: none;
  position: relative;
  padding-left: 22px;
  margin-bottom: 13px;
}

.tr-list li:last-child {
  margin-bottom: 0;
}

.tr-list li:before,
.tr-list li:after {
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  border: 2px solid #ef7f1a;
  height: 12px;
  width: 12px;
  border-radius: 50%;
}

.tr-list li:after {
  background: #ef7f1a;
  height: 6px;
  width: 6px;
  left: 3px;
  top: 10px;
}

.tr-list1 {
  padding: 0;
  margin: 0;
  margin-bottom: 33px;
}

.tr-list1 li {
  list-style: none;
  position: relative;
  padding-left: 22px;
  margin-bottom: 13px;
  line-height: 24px;
}

.tr-list1 li span {
  margin-bottom: 6px;
  display: inline-block;
  font-weight: 600;
}

.tr-list1 li:before,
.tr-list1 li:after {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  border: 2px solid #ef7f1a;
  height: 12px;
  width: 12px;
  border-radius: 50%;
}

.tr-list1 li ul li {
  list-style: none;
  position: relative;
  padding-left: 22px;
  margin-bottom: 8px;
}

.tr-list1 li ul li:before,
.tr-list1 li ul li:after {
  content: none;
}

.st-about-img {
  padding-right: 15px;
}

.st-about-img.st-style1 {
  position: relative;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(106, 106, 106, 0.12);
  box-shadow: 0px 5px 10px 0px rgba(106, 106, 106, 0.12);
  padding: 5px;
}

.st-about-img.st-style1:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0;
}

/*--------------------------------------------------------------
9. Image Box
----------------------------------------------------------------*/
.st-image-box.st-style1 {
  background-color: #fff;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(106, 106, 106, 0.12);
  box-shadow: 0px 5px 10px 0px rgba(106, 106, 106, 0.12);
}

.st-image-box.st-style1 .st-image-box-info {
  padding: 25px 20px 30px;
}

.st-image {
  display: block;
  position: relative;
  overflow: hidden;
}

.st-image:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: #000;
  opacity: 0;
  z-index: 1;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.st-image:hover:before {
  opacity: 0.1;
}

.st-image img {
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.st-image:hover img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

/*--------------------------------------------------------------
10. Fun Fact
----------------------------------------------------------------*/
.st-funfact-icon {
  margin: auto;
  position: relative;
  margin-bottom: 15px;
  font-size: 56px;
  color: #ef7f1a;
  height: 70px;
  width: 85px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.st-funfact-icon:before {
  content: "";
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
  width: 85px;
  height: 71px;
  -webkit-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
  /* background-image: url(../img/light-img/shap1.png); */
}

.st-funfact:hover .st-funfact-icon:before {
  -webkit-transform: translateX(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg);
}

.st-funfact-number {
  font-size: 60px;
  line-height: 1em;
  color: #ff8a73;
  font-weight: 600;
  margin-bottom: 3px;
}

.st-funfact-title {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: -4px;
  font-family: inherit;
}

/*--------------------------------------------------------------
11. Project
----------------------------------------------------------------*/
.st-single-project {
  height: 340px;
  width: 100%;
  display: block;
  position: relative;
}

.st-single-project:hover {
  -webkit-box-shadow: 0px 5px 10px 0px rgba(106, 106, 106, 0.12);
  box-shadow: 0px 5px 10px 0px rgba(106, 106, 106, 0.12);
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
}

.st-project-meta {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.42);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 30px;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.st-project-meta h3 {
  font-size: 18px;
  color: #fff;
  margin-bottom: 5px;
  position: relative;
  top: 20px;
  opacity: 0;
  -webkit-transition: all 0.4s cubic-bezier(0.67, -0.54, 0.27, 1.56);
  -o-transition: all 0.4s cubic-bezier(0.67, -0.54, 0.27, 1.56);
  transition: all 0.4s cubic-bezier(0.67, -0.54, 0.27, 1.56);
}

.st-project-meta span {
  color: rgba(255, 255, 255, 0.7);
  position: relative;
  top: 10px;
  opacity: 0;
  -webkit-transition: all 0.4s cubic-bezier(0.67, -0.54, 0.27, 1.56);
  -o-transition: all 0.4s cubic-bezier(0.67, -0.54, 0.27, 1.56);
  transition: all 0.4s cubic-bezier(0.67, -0.54, 0.27, 1.56);
}

.st-single-project:hover .st-project-meta {
  opacity: 1;
}

.st-single-project:hover .st-project-meta h3 {
  top: 0;
  opacity: 1;
  -webkit-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.st-single-project:hover .st-project-meta span {
  top: 0;
  opacity: 1;
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

/*--------------------------------------------------------------
12. News Letter
----------------------------------------------------------------*/
.subscription-error,
.subscription-success {
  display: none !important;
}

.st-subscribe-form {
  position: relative;
}

.st-subscribe-form input,
.st-contact-form input,
.st-contact-form textarea {
  border: 1px solid #d0d0e2;
  height: 48px;
  width: 100%;
  border-radius: 3px;
  padding: 10px 20px;
  font-size: 14px;
  max-width: calc(100% - 35px);
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background: #fff;
  font-weight: 400;
}

.st-subscribe-form input:focus,
.st-contact-form input:focus,
.st-contact-form textarea:focus {
  border-color: #5257a6;
  outline: none;
}

.st-newsletter-btn {
  height: 48px;
  width: 150px;
  background-color: #ff8a73;
  border: none;
  border-radius: 3px;
  position: absolute;
  right: 25px;
  top: 0;
  cursor: pointer;
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.subscription-label {
  flex: none;
  width: 100%;
  display: block;
  margin: 0;
}

.subscription-label.error {
  color: red;
}

.subscription-label.valid {
  color: green;
}

.st-newsletter-btn:hover {
  opacity: 0.8;
}

.st-newsletter-btn1:hover {
  opacity: 0.8;
}

.st-newsletter-btn1 {
  height: 48px;
  width: 150px;
  background-color: #023182;
  border: none;
  border-radius: 3px;
  position: absolute;
  right: 18px;
  top: 0;
  cursor: pointer;
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.st-right-newsletter {
  padding-left: 40px;
  max-width: 300px;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}

.st-newsletter-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 825px;
  margin: auto;
  align-items: center;
}

.st-newsletter-text {
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 1.6em;
}

.st-newsletter-img {
  position: relative;
}

.st-bard-animation1 {
  top: 7%;
  left: 4%;
  position: absolute;
  -webkit-animation: bardAnimaiton1 5s alternate infinite linear;
  animation: bardAnimaiton1 5s alternate infinite linear;
}

.st-bard-animation2 {
  position: absolute;
  top: 30%;
  left: 34%;
  -webkit-animation: bardAnimaiton1 4s alternate infinite linear;
  animation: bardAnimaiton1 4s alternate infinite linear;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.st-bard-animation3 {
  position: absolute;
  left: 70%;
  top: 1%;
  -webkit-animation: bardAnimaiton1 5s alternate infinite linear;
  animation: bardAnimaiton1 5s alternate infinite linear;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.inline-list1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: -10px;
  padding-bottom: 5px;
  text-align: center;
}

.inline-list1 li {
  padding: 2px 10px 2px 0;
  list-style: disc;
  font-size: 13px;
  margin-left: 10px;
}

@keyframes bardAnimaiton1 {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  33% {
    -webkit-transform: translate(-5px, -5px) rotate(-5deg);
    transform: translate(-5px, -5px) rotate(-5deg);
  }

  66% {
    -webkit-transform: translate(-5px, 0px) rotate(-2deg);
    transform: translate(-5px, 0px) rotate(-2deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
}

/*--------------------------------------------------------------
13. Contact Form
----------------------------------------------------------------*/
.st-contact-form input,
.st-contact-form textarea {
  border-radius: 0;
  border-top: none;
  border-right: none;
  border-left: none;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 14px;
}

.st-contact-form textarea {
  height: 140px;
}

.st-contact-form .st-btn.st-style1 {
  margin-top: 16px;
}

.st-contact-info.st-style1 {
  padding: 45px 100px;
  padding-right: 70px;
  height: 100%;
  min-height: 400px;
  position: relative;
}

.st-contact-info-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.st-contact-info-text {
  margin-bottom: 30px;
}

.st-contact-info.st-style1 ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.st-contact-info.st-style1 ul li {
  line-height: 1.6em;
  margin-bottom: 10px;
  position: relative;
  padding-left: 25px;
}

.st-contact-info.st-style1 ul li:last-child {
  margin-bottom: 0;
}

.st-contact-info.st-style1 ul li i {
  position: absolute;
  top: 4px;
  left: 0;
  color: #ef7f1a;
  font-size: 16px;
}

.st-contact-info-in {
  position: relative;
  z-index: 1;
}

.st-svg-animation1 {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: borderAnimation 4s linear infinite;
  animation: borderAnimation 4s linear infinite;
}

@keyframes borderAnimation {
  0% {
    -webkit-transform: skew(0deg, 0deg);
    transform: skew(0deg, 0deg);
  }

  33% {
    -webkit-transform: skew(4deg, -4deg);
    transform: skew(4deg, -4deg);
  }

  66% {
    -webkit-transform: skew(4deg, 0deg);
    transform: skew(4deg, 0deg);
  }

  100% {
    -webkit-transform: skew(0deg, 0deg);
    transform: skew(0deg, 0deg);
  }
}

/*--------------------------------------------------------------
14. Map Area
----------------------------------------------------------------*/
.st-map-bar {
  height: 110px;
  background-color: #edeef6;
  cursor: pointer;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.st-map-bar:hover {
  background-color: #5257a6;
}

.st-map-bar-img {
  position: relative;
}

.st-map-bar-icon {
  position: absolute;
  top: 46%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 28px;
  color: #5257a6;
  -webkit-animation: 2s linear 0s infinite normal none running markerAnimation;
  animation: 2s linear 0s infinite normal none running markerAnimation;
}

.st-map-bar.st-flex.st-bar-active .st-map-bar-icon {
  top: 50%;
  -webkit-animation: none;
  animation: none;
}

/*Map*/
.st-map-wrpa {
  display: none;
}

#st-map {
  height: 500px;
}

.popup-tip-anchor {
  height: 0;
  position: absolute;
  width: 200px;
}

.popup-bubble-anchor {
  position: absolute;
  width: 100%;
  bottom: 8px;
  left: 0;
}

.st-map-marker {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translate(-50%, -100%);
  -ms-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

.st-map-marker i {
  font-size: 42px;
  position: absolute;
  bottom: 7px;
  left: -8px;
  color: #ff8a73;
  z-index: 10;
  -webkit-animation: markerAnimation 2s linear infinite;
  animation: markerAnimation 2s linear infinite;
}

.st-map-marker-animation {
  -webkit-animation: preloader-wave 2s linear infinite;
  animation: preloader-wave 2s linear infinite;
  position: absolute;
  height: 60px;
  width: 60px;
  left: -25px;
  top: -20px;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 16px 8px #ff8a73;
  box-shadow: 0 0 16px 8px #ff8a73;
}

@keyframes markerAnimation {
  0% {
    bottom: 20px;
  }

  50% {
    bottom: 7px;
  }

  100% {
    bottom: 20px;
  }
}

@keyframes markerAnimation {
  0% {
    bottom: 20px;
  }

  50% {
    bottom: 7px;
  }

  100% {
    bottom: 20px;
  }
}

@keyframes preloader-wave {
  0% {
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
    opacity: 1;
  }

  50% {
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes preloader-wave {
  0% {
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
    opacity: 1;
  }

  50% {
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
15. Progressbar
----------------------------------------------------------------*/
.st-skill-img {
  -webkit-animation: 1.7s infinite alternate imageUpAnimation;
  animation: 1.7s infinite alternate imageUpAnimation;
}

@keyframes imageUpAnimation {
  from {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  to {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
}

.st-progressbar {
  height: 8px;
  width: 100%;
  overflow: hidden;
  background-color: #e8f1fd;
  position: relative;
}

.st-progressbar-in {
  position: absolute;
  height: 4px;
  background: #ef7f1a;
  width: calc(100% - 4px);
  top: 2px;
  left: 2px;
}

.st-single-progressbar:not(:last-child) {
  margin-bottom: 30px;
}

.st-progressbar-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 5px;
}

.st-progressbar-title {
  font-size: 18px;
  margin-bottom: 0;
}

.st-progressbar-percentage {
  color: #ff8a73;
}

/*--------------------------------------------------------------
16. Team
----------------------------------------------------------------*/
.st-team-member {
  -webkit-box-shadow: 0px 5px 10px 0px rgba(106, 106, 106, 0.15);
  box-shadow: 0px 5px 10px 0px rgba(106, 106, 106, 0.15);
  padding: 30px;
}

.st-member-img {
  margin-bottom: 25px;
  text-align: center;
}

.owl-carousel .owl-item .st-member-img img {
  width: initial;
  display: inline-block;
}

.st-member-name {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.st-team-member:hover .st-member-name {
  color: #ff8a73;
}

.st-member-designation {
  margin-bottom: 10px;
  font-size: 12px;
}

.st-member-social-btn li {
  margin: 0 8px;
  font-size: 14px;
}

.st-member-social-btn li a {
  color: #b5b5b5;
  display: flex;
}

.st-member-social-btn li a:hover {
  color: #ff8a73;
  transform: scale(1.2);
}

.st-member-social-btn img {
  display: block;
}

/*--------------------------------------------------------------
17. CTA
----------------------------------------------------------------*/
.st-cta-title {
  font-size: 42px;
  margin-bottom: 13px;
  margin-top: -5px;
}

.st-cta-text {
  margin-bottom: 32px;
  font-size: 18px;
  line-height: 1.6em;
}

/*--------------------------------------------------------------
18. Pricing Table
----------------------------------------------------------------*/
.st-price-card {
  -webkit-box-shadow: 0px 5px 10px 0px rgba(106, 106, 106, 0.15);
  box-shadow: 0px 5px 10px 0px rgba(106, 106, 106, 0.15);
  padding: 40px 20px;
  margin-bottom: 10px;
  -webkit-transition: -webkit-transform 0.4s
    cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -o-transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55),
    -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.st-price-card:hover {
  -webkit-transform: translateY(-8px);
  -ms-transform: translateY(-8px);
  transform: translateY(-8px);
}

.st-price-card-img {
  margin-bottom: 26px;
}

.st-price-card-title {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 15px;
}

.st-price {
  margin-bottom: 30px;
}

.st-price h3 {
  font-size: 60px;
  line-height: 1em;
  margin-bottom: -1px;
  font-weight: 400;
}

.st-price span {
  display: block;
  font-size: 14px;
  line-height: 1.2em;
  font-weight: 400;
  color: #b5b5b5;
}

.st-price-card-feature {
  margin-bottom: 33px;
  font-size: 16px;
  line-height: 1.6em;
}

.st-price-card-feature li:not(:last-child) {
  margin-bottom: 12px;
}

.st-featured-price .st-price h3 {
  color: #ff8a73;
}

/*--------------------------------------------------------------
19. Blog
----------------------------------------------------------------*/
.st-blog.st-style1 {
  margin-bottom: 10px;
  border: 1px solid #eeeeee;
}

.st-blog.st-style1 .st-blog-thumb {
  height: 130px;
  display: block;
}

.st-blog.st-style1 .st-blog-info {
  padding: 20px 25px;
}

.st-blog.st-style1 .st-blog-label {
  margin-top: -5px;
  margin-bottom: 2px;
}

.st-blog.st-style1 .st-blog-label a {
  font-weight: 500;
}

.st-blog.st-style1 .st-blog-label a:hover {
  text-decoration: underline;
}

.st-blog.st-style1 .st-blog-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.st-blog.st-style1 .st-blog-text {
  margin-bottom: 20px;
}

.st-blog.st-style1 .st-blog-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: -6px;
}

.st-blog.st-style1 .st-blog-btn {
  font-weight: 500;
  color: #ef7f1a;
}

.st-blog.st-style1 .st-blog-btn i {
  font-size: 12px;
  line-height: inherit;
  margin-left: 1px;
}

.st-blog.st-style1 .st-blog-btn:hover {
  color: #ff8a73;
  letter-spacing: 0.5px;
}

.st-pagination {
  margin: 20px 0;
}

.st-pagination ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.st-pagination ul li {
  margin: 0 5px;
}

.st-pagination a {
  height: 35px;
  min-width: 35px;
  border: 1px solid #d0d0e2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  border-radius: 3px;
  color: #666;
}

.st-pagination a:hover,
.st-pagination ul li.is-active a {
  background-color: #d0d0e2;
}

.st-pagination ul li.is-active a {
  pointer-events: none;
}

/*--------------------------------------------------------------
20. Testimonial
----------------------------------------------------------------*/
.st-testimonial-quote {
  -webkit-box-shadow: 0px 5px 10px 0px rgba(106, 106, 106, 0.15);
  box-shadow: 0px 5px 10px 0px rgba(106, 106, 106, 0.15);
  height: 70px;
  width: 70px;
  border-radius: 50%;
  margin-bottom: 22px;
  color: rgba(255, 138, 115, 0.5);
  font-size: 26px;
  margin-top: 5px;
}

.st-testimonial-text {
  font-size: 18px;
  line-height: 1.6em;
  margin-bottom: 20px;
}

.st-testimonial-meta h3 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0px;
}

.st-testimonial-meta p {
  margin-bottom: 0;
}

.owl-carousel.st-client-carousel .owl-item .st-client-logo img {
  width: initial;
}

.st-testimonial-slider .owl-stage-outer {
  margin-left: -8px;
}

.st-single-testimonial {
  padding-left: 8px;
}

.st-testimonial-right-img {
  height: 100%;
}

.car-image img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  margin-bottom: 6px;
}

.car-image h5 {
  color: #01358f !important;
}

.car-image h6 {
  color: #ed0000 !important;
}

.car-image p {
  margin-bottom: 8px;
}

.react-multi-carousel-track {
  margin-bottom: 20px !important;
}

/*--------------------------------------------------------------
20. Site Footer
----------------------------------------------------------------*/
.st-content {
  background-color: #fff;
  z-index: 10;
  position: relative;
}

.st-main-footer {
  padding: 80px 0 20px;
  background: #eeeeee;
}

.st-copyright {
  background: #023182;
}

.st-sticky-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}

.st-footer-social {
  margin-top: 13px;
}

.st-footer-social-btn {
  font-size: 14px;
  display: inline-flex;
}

.st-footer-social-btn li {
  margin: 0 10px;
}

.st-footer-social-btn li a {
  display: flex;
}

.st-footer-social-btn li a {
  display: flex;
  color: #b5b5b5;
}

.st-footer-social-btn a:hover {
  color: #ff8a73;
}

.st-copyright-text {
  border-top: 1px solid rgba(208, 208, 226, 0.6);
  padding: 19px 15px;
  color: #ffffff;
}

.st-footer-logo {
  margin-bottom: 20px;
}

.st-footer-logo img {
  height: 40px;
}

/*--------------------------------------------------------------
21. Start Page Title
----------------------------------------------------------------*/

.st-page-header {
  height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  padding-top: 20px;
  position: relative;
  background: #023182;
  /* background-image: url('./assets/img/page-header-bg.jpg'); */
}

.list-inline {
  line-height: 30gpx;
  font-size: 15px;
}

.st-page-header-text h1 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 6px;
  margin-top: -9px;
  text-transform: capitalize;
  color: #fff !important;
}

.st-breadcam {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 6px;
}

.st-breadcam li {
  font-size: 14px;
  color: #2cbcff;
}

.st-breadcam li a {
  color: #2cbcff;
}

.st-breadcam li a:hover {
  color: #fff;
}

.st-breadcam li:not(:last-child):after {
  content: "/";
  margin-left: 7px;
  padding-right: 7px;
  color: #007bff;
}

.st-blog-list .row {
  margin-top: -40px;
}

.st-blog-list .row > [class*="col-"] {
  margin-top: 40px;
}

.st-right-sidebar {
  padding-right: 5px;
}

.st-left-sidebar {
  padding-right: 10px;
}

/*--------------------------------------------------------------
22. Sidebar Widget
----------------------------------------------------------------*/

.widget {
  border-right: 1px solid #d0d0e2;
  padding: 10px;
}

.widget:not(:last-child) {
  margin-bottom: 40px;
}

.widget.widget_search {
  border-left: 0;
  padding: 0;
}

.search-form {
  position: relative;
  width: 100%;
}

.search-form label {
  width: 100%;
  margin: 0;
}

.search-form label:before {
  content: "\f002";
  font-family: "FontAwesome";
  width: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  height: 100%;
  right: 0;
  z-index: 1;
  background: #ff8a73;
  color: #fff;
  font-size: 18px;
  border-radius: 0 3px 3px 0;
}

.search-field {
  width: 100%;
  border-radius: 3px;
  padding: 11px 35px 11px 18px;
  border: 1px solid #d0d0e2;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-color: #f9fbff;
}

.search-field:focus {
  outline: none;
  border-color: #ff8a73;
}

.search-form .search-submit {
  position: absolute;
  height: 100%;
  width: 50px;
  top: 0;
  right: 0;
  border: 0;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
  text-indent: 50px;
  background-color: #000;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 0 3px 3px 0;
}

.search-form .search-submit:hover {
  opacity: 0.1;
}

.widget-title {
  font-size: 20px;
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 12px;
  color: #ed0000;
}

.widget-title:before {
  content: "";
  height: 2px;
  width: 80px;
  position: absolute;
  left: 0;
  background-color: #ff8a73;
  bottom: 0;
  border-radius: 0 3px 3px 0;
}

.widget ul {
  padding: 0;
  list-style: none;
  margin: 0;
  margin-top: -5px;
}

.widget ul li {
  position: relative;
  word-wrap: break-word;
  margin-bottom: 8px;
  font-weight: 500;
  padding-bottom: 8px;
  line-height: 22px;
  border-bottom: 1px solid #d0d0e2;
}

.widget ul li i {
  padding-right: 6px;
  font-size: 16px;
}

.widget ul li img {
  height: 26px;
  margin-right: 6px;
}

.widget.widget_categories a {
  /* -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; */
  font-size: 15px;
  color: #023182;
}

.widget.widget_categories a:hover {
  color: #ed0000;
}

.widget_recent_entries ul {
  margin-top: 0;
  padding-bottom: 5px;
}

.widget_recent_entries ul li {
  margin-bottom: 25px;
}

.widget_recent_entries ul li:last-child {
  margin-bottom: 0;
}

.widget.widget_recent_entries ul li a {
  display: block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.widget.widget_recent_entries img {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  height: 64px;
  width: 79px;
  margin-right: 15px;
  background-color: #fafafa;
}

.widget.widget_recent_entries ul h2 {
  font-size: 16px;
  margin-bottom: 7px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.widget.widget_recent_entries ul span {
  font-size: 15px;
}

.tagcloud {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: -10px;
  padding-bottom: 5px;
}

.tagcloud a {
  padding: 2px 15px;
  display: inline-block;
  background-color: #f6f7fc;
  font-size: 14px;
  margin-right: 10px;
  margin-top: 10px;
  text-align: center;
}

/*--------------------------------------------------------------
23. Isotope Style
----------------------------------------------------------------*/
.st-isotop.st-has-gutter {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -30px;
}

.st-isotop.st-has-gutter .st-isotop-item {
  padding: 0 15px;
  margin-bottom: 30px;
}

.st-port-col-6 .st-grid-sizer,
.st-port-col-6 .st-isotop-item {
  width: 16.666667%;
}

.st-port-col-5 .st-grid-sizer,
.st-port-col-5 .st-isotop-item {
  width: 20%;
}

.st-port-col-4 .st-grid-sizer,
.st-port-col-4 .st-isotop-item {
  width: 25%;
}

.st-port-col-3 .st-grid-sizer,
.st-port-col-3 .st-isotop-item {
  width: 33.333333%;
}

.st-port-col-2 .st-grid-sizer,
.st-port-col-2 .st-isotop-item {
  width: 50%;
}

.st-port-col-1 .st-grid-sizer,
.st-port-col-1 .st-isotop-item {
  width: 100%;
}

.st-grid-sizer {
  width: 33.333333%;
}

.st-port-col-5 .st-w20,
.st-port-col-4 .st-w20,
.st-port-col-3 .st-w20,
.st-port-col-2 .st-w20,
.st-port-col-1 .st-w20 {
  width: 20%;
}

.st-port-col-5 .st-w25,
.st-port-col-4 .st-w25,
.st-port-col-3 .st-w25,
.st-port-col-2 .st-w25,
.st-port-col-1 .st-w25 {
  width: 25%;
}

.st-port-col-5 .st-w33,
.st-port-col-4 .st-w33,
.st-port-col-3 .st-w33,
.st-port-col-2 .st-w33,
.st-port-col-1 .st-w33 {
  width: 33.333333%;
}

.st-port-col-5 .st-w50,
.st-port-col-4 .st-w50,
.st-port-col-3 .st-w50,
.st-port-col-2 .st-w50,
.st-port-col-1 .st-w50 {
  width: 50%;
}

.st-port-col-5 .st-w66,
.st-port-col-4 .st-w66,
.st-port-col-3 .st-w66,
.st-port-col-2 .st-w66,
.st-port-col-1 .st-w66 {
  width: 66.666666%;
}

.st-port-col-5 .st-w100,
.st-port-col-4 .st-w100,
.st-port-col-3 .st-w100,
.st-port-col-2 .st-w100,
.st-port-col-1 .st-w100 {
  width: 100%;
}

.st-height1 {
  height: 250px;
}

.st-height2 {
  height: 450px;
}

/*--------------------------------------------------------------
24. Start Text Slider
----------------------------------------------------------------*/

.st-words-wrapper {
  display: inline-block;
  position: relative;
  text-align: left;
}

.st-words-wrapper b {
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: 0;
}

.st-words-wrapper b.is-visible {
  position: relative;
}

.st-headline.st-rotate-text .st-words-wrapper {
  -webkit-perspective: 300px;
  perspective: 300px;
}

.st-headline.st-rotate-text b {
  opacity: 0;
}

.st-headline.st-rotate-text i {
  display: inline-block;
  -webkit-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.is-visible .st-headline.st-rotate-text i {
  -webkit-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.st-headline.st-rotate-text i.in {
  -webkit-animation: cd-st-rotate-text-in 0.6s forwards;
  animation: cd-st-rotate-text-in 0.6s forwards;
}

.st-headline.st-rotate-text i.out {
  -webkit-animation: cd-st-rotate-text-out 0.6s forwards;
  animation: cd-st-rotate-text-out 0.6s forwards;
}

.no-csstransitions .st-headline.st-rotate-text i {
  -webkit-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
  opacity: 0;
}

.no-csstransitions .st-headline.st-rotate-text .is-visible i {
  opacity: 1;
}

@keyframes cd-st-rotate-text-in {
  0% {
    -webkit-transform: rotateY(180deg);
  }

  100% {
    -webkit-transform: rotateY(0deg);
  }
}

@keyframes cd-st-rotate-text-in {
  0% {
    -webkit-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }

  100% {
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}

@keyframes cd-st-rotate-text-out {
  0% {
    -webkit-transform: rotateY(0);
  }

  100% {
    -webkit-transform: rotateY(-180deg);
  }
}

@keyframes cd-st-rotate-text-out {
  0% {
    -webkit-transform: rotateY(0);
    -ms-transform: rotateY(0);
    transform: rotateY(0);
  }

  100% {
    -webkit-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
  }
}

/*xscale*/
.st-headline.scale b {
  opacity: 0;
}

.st-headline.scale i {
  display: inline-block;
  opacity: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.is-visible .st-headline.scale i {
  opacity: 1;
}

.st-headline.scale i.in {
  -webkit-animation: scale-up 0.6s forwards;
  animation: scale-up 0.6s forwards;
}

.st-headline.scale i.out {
  -webkit-animation: scale-down 0.6s forwards;
  animation: scale-down 0.6s forwards;
}

.no-csstransitions .st-headline.scale i {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 0;
}

.no-csstransitions .st-headline.scale .is-visible i {
  opacity: 1;
}

@keyframes scale-up {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  60% {
    -webkit-transform: scale(1.2);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale-up {
  0% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }

  60% {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale-down {
  0% {
    -webkit-transform: scale(1);
    opacity: 1;
  }

  60% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
}

@keyframes scale-down {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  60% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
}

/*xpush */
.st-headline.push b {
  opacity: 0;
}

.st-headline.push b.is-visible {
  opacity: 1;
  -webkit-animation: push-in 0.6s;
  animation: push-in 0.6s;
}

.st-headline.push b.is-hidden {
  -webkit-animation: push-out 0.6s;
  animation: push-out 0.6s;
}

@keyframes push-in {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(10%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@keyframes push-in {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(10%);
    -ms-transform: translateX(10%);
    transform: translateX(10%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes push-out {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }

  60% {
    opacity: 0;
    -webkit-transform: translateX(110%);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(100%);
  }
}

@keyframes push-out {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  60% {
    opacity: 0;
    -webkit-transform: translateX(110%);
    -ms-transform: translateX(110%);
    transform: translateX(110%);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
  }
}

/*End Rotate Text*/
/*Icon Box*/
.st-icon-box {
  padding-left: 65px;
  position: relative;
}

.st-icon {
  position: absolute;
  left: 15px;
  top: 3px;
  font-size: 36px;
  color: #2caee2;
}

.st-icon-box-title {
  font-size: 24px;
  margin-bottom: 0;
  letter-spacing: 0.25px;
}

.st-icon-box-text {
  margin-bottom: 0;
  margin-top: 12px;
}

/*End Text Slider*/

/*Isotop Menu Style1*/
.st-isotop-filter.st-style1 {
  margin-bottom: 45px;
}

.st-isotop-filter.st-style1 li {
  margin: 0 15px;
}

.st-isotop-filter.st-style1 li.active a,
.st-isotop-filter.st-style1 li a:hover {
  color: #b5b5b5;
}

/*--------------------------------------------------------------
25. Blog Details
----------------------------------------------------------------*/

.post-thumbnail {
  margin-bottom: 25px;
}

.post-thumbnail img {
  width: 100%;
}

.post-details-wrap {
  position: relative;
  margin-bottom: 14px;
}

.entry-title {
  font-size: 42px;
  margin-bottom: 9px;
}

.byline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  bottom: 100%;
  width: 100%;
  margin-bottom: 5px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  left: 0;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 1;
}

.byline span {
  margin-right: 30px;
}

.byline i {
  display: inline-block;
  margin-right: 6px;
  color: #b5b5b5;
}

.byline a:hover {
  color: #ff8a73;
}

/*End Post Details*/
/*Start Comment Section*/
.comments-area {
  margin-top: 55px;
  border-top: 1px solid #d0d0e2;
  padding-top: 55px;
}

.commenst-title,
.comment-reply-title,
.comments-title {
  font-size: 22px;
  margin-bottom: 35px;
}

.comment-body {
  position: relative;
  margin-left: 90px;
  margin-bottom: 30px;
}

.commenst-area {
  margin-top: 60px;
}

.commenst-area ol {
  list-style: none;
  padding-left: 0;
}

.commenst-area .children {
  padding-left: 90px;
}

.commenst-area .children .children {
  padding-left: 0;
}

.comment-author .avatar {
  height: 75px;
  width: 75px;
  position: absolute;
  top: 0;
  left: -90px;
  border-radius: 50%;
}

.comment-author {
  line-height: 20px;
  margin-bottom: 0;
}

.comment-list,
.comment-list ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

.comment-list .children {
  margin-left: 45px;
}

.comment-list .children .children {
  margin-left: 0;
}

.comment-author a.nm,
.comment-author .nm a {
  font-weight: 400;
  font-size: 18px;
  color: #ef7f1a;
}

.comment-author a.nm:hover,
.comment-author .nm a:hover {
  color: #ff8a73;
}

.comment-metadata {
  margin-bottom: 5px;
  display: inline-block;
  font-size: 13px;
  line-height: 1.6em;
  color: #b5b5b5;
}

.comment-metadata a,
.comment-metadata span {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
}

.comment-metadata a:hover {
  color: #ef7f1a;
}

.comment-content {
  line-height: 25px;
  color: #767676;
  font-size: 15px;
}

.comment-content p {
  line-height: inherit;
  color: inherit;
  font-size: inherit;
}

.reply {
  display: inline-block;
  margin-left: 10px;
}

.comment-reply-link {
  font-size: 14px;
  position: relative;
  font-weight: 400;
  color: #ff8a73;
  padding-left: 10px;
}

.comment-reply-link i {
  display: inline-block;
  margin-right: 2px;
  font-size: 13px;
}

.comment-reply-link:hover,
.comment-reply-link:focus {
  color: #ef7f1a;
}

.children .comment-author .avatar {
  left: -90px;
}

.comment-respond {
  display: block;
  width: 100%;
  padding-top: 25px;
}

.comment-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.comment-form p:not(:last-child) {
  margin-bottom: 20px;
}

.comment-form-author,
.comment-form-email,
.comment-form-url {
  width: 33.3333%;
}

.comment-form-comment {
  width: 100%;
  display: block;
}

.comment-form-author {
  padding-right: 15px;
}

.comment-form-url {
  padding-left: 15px;
}

.form-submit {
  margin-bottom: 0;
  margin-top: 6px;
}

.comment-form textarea,
.comment-form input {
  border: 1px solid #d0d0e2;
  padding: 10px 15px;
  width: 100%;
  border-radius: 2px;
  font-size: 14px;
  line-height: 1.3em;
}

.comment-form textarea:focus,
.comment-form input:focus {
  border-color: #ef7f1a;
  outline: none;
}

#st-partical-wrap {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.st-ball {
  position: absolute;
  border-radius: 100%;
  opacity: 0.3;
}

/*End Comment Section*/
.st-google-map iframe {
  border: none;
  height: 450px;
  width: 100%;
}

/*Start Preloader*/
#st-preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  background-size: cover;
  height: 100%;
  z-index: 9999999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.st-preloader-wave {
  display: inline-block;
  width: 8px;
  height: 40px;
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-animation: preloaderAnimation 633ms alternate infinite
    cubic-bezier(0.2, 0, 0.5, 1);
  animation: preloaderAnimation 633ms alternate infinite
    cubic-bezier(0.2, 0, 0.5, 1);
}

.st-preloader-wave:nth-of-type(1) {
  -webkit-animation-delay: 111ms;
  animation-delay: 111ms;
  background: #ff8a73;
  border-radius: 3px 0 0 3px;
}

.st-preloader-wave:nth-of-type(2) {
  -webkit-animation-delay: 222ms;
  animation-delay: 222ms;
  background: #ff8a73;
  opacity: 0.9;
}

.st-preloader-wave:nth-of-type(3) {
  -webkit-animation-delay: 333ms;
  animation-delay: 333ms;
  background: #ff8a73;
  opacity: 0.8;
}

.st-preloader-wave:nth-of-type(4) {
  -webkit-animation-delay: 444ms;
  animation-delay: 444ms;
  background: #ff8a73;
  opacity: 0.7;
}

.st-preloader-wave:nth-of-type(5) {
  -webkit-animation-delay: 555ms;
  animation-delay: 555ms;
  background: #ff8a73;
  border-radius: 0 3px 3px 0;
  opacity: 0.6;
}

.st-blue-color .st-preloader-wave:nth-of-type(1),
.st-blue-color .st-preloader-wave:nth-of-type(2),
.st-blue-color .st-preloader-wave:nth-of-type(3),
.st-blue-color .st-preloader-wave:nth-of-type(4),
.st-blue-color .st-preloader-wave:nth-of-type(5) {
  background: #023182;
}

/*Preloader Animaiton*/
@keyframes preloaderAnimation {
  to {
    -ms-transform: translateY(12px);
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
  }
}

@keyframes preloaderAnimation {
  to {
    -ms-transform: translateY(12px);
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
  }
}

/*End Preloader*/
/*--------------------------------------------------------------
26. Blue Color CSS
----------------------------------------------------------------*/

.st-blue-color a:hover {
  color: #023182;
}

.st-blue-color hr {
  border-color: #eaeaea;
}

button:disabled,
input[type="radio"]:disabled .styled-radio1 label,
input[disabled],
button[disabled] {
  border: 1px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
}

.styled-radio4 input[type="radio"]:checked + label {
  color: #10b1e7 !important;
  background-color: #023182 !important;
  border: 2px solid #10b1e7 !important;
}

.st-blue-color .st-btn.st-style2,
.st-blue-color .st-btn.st-style2 i {
  color: #fff;
}

.st-blue-color .st-btn.st-color1 {
  color: #fff;
  background-color: #023182;
}

.st-blue-color .st-funfact-icon,
.st-blue-color .st-map-bar-icon,
.st-blue-color .st-iconbox.st-style1:hover .st-iconbox-title,
.st-blue-color .st-blog.st-style1 .st-blog-btn {
  color: #111;
}

.st-blue-color .st-funfact-title,
.st-blue-color .owl-carousel.st-owl-controler3 .owl-nav .owl-prev,
.st-blue-color .owl-carousel.st-owl-controler3 .owl-nav .owl-next,
.st-blue-color .owl-carousel.st-owl-controler4 .owl-nav .owl-prev,
.st-blue-color .owl-carousel.st-owl-controler4 .owl-nav .owl-next {
  color: #666;
}

.st-blue-color .st-map-marker i,
.st-blue-color .st-featured-price .st-price h3,
.st-blue-color .owl-carousel.st-owl-controler3 .owl-nav .owl-prev:hover,
.st-blue-color .owl-carousel.st-owl-controler3 .owl-nav .owl-next:hover,
.st-blue-color .owl-carousel.st-owl-controler4 .owl-nav .owl-prev:hover,
.st-blue-color .owl-carousel.st-owl-controler4 .owl-nav .owl-next:hover,
.st-blue-color .st-team-member:hover .st-member-name,
.st-blue-color .st-progressbar-percentage,
.st-blue-color .st-funfact-number,
.st-blue-color .st-iconbox.st-style1:hover .st-iconbox-icon,
.st-blue-color .st-iconbox.st-style1 .st-iconbox-icon,
.st-blue-color .st-member-social-btn li a:hover,
.st-blue-color .st-blog.st-style1 .st-blog-btn:hover {
  color: #023182;
}

.st-blue-color .tr-list li:after {
  background: #666;
}

.st-blue-color .tr-list li:before,
.st-blue-color .tr-list li:after {
  border-color: #666;
}

.st-blue-color .st-newsletter-btn,
.st-blue-color .st-btn.st-color2,
.st-blue-color .owl-carousel.st-owl-controler1 .owl-dots button.active,
.st-blue-color .st-progressbar-in,
.st-blue-color .st-map-bar {
  background-color: #023182;
}

.st-blue-color .st-map-bar:hover {
  background-color: rgba(42, 88, 255, 0.85);
}

.st-blue-color .st-btn.st-color2:hover {
  color: #fff;
  background: #ef7f1a;
}

.st-blue-color .owl-carousel.st-owl-controler1 .owl-dots button,
.st-blue-color .owl-carousel.st-owl-controler3 .owl-dots button,
.st-blue-color .owl-carousel.st-client-carousel .owl-dots button,
.st-blue-color .st-seperator-left-bar,
.st-blue-color .st-seperator-right-bar {
  background-color: #666;
}

.st-blue-color .st-btn.st-color3 {
  color: #fff;
  background-color: #111;
}

.st-blue-color .st-price span,
.st-blue-color .st-contact-info.st-style1 ul li i {
  color: #b5b5b5;
}

.st-blue-color .st-testimonial-quote {
  color: rgba(42, 88, 255, 0.5);
}

.st-blue-color .st-subscribe-form input:focus,
.st-blue-color .st-contact-form input:focus,
.st-blue-color .st-contact-form textarea:focus {
  border-color: #023182;
  outline: none;
}

.st-blue-color .st-map-marker-animation {
  -webkit-box-shadow: 0 0 16px 8px #023182;
  box-shadow: 0 0 16px 8px #023182;
}

.st-blue-color .st-project-meta h3 {
  color: #fff;
}

/* .st-blue-color .st-iconbox.st-style1 .st-iconbox-icon:before {
    background-image: url(../img/light-img/shap2.png);
    opacity: 0.7;
}

.st-blue-color .st-funfact-icon:before {
    background-image: url(../img/light-img/shap2.png);
} */

.st-cta-wrap {
  position: relative;
  overflow: hidden;
}

.st-cta-wrap .row,
.st-cta-wrap .row > div {
  margin-bottom: 0;
  margin-top: 0;
}

.st-cta-wrap .container {
  position: relative;
  z-index: 2;
}

.shap1 {
  position: absolute;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  left: 12%;
  top: 5%;
  background: rgba(42, 88, 255, 0.05);
  -webkit-animation: animationFramesOne 15s alternate infinite linear;
  animation: animationFramesOne 15s alternate infinite linear;
}

.shap2 {
  position: absolute;
  height: 50px;
  width: 50px;
  left: 2%;
  top: 45%;
  border: 8px solid #ebf5f7;
  -webkit-animation: animationFramesOne 20s alternate infinite linear;
  animation: animationFramesOne 20s alternate infinite linear;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.shap3 {
  position: absolute;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  left: 32%;
  top: 0%;
  background: rgba(223, 79, 252, 0.1);
  -webkit-animation: animationFramesOne 35s alternate infinite linear;
  animation: animationFramesOne 35s alternate infinite linear;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.shap4 {
  overflow: hidden;
  position: absolute;
  height: 54px;
  width: 50px;
  left: 75%;
  top: 0;
  border: 8px solid #d5f6f4;
  -webkit-animation: animationFramesOne 25s alternate infinite linear;
  animation: animationFramesOne 25s alternate infinite linear;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
  border-top-color: transparent;
  border-left-color: transparent;
}

.shap4:before {
  content: "";
  position: absolute;
  height: 8px;
  width: 100px;
  background: #d5f6f4;
  -webkit-transform: rotate(-48deg);
  -ms-transform: rotate(-48deg);
  transform: rotate(-48deg);
  bottom: 20px;
  left: -23px;
}

.shap5 {
  position: absolute;
  height: 75px;
  width: 75px;
  border-radius: 50%;
  left: 48%;
  top: 50%;
  background: rgba(226, 246, 225, 0.7);
  -webkit-animation: animationFramesOne 30s alternate infinite linear;
  animation: animationFramesOne 30s alternate infinite linear;
}

.shap6 {
  position: absolute;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  left: 91%;
  top: 65%;
  background: rgba(42, 88, 255, 0.1);
  -webkit-animation: animationFramesOne 17s alternate infinite linear;
  animation: animationFramesOne 17s alternate infinite linear;
}

@keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
    transform: translate(73px, -1px) rotate(36deg);
  }

  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
    transform: translate(141px, 72px) rotate(72deg);
  }

  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
    transform: translate(83px, 122px) rotate(108deg);
  }

  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
    transform: translate(-40px, 72px) rotate(144deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
    transform: translate(73px, -1px) rotate(36deg);
  }

  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
    transform: translate(141px, 72px) rotate(72deg);
  }

  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
    transform: translate(83px, 122px) rotate(108deg);
  }

  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
    transform: translate(-40px, 72px) rotate(144deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
}

/*--------------------------------------------------------------
27. Dark Version Css
----------------------------------------------------------------*/

.st-black-version {
  background-color: #121214;
  color: #fff;
}

.st-black-version .st-hero-slide.st-style1 {
  background-color: #151517;
}

.st-black-version h1,
.st-black-version h2,
.st-black-version h3,
.st-black-version h4,
.st-black-version h3,
.st-black-version h6,
.st-black-version .st-member-social-btn li a,
.st-black-version .st-price span,
.st-black-version .owl-carousel.st-owl-controler2 .owl-prev:hover,
.st-black-version .owl-carousel.st-owl-controler2 .owl-next:hover,
.st-black-version .owl-carousel.st-owl-controler3 .owl-nav .owl-prev,
.st-black-version .owl-carousel.st-owl-controler3 .owl-nav .owl-next,
.st-black-version .st-blog.st-style1 .st-blog-label a:hover,
.st-black-version .owl-carousel.st-owl-controler4 .owl-nav .owl-prev,
.st-black-version .owl-carousel.st-owl-controler4 .owl-nav .owl-next {
  color: #fff;
}

.st-black-version .st-team-member:hover .st-member-name,
.st-black-version .st-progressbar-percentage,
.st-black-version .st-funfact-number,
.st-black-version a:hover,
.st-black-version .st-iconbox.st-style1:hover .st-iconbox-title,
.st-black-version .st-section-heading.st-style1 h3,
.st-black-version .st-member-social-btn li a:hover,
.st-black-version .st-map-bar-icon,
.st-black-version .st-map-marker i,
.st-black-version .st-blog.st-style1 .st-blog-btn,
.st-black-version .st-featured-price .st-price h3 {
  color: #e9204f;
}

.st-black-version .st-map-marker-animation {
  -webkit-box-shadow: 0 0 16px 8px #e9204f;
  box-shadow: 0 0 16px 8px #e9204f;
}

.st-black-version .st-iconbox-text {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.st-black-version .st-btn.st-color1 {
  background-color: #e9204f;
  color: #fff;
}

.st-black-version .st-funfact-icon,
.st-black-version .st-iconbox.st-style1:hover .st-iconbox-text {
  color: #fff;
}

.st-black-version .st-btn.st-style2 i {
  color: #e9204f;
  background-color: #fff;
  -webkit-box-shadow: inset 0 0 0 2px #e9204f;
  box-shadow: inset 0 0 0 2px #e9204f;
}

.st-black-version .st-nav-list > li > a {
  color: rgba(255, 255, 255, 0.7);
}

.st-black-version .st-header.st-sticky-menu.st-sticky-active {
  background: #151517;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

/* .st-black-version .st-iconbox.st-style1 .st-iconbox-icon:before,
.st-black-version .st-iconbox.st-style1 .st-iconbox-icon:after,
.st-black-version .st-funfact-icon:before {
    background-image: url(../img/dark-img/shap2.png);
} */

.st-black-version .st-iconbox.st-style1 {
  background: #19191b;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.st-black-version .st-btn.st-color2 {
  color: #fff;
  background-color: #e9204f;
}

.st-black-version .st-btn.st-color2:hover {
  color: #e9204f;
  background-color: #fff;
}

.st-black-version .tr-list li:before,
.st-black-version .tr-list li:after {
  border-color: #e9204f;
}

.st-black-version .st-progressbar-in,
.st-black-version .tr-list li:after {
  background: #e9204f;
}

.st-black-version .st-seperator-left-bar,
.st-black-version .st-seperator-right-bar {
  background: rgba(255, 255, 255, 0.8);
}

.st-black-version .st-image-box.st-style1,
.st-black-version .owl-carousel.st-owl-controler3 .owl-nav .owl-prev,
.st-black-version .owl-carousel.st-owl-controler3 .owl-nav .owl-next,
.st-black-version .st-blog.st-style1,
.st-black-version .owl-carousel.st-owl-controler4 .owl-nav .owl-prev,
.st-black-version .owl-carousel.st-owl-controler4 .owl-nav .owl-next {
  background-color: #1f1f22;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.st-black-version .owl-carousel.st-owl-controler1 .owl-dots button,
.st-black-version .owl-carousel.st-owl-controler3 .owl-dots button,
.st-black-version .owl-carousel.st-client-carousel .owl-dots button {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: #7d8289;
  margin: 0 5px;
}

.st-black-version .owl-carousel.st-owl-controler1 .owl-dots button.active,
.st-black-version .owl-carousel.st-owl-controler3 .owl-dots button.active,
.st-black-version .owl-carousel.st-client-carousel .owl-dots button.active {
  background-color: #e9204f;
}

.st-black-version .st-progressbar {
  height: 8px;
  width: 100%;
  overflow: hidden;
  background-color: #1f1f22;
  position: relative;
}

.st-black-version .st-team-member,
.st-black-version .st-price-card {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #1f1f22;
}

.st-black-version .st-gray-bg {
  background-color: #171719;
}

.st-black-version .st-hero-text.st-style1 .st-hero-subtitle,
.st-black-version .st-iconbox-text,
.st-black-version .st-section-heading.st-style2 p,
.st-black-version .tr-list li,
.st-black-version .st-image-box-text,
.st-black-version .st-funfact-title,
.st-black-version .st-member-designation,
.st-black-version .st-cta-text,
.st-black-version .st-about-text p,
.st-black-version .st-price-card-feature,
.st-black-version .owl-carousel.st-owl-controler2 .owl-prev:hover,
.st-black-version .owl-carousel.st-owl-controler2 .owl-next:hover,
.st-black-version .st-price span,
.st-black-version .st-blog.st-style1 .st-blog-text,
.st-black-version .st-blog.st-style1 .st-posted-by,
.st-black-version .st-blog.st-style1 .st-blog-btn:hover,
.st-black-version .st-contact-info-text,
.st-black-version .st-footer-text,
.st-black-version .st-newsletter-btn:hover {
  opacity: 0.7;
}

.st-black-version .owl-carousel.st-owl-controler3 .owl-nav .owl-prev:hover,
.st-black-version .owl-carousel.st-owl-controler3 .owl-nav .owl-next:hover,
.st-black-version .st-blog.st-style1 .st-blog-label,
.st-black-version .owl-carousel.st-owl-controler4 .owl-nav .owl-prev:hover,
.st-black-version .owl-carousel.st-owl-controler4 .owl-nav .owl-next:hover,
.st-black-version .st-footer-social-btn,
.st-black-version .st-contact-info.st-style1 ul li,
.st-black-version .st-iconbox.st-style1 .st-iconbox-icon {
  color: rgba(255, 255, 255, 0.7);
}

.st-black-version .st-footer-social-btn li a {
  color: #fff;
  opacity: 0.4;
}

.st-black-version .st-footer-social-btn li a:hover {
  opacity: 1;
}

.st-black-version .st-testimonial-quote {
  color: rgba(233, 32, 79, 0.4);
  background-color: hsl(0, 0%, 100%);
  opacity: 0.7;
}

.st-black-version .st-section-heading.st-style1.st-type1 {
  border-bottom-color: rgba(255, 255, 255, 0.3);
}

.st-black-version .st-subscribe-form input,
.st-black-version .st-contact-form input,
.st-black-version .st-contact-form textarea {
  border-color: rgba(255, 255, 255, 0.3);
  color: #fff;
}

.st-black-version .st-subscribe-form input:focus,
.st-black-version .st-contact-form input:focus,
.st-black-version .st-contact-form textarea:focus {
  border-color: rgba(255, 255, 255, 0.3);
}

.st-black-version .st-newsletter-btn {
  background-color: #e9204f;
}

.st-black-version .st-contact-info.st-style1 ul li i {
  color: rgba(255, 255, 255, 0.5);
}

.st-black-version .st-map-bar {
  background-color: #1f1f22;
}

.st-black-version .st-footer-social-btn a:hover,
.st-black-version .st-iconbox.st-style1:hover .st-iconbox-icon,
.st-black-version .st-desktop-nav .st-nav-list > li > a.active {
  color: #fff;
}

.st-black-version .st-copyright-text {
  color: rgba(255, 255, 255, 0.4);
  border-color: rgba(255, 255, 255, 0.3);
}

.st-black-version .st-content {
  background-color: #121214;
}

.st-black-version .st-mobile-nav {
  border-top: 1px solid #414141;
  background-color: #222;
}

.cmt-row.services-section {
  padding: 50px 0 35px;
}

/* .inner_pg-bg-img7 {
    background-image: url(../images/inner_pages/bg-image/row-bgimage-7.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
} */
.cmt-bg {
  position: relative;
}

.cmt-bgimage-yes .cmt-row-wrapper-bg-layer.cmt-bg-layer {
  background-color: #023182 !important;
}

.cmt-bg.cmt-bgimage-yes > .cmt-bg-layer {
  opacity: 0.9;
}

/* .aircon-bg-white-bootom {
    background-image: url(../images/bg-image/air-con-white.png);
    background-repeat: no-repeat;
    position: absolute;
    left: -54px;
    width: 50px;
    bottom: -45px;
    background-size: cover;
    height: 50px;
    z-index: 2;
    opacity: 0.4;
} */
.pt-30 {
  padding-top: 30px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.cmt-fid.cmt-fid-view-lefticon.style2 > div {
  vertical-align: top;
}

.cmt-fid-view-lefticon .cmt-fid-contents,
.cmt-fid-view-righticon .cmt-fid-icon-wrapper {
  padding-left: 15px;
  text-align: left;
}

.cmt-list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.cmt-list li {
  position: relative;
  padding-bottom: 10px;
  font-size: 17px;
  line-height: 26px;
}

.cmt-list.cmt-list-style-icon li i {
  position: absolute;
  left: auto;
  top: 5px;
  font-size: 16px;
  font-weight: 700;
}

.cmt-list.cmt-list-style-icon .cmt-list-li-content {
  display: inline-block;
  padding-left: 25px;
}

.section-title {
  position: relative;
  margin-bottom: 30px;
}

.section-title .title-header {
  padding-bottom: 10px;
}

.cmt-bg-layer {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.content-w3ls {
  max-width: 500px;
  margin: 0 auto;
  padding: 10px;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.icon {
  margin-bottom: 3em;
}

.content-bottom {
  padding: 0em 3em 25px;
  margin: 0 1em;
  position: relative;
  z-index: 1;
}

form .field-group {
  background: rgba(255, 255, 255, 0.25);
  border: 1px solid #a6a6a6;
  display: flex;
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  margin-bottom: 10px;
  padding: 2px 10px 2px 0;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

form .field-group span {
  flex: 1;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  width: 20%;
  -webkit-flex: 1;
  -ms-flex: 1;
  color: #555555;
  background: transparent;
  line-height: 45px;
  text-align: right;
}

form .field-group .wthree-field {
  flex: 4 50%;
  -webkit-box-flex: 4 50%;
  -moz-box-flex: 4 50%;
  -webkit-flex: 4 50%;
  -ms-flex: 4 50%;
}

.wthree-field input {
  padding: 10px 0 10px 15px;
  font-size: 16px;
  border: 1px solid #414141;
  font-weight: 400;
  color: #555555;
  border: none;
  background: transparent;
  box-sizing: border-box;
  width: 100%;
  outline: none;
}

.wthree-field1 input {
  padding: 10px 0 10px 15px;
  font-size: 17px;
  font-weight: 400;
  color: #fff;
  border: 1px solid #414141;
  letter-spacing: 1px;
  border: none;
  background: transparent;
  box-sizing: border-box;
  width: 100%;
  outline: none;
}

.wthree-field textarea,
.wthree-field select {
  padding: 10px 0 10px 15px;
  font-size: 17px;
  height: auto;
  font-weight: 400;
  color: #555555;
  border: 1px solid #414141;
  letter-spacing: 1px;
  border: none;
  background: transparent;
  box-sizing: border-box;
  width: 100%;
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #555555 !important;
}

option {
  background: white;
  color: black;
  padding: 4px 2px;
}

ul.list-login {
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}

.content-w3ls ul li {
  display: inline-block;
}

.main1 {
  /* background: url(./assets/img/bg-1.jpg) no-repeat center; */
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  position: relative;
  min-height: 100vh;
  padding: 10% 0;
}

.main2 {
  /* background: url(./assets/img/bg-2.jpg) no-repeat center; */
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  position: relative;
  min-height: 100vh;
  padding: 10% 0;
}

.main3 {
  /* background: url(./assets/img/bg-3.jpg) no-repeat center; */
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  position: relative;
  min-height: 100vh;
  padding: 3% 0;
}

.main4 {
  /* background: url(./assets/img/bg-4.jpg) no-repeat center; */
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  position: relative;
  min-height: 100vh;
  padding: 3% 0;
}

.main5 {
  /* background: url(./assets/img/bg-5.jpg) no-repeat center; */
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  position: relative;
  min-height: 100vh;
  padding: 10% 0;
}

li.switch-agileits {
  float: left;
}

ul.list-login li:nth-child(2) {
  float: right;
}

li:nth-child(2) a,
label.switch {
  font-size: 15px;
}

ul.list-login-bottom li:nth-child(1) a,
ul.list-login-bottom li:nth-child(2) a {
  text-transform: none;
}

ul.list-login-bottom li:nth-child(2) {
  float: right;
}

.slider.round {
  border-radius: 34px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 2px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 21%;
  background-color: rgba(255, 255, 255, 0.15);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider.round:before {
  border-radius: 50%;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

label.switch {
  position: relative;
  display: inline-block;
  height: 18px;
  padding-left: 3em;
  cursor: pointer;
  color: #ccc;
  font-weight: 400;
}

.switch input {
  display: none;
}

.Link {
  text-decoration: none !important;
}

.navbar__link--active {
  color: #023182;
  text-decoration: none !important;
}

.rowflex {
  display: flex;
}

.assets {
  color: white;
  text-transform: uppercase;
  font-size: 16px;
  margin-left: 30px;
  letter-spacing: 2px;
}

.content-w3ls1 {
  max-width: 800px;
  margin: 0 auto;
  padding: 10% 0;
}

form .field-group1 {
  background: rgba(255, 255, 255, 0.15);
  display: flex;
  width: 300px;
  margin-left: 30px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  margin-bottom: 20px;
  padding: 2px 10px 2px 0;
  border-radius: 35px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -ms-border-radius: 35px;
  -o-border-radius: 35px;
}

form .field-group2 {
  background: rgba(255, 255, 255, 0.15);
  display: flex;
  width: 100%;
  margin-left: 30px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  margin-bottom: 20px;
  padding: 2px 10px 2px 0;
  border-radius: 35px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -ms-border-radius: 35px;
  -o-border-radius: 35px;
}

form .field-group2 .wthree-field {
  flex: 4 50%;
  -webkit-box-flex: 4 50%;
  -moz-box-flex: 4 50%;
  -webkit-flex: 4 50%;
  -ms-flex: 4 50%;
}

form .field-group2 span {
  flex: 1;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  width: 20%;
  -webkit-flex: 1;
  -ms-flex: 1;
  color: #fff;
  background: transparent;
  line-height: 49px;
  text-align: right;
}

.signatureCanvas {
  border: 1px solid black;
  width: 500px;
  height: 150px;
}

.checkbillingaddress {
  color: black;
  font-size: larger;
}

.table-condensed > thead > tr > th {
  padding: 5px;
}

.table-condensed > tbody > tr > td {
  padding: 5px;
}

/* .pagination{
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
} */
.custom-select {
  width: 50px !important;
  background-image: url("https://cdn.datatables.net/1.11.3/images/sort_both.png");
  background-repeat: no-repeat;
  background-position: right;
}

/* label
{
font-weight: bold;
} */
.App {
  margin: 1rem auto;
  max-width: 1000px;
  padding: 1rem;
}

/* .show {
  display: block;
}

.hide {
  display: none;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0000003a;
    transition: opacity 0.2s ease;
  }
  
  .modal {
    width: 800px !important;
    position: relative;
    margin: 0 auto !important;
    padding: 10px !important;
    background: #fff !important;
    border-radius: 2px !important;
    transform: translateY(100%) !important;
    display:block !important;
    z-index:999 !important;
  } */

.react-calendar {
  max-width: 80% !important;
  max-height: 100% !important;
  background: white !important;
  border: 0 !important;
  line-height: 3em !important;
  line-break: loose !important;
  border-inline: none !important;
  font-size: large !important;
  padding: 5px !important;
}

.react-calendar__tile {
  padding: 20px 6.6667px !important;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__month-view__days,
.react-calendar__month-view__weekdays,
.react-calendar__navigation,
.react-calendar__year-view__months {
  width: 400px !important;
}

.datetime {
  margin-left: 20px;
}

rt-resizable-header -sort-desc:after {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  content: " ";
  border-top: 20px solid #000;
}

rt-resizable-header -sort-asc:after {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  content: " ";
  border-bottom: 20px solid #000;
}

.table .sort-desc {
  box-shadow: none !important;
}

.sort-desc:before {
  content: "▼";
  float: right;
}

.table .sort-asc {
  box-shadow: none !important;
}

.sort-asc:before {
  content: "▲";
  float: right;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: none !important;
}

.formError {
  color: red;
  font-size: 12px;
  margin-bottom: 1px;
}

.content {
  max-width: 800px;
  margin: 2% auto 5%;
  padding: 15px 15px 45px 15px;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* .modal-dialog {
    max-width: 1000px !important;
} */

.btn-secondary1 {
  color: #fff !important;
  background-color: #5c636a !important;
  border-color: #565e64 !important;
}

/* .input-wrapper * {
  
} */

.input-wrapper label {
  z-index: 99;
  line-height: 29px;
  padding: 2px;
  margin-left: 5px;
  position: absolute;
}

.input-wrapper input {
  text-indent: 35px;
}

.disabled-link {
  pointer-events: none;
}

.payment {
  border: 1px solid #f2f2f2;
  border-radius: 20px;
  background: #fff;
}

.payment_header {
  background: rgba(255, 102, 0, 1);
  padding: 20px;
  border-radius: 20px 20px 0px 0px;
}

.check {
  margin: 0px auto;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #fff;
  text-align: center;
}

.check i {
  vertical-align: middle;
  line-height: 50px;
  font-size: 30px;
}

.content {
  text-align: center;
}

.content h1 {
  font-size: 25px;
  padding-top: 25px;
}

.content a {
  width: 200px;
  height: 35px;
  color: #fff;
  border-radius: 30px;
  padding: 5px 10px;
  background: rgba(255, 102, 0, 1);
  transition: all ease-in-out 0.3s;
}

.content a:hover {
  text-decoration: none;
  background: #000;
}

.input-group-addon {
  padding: 0.5rem 0.15rem;
}

.add-on {
  padding: 0.5rem 0.75rem;
  border: 2px solid #e2e6e9;
}

.countdown-wrap {
  margin-top: 50px;
  padding: 0 20px;
  display: inline-block;
  background: #f65c50;
  border-radius: 3px;
}

.countdown {
  text-align: center;
  list-style: none;
  display: inline-block;
  margin: 10px 0 6px;
  padding: 0;
}

.countdown li {
  float: left;
  color: #fff;
  font-size: 20px;
}

.countdown li i {
  float: left;
  padding: 5px 8px 0 0;
  color: #fff;
}

.countdown li span {
  color: #fff;
  float: left;
  padding: 0 2px;
}

.countdown li .fa-question-circle {
  padding: 5px 0 0 8px;
}

.countdown li p {
  color: #fff;
  float: left;
  font-size: 20px;
  margin: 0;
  padding-right: 6px;
}

.set-sm-fit {
  position: relative;
  margin-top: 10px;
  bottom: 10px;
}

.sidebar-item {
  position: absolute;
  width: 100%;
  top: 125px;
}

.affix {
  position: fixed;
  top: 70px;
  /* z-index: 1030; */
}

.MuiAutocomplete-endAdornment {
  display: none;
}

.MuiOutlinedInput-root {
  position: relative;
  border-radius: 25px !important;
}

.slider:before {
  content: "";
  position: absolute;
  left: 0;
  background: rgba(0, 0, 0, 0.18);
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 0;
}

.slider-content {
  text-align: center;
}

.slider-content h1 {
  margin-bottom: 32px;
  margin-top: 0;
  text-shadow: 1px 1px 55px rgba(0, 0, 0, 0.47);
  letter-spacing: -2px;
}

.slider-content h3 {
  color: #fff;
  line-height: 1.3;
  font-size: 26px;
  text-shadow: 1px 1px 20px rgba(0, 0, 0, 0.37);
  font-weight: 400;
  max-width: 790px;
  margin: 0 auto;
}

.form-wrap {
  margin: 75px 0 0;
}

.btn-form i {
  vertical-align: middle;
}

.center-block h2 {
  text-align: center;
  color: #3f4b5e;
  margin: 0 0 30px;
}

.center-block h2 span {
  color: #45c3d3;
  border-bottom: 3px solid #45c3d3;
}

.center-block h6 {
  text-align: center;
  display: inline-block;
  color: #212529;
  font-weight: 400;
  margin: 0!important;
  line-height: 1.6;
}

.counter {
  max-width: 100%;
  height: 38px;
  border-right: 2px solid #e2e6e9;
  border-left: 2px solid #e2e6e9;
  border-bottom: 2px solid #e2e6e9;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: bg_base;
}

.counter__item {
  position: relative;
  float: left;
  width: 30%;
  height: 100%;
  cursor: pointer;
}

.counter__item--center {
  width: 40%;
  border-right: 2px solid #e2e6e9;
  border-left: 2px solid #e2e6e9;
}

.counter__minus,
.counter__plus {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 10px 0;
  cursor: pointer;
  color: #e2e6e8 !important;
}

.counter__minus:hover,
.counter__plus:hover {
  color: #10b2e7 !important;
}

.counter__value {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  padding: 0;
  width: 100%;
  font-size: 17px;
  font-weight: 700;
  color: #10b2e7;
  border: none;
  text-align: center;
  outline: none;
  background-color: #fff;
}

.our-client {
  padding: 26px 0;
  background: #f6fbfc;
  border-bottom: 2px solid #d0f0f4;
  text-align: center;
}

.our-client span {
  color: #7e8388;
  text-align: center;
  position: relative;
  padding: 0 70px;
}

.our-client span:before {
  content: "";
  position: absolute;
  width: 50px;
  border-bottom: 1px solid #7e8388;
  top: 50%;
  left: 0;
}

.our-client span:after {
  content: "";
  position: absolute;
  width: 50px;
  border-bottom: 1px solid #7e8388;
  top: 50%;
  right: 0;
}

.our-client ul {
  padding: 0;
  margin: 20px 0 0;
}

.our-client ul li {
  list-style: none;
  float: left;
  margin: 0 25px;
}

.howit-wrap {
  text-align: center;
  margin: 70px 0 0;
}

.howit-wrap span {
  font-size: 130px;
  color: #45c3d3;
}

.howit-wrap h4 {
  padding: 32px 12px 0;
}

.howit-wrap p {
  font-size: 15px;
  color: #8b9aab;
}

.testimonial {
  background: #f6fbfc;
}

.testi-block {
  position: relative;
  background: #fff;
  border: 3px solid #d0f0f4;
  text-align: center;
  padding: 29px;
  border-radius: 3px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.testi-block i {
  font-size: 30px;
  color: #d0f0f4;
  margin: 0 0 22px;
}

.testi-block:after,
.testi-block:before {
  top: 100%;
  left: 53.5%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.testi-block p {
  font-size: 18px;
}

.testi-block:hover {
  -webkit-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  transform: translateY(-4px);
}

.testi-block:after {
  border-color: transparent;
  border-top-color: #fff;
  border-width: 20px;
  margin-left: -31px;
}

.testi-block:before {
  border-color: transparent;
  border-top-color: #d0f0f4;
  border-width: 25px;
  margin-left: -36px;
}

.testi-title {
  text-align: center;
  margin: 46px 0 0;
}


figure.effect-lily img {
  max-width: none;
  width: -webkit-calc(100% + 50px);
  width: calc(100% + 50px);
  opacity: 0.7;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  -o-transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(-40px, 0, 0);
  transform: translate3d(-40px, 0, 0);
}

figure.effect-lily figcaption {
  text-align: left;
}

figure.effect-lily figcaption > div {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2em;
  width: 100%;
  height: 50%;
}

figure.effect-lily h2,
figure.effect-lily p {
  -webkit-transform: translate3d(0, 40px, 0);
  transform: translate3d(0, 40px, 0);
}

figure.effect-lily h2 {
  -webkit-transition: -webkit-transform 0.35s;
  transition: -webkit-transform 0.35s;
  -o-transition: transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
}

figure.effect-lily p {
  color: rgba(255, 255, 255, 0.8);
  opacity: 0;
  -webkit-transition: opacity 0.2s, -webkit-transform 0.35s;
  transition: opacity 0.2s, -webkit-transform 0.35s;
  -o-transition: opacity 0.2s, transform 0.35s;
  transition: opacity 0.2s, transform 0.35s;
  transition: opacity 0.2s, transform 0.35s, -webkit-transform 0.35s;
}

figure.effect-lily:hover img,
figure.effect-lily:hover p {
  opacity: 1;
}

figure.effect-lily:hover img,
figure.effect-lily:hover h2,
figure.effect-lily:hover p {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-lily:hover p {
  -webkit-transition-delay: 0.05s;
  -o-transition-delay: 0.05s;
  transition-delay: 0.05s;
  -webkit-transition-duration: 0.35s;
  -o-transition-duration: 0.35s;
  transition-duration: 0.35s;
}

.service-title {
  background: #45c3d3;
  text-align: center;
  padding: 40px 0;
}

.service-title h2 {
  color: #fff;
  font-size: 52px;
  font-weight: 400;
}

.service-title h2 span {
  border-bottom: 3px solid #fff;
}

.service {
  position: relative;
}

.service .container {
  pointer-events: none;
}

.service img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.service-link {
  margin: 50px 0 50px 50px;
}

.service-link h3 span {
  color: #45c3d3;
  border-bottom: 3px solid #45c3d3;
}

.service-link ul {
  margin: 40px 0 0;
  padding: 0;
}

.service-link ul li {
  list-style: none;
}

.service-link ul li i {
  color: #45c3d3;
  padding-right: 12px;
}

.service-link ul li a {
  color: #8b9aab;
  line-height: 2.6;
  pointer-events: all;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.service-link ul li a:hover {
  padding-left: 10px;
  text-decoration: none;
}

.service-link1 {
  margin-left: 0;
}

.img-objectfit {
  width: 50%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.img-objectfit_1 {
  width: 50%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.booking {
  background: #f6fbfc;
  border-top: 2px solid #d0f0f4;
}

.booking h6 {
  width: 55%;
}

.subscribe h6 {
  color: #fff;
  margin-bottom: 22px;
}

.social-icons {
  margin: 30px 0 0;
}

.social-icons a {
  width: 43px;
  height: 43px;
  background: #30abba;
  color: #98d5dd;
  border-radius: 50%;
  display: inline-block;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  opacity: 1;
}

.social-icons a i {
  text-align: center;
  display: block;
  padding: 13px 0;
}

.social-icons a:hover {
  text-decoration: none;
  opacity: 0.6;
}

.subscribe-form {
  padding: 17px 14px;
  border-radius: 2px;
  border: 2px solid #168d9b;
}

.subscribe-form::-webkit-input-placeholder {
  color: #45c3d3;
}

.form-inline .input-group {
  width: 100%;
}

.subscribe-btn {
  background: #168d9b;
  color: #fff;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  cursor: pointer;
}

.subscribe-btn:hover {
  background: #1eacbd;
}

.subscribe-btn span {
  font-size: 65px;
  line-height: 0.7;
}

.copyright {
  text-align: center;
  margin: 24px 0 0;
}

.copyright a {
  color: #fff;
  margin: 20px;
}

.header-style2 {
  padding: 12px 0;
  background: #fff;
  border-bottom: 1px solid #efefef;
}

.header-style2 img {
  margin: 8px 0 0;
  height: 49px;
}

.header-style2 span {
  font-weight: 700;
  float: right;
  font-size: 28px;
}

.landing-2 .slider-content h1 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 64px;
}

.landing-2 .slider-content h1 span {
  position: relative;
  padding: 0 0 12px;
}

.landing-2 .slider-content h3 {
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  display: inline-block;
}

.landing-2 .slider-content h3:before {
  position: absolute;
  content: "";
  border-top: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
  width: 120px;
  height: 8px;
  left: -136px;
  top: 50%;
}

.landing-2 .slider-content h3:after {
  position: absolute;
  content: "";
  border-top: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
  width: 120px;
  height: 8px;
  right: -136px;
  top: 50%;
}

.landing-2 .slider-content h2 {
  color: #fff;
  font-weight: 700;
  font-size: 100px;
  margin: 60px 0;
}

.landing-2 .slider-content h2 .oldprice {
  font-size: 35px;
  position: relative;
  vertical-align: middle;
}

.landing-2 .slider-content h2 small {
  font-size: 60px;
  vertical-align: top;
  line-height: 1.6;
}

.landing-2 .slider-content h2 .only {
  display: inline-block;
  color: #fff;
  margin: 0;
  font-weight: 400;
  font-size: 30px;
  bottom: 5px;
}

.file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.file-input::before {
  content: "Edit";
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}

.file-input:hover::before {
  border-color: black;
}

.file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.landing-2 .slider-content a {
  font-size: 28px;
  font-weight: 700;
  padding: 17px 28px;
  background: #2cce74;
  border: none;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.landing-2 .slider-content a:hover {
  background: #35e181;
}

.landing-2 .slider-content a:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.landing-2 .slider-content a span {
  font-size: 48px;
  vertical-align: top;
  line-height: 0.8;
}

.landing-2 .slider-content:focus {
  border: none;
}

.howit-work2 h2 {
  font-weight: 700;
}

.howit-work2 h2 span {
  color: #1075e7;
  border: none;
}

.howit-work2 h6 {
  color: #7e8388;
  font-weight: 100;
}

.howit-block {
  border-bottom: 1px solid #f1f1f1;
  padding: 0 0 70px;
  margin: 0 0 70px;
}

.howit-block .howit-wrap span {
  color: #71bf44;
}

.howit-block .howit-wrap h4 {
  font-weight: 700;
}

.howit-block .howit-wrap p {
  color: #7e8388;
  font-weight: 100;
}

.why-choose h2 {
  font-size: 40px;
  margin: 0 0 26px;
}

.why-choose p {
  font-size: 19px;
  text-align: center;
  line-height: 1.6;
  color: #7e8388;
  font-weight: 100;
}

.why-choose-list {
  padding: 0;
  margin: 28px 0 0;
}

.why-choose-list li {
  list-style: none;
  margin: 0 0 12px;
}

.why-choose-list li a i {
  color: #71bf44;
  border: 1px solid #71bf44;
  border-radius: 50%;
  padding: 10px;
  float: left;
}

.why-choose-list li p {
  margin-left: 55px;
  line-height: 2;
  color: #7e8388;
  font-size: 18px;
  font-weight: 100;
  text-decoration: none;
}

.testimonial {
  background: #fafafa;
}

.testimonial h3 {
  text-align: center;
  font-weight: 700;
  position: relative;
}

.testimonial h3:after {
  position: absolute;
  content: "";
  width: 60px;
  height: 6px;
  background: #71bf44;
  left: 50%;
  bottom: -17px;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
}

.review {
  position: relative;
  background: #fff;
  padding: 24px 0;
}

.review i {
  float: left;
  color: #fff;
  background: #bbb;
  padding: 12px;
  font-size: 22px;
}

.review p {
  padding: 0 28px 0 77px;
  font-size: 18px;
  color: #7e8388;
  line-height: 1.7;
  font-weight: 100;
  font-style: italic;
}

.review:after {
  position: absolute;
  content: "";
  left: 35px;
  width: 0;
  bottom: -30px;
  height: 0;
  border-style: solid;
  border-width: 22px 20px 9px 0;
  border-color: #fff transparent transparent transparent;
}

.reviewer-name {
  margin: 30px 0 0;
}

.reviewer-name p {
  color: #3f4b5e;
  font-weight: 700;
  margin: 0;
}

.reviewer-name p span {
  font-weight: 100;
}

.reviewer-name i {
  font-size: 12px;
  color: #f7c51e;
}

.speciality h2 {
  font-weight: 700;
}

.speciality h6 {
  color: #7e8388;
  width: 55%;
}

.speciality-img {
  position: realative;
}

.speciality-description {
  position: relative;
  margin: -82px auto 0;
  display: block;
  width: 76%;
  background: #fff;
  padding: 20px 12px;
}

.speciality-description h5 {
  text-align: center;
  color: #0f91ff;
  font-weight: 700;
  margin-bottom: 26px;
}

.speciality-description ul {
  margin: 0;
  padding: 0;
}

.speciality-description ul li {
  list-style: none;
  margin: 0 0 16px;
}

.speciality-description ul li span {
  color: #71bf44;
  font-size: 40px;
  vertical-align: top;
  line-height: 0.6;
  float: left;
}

.speciality-description ul li p {
  color: #7e8388;
  font-size: 17px;
  margin: 0 0 0 40px;
}

.guests {
  background: #fafafa;
}

.guests h2 {
  font-weight: 700;
  font-size: 41px;
}

.guests h6 {
  width: 55%;
  color: #7e8388;
}

.footer-style2 {
  text-align: center;
  padding: 50px 0;
}

.footer-style2 p {
  font-size: 13px;
  font-weight: 100;
  text-align: center;
  width: 55%;
  color: #7e8388;
  margin: 0 auto;
  line-height: 1.8;
}

.copyright {
  font-size: 16px;
  text-align: center;
  color: #7e8388;
  display: block;
}

.private {
  color: #f1f1f1;
  margin: 8px 0 0;
}

.private a {
  color: #71bf44;
  text-decoration: none;
  opacity: 1;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.private a:hover {
  opacity: 0.6;
}

.booking-details {
  background: transparent;
}

.booking-details h2 {
  font-size: 30px;
  font-weight: 600;
  margin: 0;
}

.booking-details h5 {
  font-size: 17px;
  color: rgb(68, 68, 68);
}

.booking-details h6 {
  margin: 10px auto 0;
}

.styled-radio {
  margin-bottom: 6px;
}

.set-sm-fit {
  width: 100%;
}

.preference-title {
  background: #45c3d3;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.preference-title h4 {
  color: #fff;
  padding: 28px 40px;
  font-weight: 600;
  margin: 0;
}

.preferences {
  padding: 8px 0px 28px;
}

.preferencesi {
  font-size: 24px;
  line-height: 24px;
  padding-right: 10px;
  top: 3px;
  position: relative;
  color: rgb(133, 140, 146);
}

.preference-radio p,
.scope_of_service p {
  font-size: 14px;
  font-weight: 500;
  margin: 10px 0;
}

.preferences1 {
  background-color: #fff;
  padding: 20px 40px;
  margin: 4px 0px;
}

.preferences2 {
  background-color: #fff;
  padding: 8px 15px;
  margin: 4px 0px;
}

.sm-box:last-child {
  margin-bottom: 0;
}

.styled-radio input[type="radio"] {
  position: absolute;
  visibility: hidden;
  display: none;
}

.styled-radio label {
  color: #3f4b5e;
  display: inline-block;
  cursor: pointer;
  font-weight: 700;
  padding: 7px 3px;
  width: 100%;
  border: 2px solid #e2e6e7;
  border-radius: 4px;
  text-align: center;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.styled-radio label:hover {
  border-color: #10b2e7;
  color: #10b2e7;
}

.styled-radio1 label {
  color: #fff !important;
  background: #ed0000 !important;
  display: inline-block;
  cursor: pointer;
  font-weight: 700;
  padding: 12px 3px;
  width: 100%;
  border: 0px !important;
  border-radius: 4px;
  text-align: center;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.styled-radio1 label:hover {
  color: #fff !important;
  background: #023182 !important;
}

.styled-radio2 label {
  color: #fff !important;
  background: #023182 !important;
  display: inline-block;
  cursor: pointer;
  font-weight: 700;
  padding: 12px 3px;
  width: 100%;
  border: 0px !important;
  border-radius: 4px;
  text-align: center;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.styled-radio2 label:hover {
  color: #fff !important;
  background: #ed0000 !important;
}

.styled-radio3 label {
  color: #fff !important;
  background: #ed0000 !important;
  display: inline-block;
  cursor: pointer;
  font-weight: 700;
  padding: 12px 3px;
  width: 100%;
  border: 0px !important;
  border-radius: 4px;
  text-align: center;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.styled-radio3 label:hover {
  color: #fff !important;
  background: #023182 !important;
}

.styled-radio input[type="radio"]:checked + label {
  color: #10b1e7 !important;
  border: 2px solid #10b1e7 !important;
}

.styled-radio input[type="radio"]:checked ~ span {
  display: none;
}

.styled-radio label + input[type="radio"] + label {
  border-left: solid 3px #675f6b;
}

.styled-radio .radio-group {
  border: solid 3px #675f6b;
  display: inline-block;
  margin: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.radio-disable label {
  color: #e2e6e7;
}

.radio-disable label:hover {
  border-color: #e2e6e7;
  color: #e2e6e7;
  cursor: no-drop;
}

.styled-radio2 {
  position: relative;
  overflow: hidden;
}

.styled-radio2 label {
  padding: 10px 15px;
  font-size: 18px;
  overflow: hidden;
}

.styled-radio2 label:hover + span {
  opacity: 0;
}

.styled-radio2 span {
  position: absolute;
  top: -19px;
  right: -20px;
  font-size: 9px;
  color: #fff;
  border-top-right-radius: 8px;
  padding: 4px;
  background: #ffc05e;
  width: 44px;
  height: 40px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.styled-radio2 span i {
  position: absolute;
  top: 27px;
  right: 17px;
}

.radio-offer {
  border: 2px solid #ffc05e !important;
}

.radio-offer:hover,
.radio-offer:active {
  border: 2px solid #10b1e7 !important;
}

.preference-about_home {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin: 38px 0 0;
  padding: 26px 0;
  display: inline-block;
  width: 100%;
}

.preference-about_home h4 {
  font-weight: 600;
  margin: 0;
  display: inline-block;
  line-height: 1.7;
}

.payment-icon {
  float: right;
  border: 1px solid #71bf44;
  padding: 3px 16px;
  border-radius: 5px;
}

.payment-icon i {
  color: #71bf44;
  font-size: 26px;
  vertical-align: top;
  line-height: 1.3;
}

.payment-icon p {
  display: inline-block;
  margin: 0 0 0 6px;
  color: #71bf44;
  font-size: 10px;
}

.date input {
  border: 2px solid #e2e6e9;
}

.preference-icon {
  text-align: center;
  border: 2px solid #e2e6e9;
  padding: 18px;
  margin: 30px 0 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.preference-icon img {
  width: 100px;
}

.preference-icon p {
  margin: 6px 0 0;
  font-weight: 700;
}

.optional-fade {
  color: #d4d4d4;
}

.optinal-textarea {
  border: 2px solid #d4d4d4;
  width: 100%;
  border-radius: 5px;
  height: 135px;
}

.houres .counter {
  border-top: 2px solid #e2e6e9;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.houres p {
  font-size: 12px;
  font-weight: 100;
  margin: 12px 0 0;
  display: inline-block;
}

.topics {
  padding: 0;
}

.topics li {
  list-style: none;
  text-align: center;
  padding: 0 8px 12px 0;
  width: 25%;
}

.topics li:hover,
.topics li:active,
.topics li:focus {
  border-color: #10b2e7;
}

.topics li:last-child {
  padding-right: 0;
}

.topics li label {
  padding: 10px 0;
  border: 2px solid #e2e6e8;
  border-radius: 5px;
  font-size: 14px;
  text-transform: none;
  letter-spacing: 0;
  margin: 0;
  color: #999;
  top: 50%;
  position: relative;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  display: block;
}

.topics li label img {
  width: 70px;
  display: block;
  margin: 0 auto;
}

.topics li label:hover {
  border-color: #10b2e7;
  cursor: pointer;
}

.checked-svg {
  margin: 0 auto;
  display: block;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  min-height: 75px;
}

.topics input[type="checkbox"] {
  display: none;
}

.topics input[type="checkbox"]:checked + label {
  border-color: #10b2e7;
  color: #10b2e7;
}

.topics input[type="checkbox"]:checked + label .checked-svg path {
  fill: #10b2e7;
}

.topics input[type="checkbox"]:checked + label:hover,
.topics input[type="checkbox"]:checked + label:active {
  background-color: #fff;
  border-color: #10b2e7;
}

.card-details {
  margin: 0 0 10px;
}

.card-number {
  border: 2px solid #e2e6e7;
  padding: 20px;
  width: 100%;
  border-radius: 5px;
}

.card-number::-webkit-input-placeholder {
  color: #d4d9e0;
}

.card-number:-ms-input-placeholder {
  color: #d4d9e0;
}

.card-number::placeholder {
  color: #d4d9e0;
}

.card-num {
  position: relative;
}

.visa-img {
  position: absolute;
  top: 34%;
  right: 8%;
}

.complete-booking {
  background: #023182;
  border-radius: 10px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  padding: 15px;
  text-align: center;
  display: block;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  -webkit-appearance: none !important;
  border: none;
}

.complete-booking:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.complete-booking:hover {
  color: #fff;
  text-decoration: none;
  background: #98e24f;
}

.complete-booking span {
  margin-right: 10px;
}

.fesilities {
  border: 0px solid #c1c1c1;
}

.fesilities ul {
  padding: 0;
  margin: 0;
}

.fesilities ul li {
  list-style: none;
  margin: 2px 0;
  width: 100%;
  display: inline-block;
}

.fesilities ul li label {
  font-weight: 400;
  float: left;
  line-height: 8px;
}

.fesilities ul li p {
  float: right;
  font-weight: 600;
  line-height: 10px;
  margin-bottom: 0px;
}

.fesilities h4 {
  font-weight: 600;
  padding: 2px 0;
  margin: 0;
  font-size: 14px;
}

.locat1 {
  height: 25px;
}

.border-t {
  border-top: 1px solid #e2e6e7;
  padding: 2px 0;
  margin: 4px 0;
}

.border-b {
  padding: 2px 0;
  border-bottom: 1px solid #e2e6e7;
  margin: 4px 0;
}

.fesilities h4 span {
  color: #ed0000;
  font-size: 22px;
  float: right;
  line-height: 0.8;
}

.chat-popup {
  position: fixed;
  bottom: 0;
  right: 0;
  border: 3px solid #f1f1f1;
  z-index: 9;
}

.form-container {
  max-width: 800px;
  background-color: white;
}

table.dataTable thead {
  background: #717883;
  color: #fff !important;
}

.aboutHome li {
  /* display: inline-block; */
  padding: 8px 6px;
  margin: 6px;
  text-align: center;
  border-bottom: 0px solid #c1c1c1;
}

/* .aboutHome li:first-child {
  width: 15%;
}

.aboutHome li:nth-child(2) {
  width: 15%;
}

.aboutHome li:nth-child(3) {
  width: 25%;
}

.aboutHome li:nth-child(4) {
  width: 25%;
} */

.product-quantity {
  position: absolute;
  top: 5px;
  left: 5px;
  /* min-width: 20px;
  line-height: 20px; */
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  padding: 0;
  text-align: center;
  color: #fff;
  font-size: 14px;
  display: inline-block;
  background-color: #4fb68d;
}

.product-quantity {
  position: initial;
  background: initial;
  display: table-cell;
}

.product-quantity .cart-plus-minus {
  display: inline-block;
  height: 30px;
  padding: 0;
  position: absolute;
  right: 10px;
  /* width: 80px; */
}
.product-quantity .cart-plus-ra {
  display: inline-block;
  padding: 0;
  position: absolute;
  right: 10px;
}

.product-quantity .cart-plus-minus .dec.qtybutton {
  border-right: 1px solid #e5e5e5;
  height: 30px;
  left: 0;
  padding-top: 4px;
  top: 0;
}

.product-quantity .cart-plus-minus .qtybutton {
  color: #666;
  cursor: pointer;
  float: inherit;
  font-size: 16px;
  margin: 0;
  position: absolute;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 20px;
  text-align: center;
}

.product-quantity .cart-plus-minus .disable {
  display: none;
}

.product-quantity .cart-plus-minus input.cart-plus-minus-box {
  color: #666;
  float: left;
  font-size: 14px;
  height: 30px;
  margin: 0;
  width: 80px;
  background: transparent none repeat scroll 0 0;
  border: 1px solid #e1e1e1;
  padding: 0;
  text-align: center;
}

.product-quantity .cart-plus-minus .inc.qtybutton {
  border-left: 1px solid #e5e5e5;
  height: 30px;
  padding-top: 4px;
  right: 0;
  top: 0;
}

.product-quantity p {
  text-transform: capitalize;
  font-size: 12px;
  line-height: 20px;
}

.product-quantity p span {
  margin-left: 8px;
  margin-right: 8px;
  top: 6px;
}

.booking-details1 h6 {
  margin-top: 0;
  position: relative;
  text-transform: capitalize;
}

.booking-details1 {
  height: 35px;
}

ul.custom-tab {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  margin-bottom: 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  /* width: max-content; */
}

ul.custom-tab li {
  margin-right: 10px;
  position: relative;
}

.letterspace {
  letter-spacing: 3px;
}

ul.custom-tab li {
  color: #b6b6b6;
  font-size: 18px;
  line-height: 22px;
  font-weight: 6500;
  text-transform: capitalize;
  padding: 12px 20px;
  position: relative;
}

ul.custom-tab li .active {
  color: #ed0000;
}

.bg-cray1 {
  /* padding: 14px 0; */
  padding: 6px 0;
}

.font-1 {
  font-size: 13px;
  padding: 5px 4px;
}

.form-control:focus {
  border-color: #ced4da !important;
  outline: 0;
  box-shadow: 0 0 0 0 rgb(255 255 255 / 25%) !important;
}

.CouponValue h4 {
  font-weight: 500;
  padding: 6px 0;
  margin: 0;
}

.CouponValue h4 span {
  color: #ed0000;
  font-size: 16px;
  float: right;
  margin-right: 5px;
}

.width100 {
  width: 100%;
}

#services {
  padding-left: 2rem;
  padding-right: 2rem;
}

.tabs {
  overflow: hidden;
}

.tab {
  width: 100%;
  color: #01358f;
  overflow: hidden;
}

.tab-label {
  display: flex;
  justify-content: space-between;
  padding: 8px 10px;
  background: #e9e9e9;
  border-bottom: 1px solid #b4b4b4;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 0;
  /* Icon */
}

.tab-label:hover {
  background: #ed0000;
  color: #ffffff;
}

.tab-label::after {
  content: "❯";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}

.tab-content {
  padding: 0px 10px;
  color: #2c3e50;
  transition: all 0.35s;
}

.tab .tab-content {
  padding: 0px 10px;
  color: #2c3e50;
  transition: all 0.35s;
  height: 0px;
}

.postbtn {
  border: none;
  background: none;
}

.tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 6px 10px;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
}

.tab-close:hover {
  background: #1451b9;
}

.tab input:checked + .tab-label {
  background: #1451b9;
  color: #ffffff;
}

.tab input:checked + .tab-label::after {
  transform: rotate(90deg);
}

.tab input:checked ~ .tab-content {
  max-height: 100vh;
  height: fit-content;
  padding: 1em;
}

.tab input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.flexCenter {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.search {
  width: 100%;
  position: relative;
  display: flex;
}

.searchTerm {
  width: 100%;
  border: 3px solid #00b4cc;
  border-right: none;
  padding: 5px;
  height: 20px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9dbfaf;
}

.searchTerm:focus {
  color: #00b4cc;
}

.searchButton {
  width: 40px;
  height: 36px;
  border: 1px solid #00b4cc;
  background: #00b4cc;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

.sidebar .active {
  color: red;
  font-weight: 600;
}

.tabbable-panel {
  border: 1px solid #eee;
  padding: 10px;
}

.tabbable-line > .nav-tabs {
  border: none;
  margin: 0px;
}

.tabbable-line > .nav-tabs > li {
  margin-right: 2px;
}

.tabbable-line > .nav-tabs > li > a {
  border: 0;
  margin-right: 0;
  color: #737373;
}

.tabbable-line > .nav-tabs > li > a > i {
  color: #a6a6a6;
}

.tabbable-line > .nav-tabs > li.open,
.tabbable-line > .nav-tabs > li:hover {
  border-bottom: 4px solid rgb(80, 144, 247);
}

.tabbable-line > .nav-tabs > li.open > a,
.tabbable-line > .nav-tabs > li:hover > a {
  border: 0;
  background: none !important;
  color: #333333;
}

.tabbable-line > .nav-tabs > li.open > a > i,
.tabbable-line > .nav-tabs > li:hover > a > i {
  color: #a6a6a6;
}

.tabbable-line > .nav-tabs > li.open .dropdown-menu,
.tabbable-line > .nav-tabs > li:hover .dropdown-menu {
  margin-top: 0px;
}

.tabbable-line > .nav-tabs > li.active {
  border-bottom: 4px solid #32465b;
  position: relative;
}

.tabbable-line > .nav-tabs > li.active > a {
  border: 0;
  color: #333333;
}

.tabbable-line > .nav-tabs > li.active > a > i {
  color: #404040;
}

.tabbable-line > .tab-content {
  margin-top: -3px;
  background-color: #fff;
  border: 0;
  border-top: 1px solid #eee;
  padding: 15px 0;
}

.portlet .tabbable-line > .tab-content {
  padding-bottom: 0;
}

.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 4px;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  padding: 3.5px 4px 3.5px 6px !important;
}

.custom-select-sm {
  width: auto !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.7;
}

.datepicker {
  visibility: hidden;
}

.react-multi-carousel-list object {
  height: 80px;
}

.react-datepicker__month-container {
  width: 310px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 1.3rem !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 2.5rem !important;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__month {
  margin: 0.4rem;
  font-size: 15px !important;
  text-align: center;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  /* background-color: #2579ba; */
  background-color: #01358f !important;
  color: #fff;
}

.social {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 11px;
  font-size: 20px;
  position: relative;
  color: #ffffff !important;
  width: 40px;
  border-radius: 4px;
}

.facebook {
  background: #3b5998;
}

.facebook:hover {
  background: #1e50ba;
}

.twitter {
  background: #00aced;
}

.twitter:hover {
  background: #008bbf;
}

.linked-in {
  background: #007bb6;
}

.linked-in:hover {
  background: #046593;
}

.cardImg {
  margin-top: 10px;
  width: 80px !important;
  margin-bottom: 10px;
}

.amount {
  height: 50px;
  width: 20px !important;
  border-radius: 10px;
  margin: 10px;
  text-align: center;
}

#data-table-points {
  align-self: center;
}

.popup-link {
  display: flex;
  flex-wrap: wrap;
}

.popup-link a {
  background: #333;
  color: #fff;
  padding: 10px 30px;
  border-radius: 5px;
  font-size: 17px;
  cursor: pointer;
  margin: 20px;
  text-decoration: none;
}

.popup-container {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform: scale(1.3);
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(21, 17, 17, 0.61);
  display: flex;
  align-items: center;
}

.popup-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
}

.popup-content p {
  font-size: 17px;
  padding: 10px;
  line-height: 20px;
}

.popup-content a.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  background: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
}

.popup-content a.close:hover {
  color: #333;
}

.popup-content span:hover,
.popup-content span:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.popup-container:target {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.popup-container h3 {
  margin: 10px;
}

/*End popup styles*/

/* Additional styles */
.popup-style-2 {
  transform: scale(0.3);
}

.popup-style-2:target {
  transform: scale(1);
}

.popup-style-3 {
  left: 100%;
}

.popup-style-3:target {
  left: 0;
}

.popup-style-4 {
  transform: rotate(180deg);
}

.popup-style-4:target {
  transform: rotate(0deg);
}

.popup-style-5 {
  top: 100%;
}

.popup-style-5:target {
  top: 0;
}

.popup-style-6 {
  transform: scale(15.3);
}

.popup-style-6:target {
  transform: scale(1);
}

.popup-style-7 {
  transform: skewY(180deg);
  transition: all 0.7s ease-in-out;
}

.popup-style-7:target {
  transform: skewY(0deg);
}

.tab {
  float: left;
  border-right: 1px solid #ccc;
  width: 30%;
  border-radius: 10px 0 0 10px;
}

.tab .text-right {
  float: right;
}

.tab a {
  display: block;
  background-color: inherit;
  padding: 12px 16px;
  width: 90%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 17px;
  margin: 1%;
  border-radius: 4px;
}

.tab a:hover {
  background-color: #bee9f7;
}

.tab a.active {
  background-color: #dadada;
}

.tabcontent {
  float: left;
  padding: 0px 12px;
  width: 70%;
  border-left: none;
  border-radius: 0;
}

.tabcontent h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
}

.tabcontent h5 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}

.tabcontent h6 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}

.tabcontent p {
  color: #454545;
}

.modal-top {
  margin-top: 10%;
}

.modal-dialog h4 {
  text-align: center;
  font-size: 26px;
  margin: 30px 0 -15px;
}

.modal-headd {
  background: #f44236;
}

.modal-dialog .icon-box1 {
  color: #ffffff;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  z-index: 9;
  background: #e62e2c;
  padding: 15px;
  text-align: center;
  border: 10px solid #e89594;
}

.modal-head {
  background: #82f3d7;
}

.icons-1 {
  font-weight: normal;
  font-style: normal;
  font-size: 34px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
}

.modal-dialog .icon-box {
  color: #ffffff;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  z-index: 9;
  background: #2bd994;
  padding: 15px;
  text-align: center;
  border: 10px solid #a7ecd7;
}

/* /appoinment-status-button-look-/ */
.booked {
  padding: 5px;
  border-radius: 4px;
  display: inline;
  margin: auto;
  align-items: center;
  color: #ffffff;
  border: none;
  outline: none;
  background-color: #dc3535;
  justify-content: center;
}
.booked1 {
  padding: 5px;
  border-radius: 4px;
  display: inline;
  margin: auto;
  align-items: center;
  color: #ffffff;
  border: none;
  outline: none;
  background-color: #557153;
  justify-content: center;
}

.assigned {
  padding: 5px;
  border-radius: 4px;
  display: inline;
  margin: auto;
  align-items: center;
  color: #ffffff;
  border: none;
  outline: none;
  background-color: #557153;
  justify-content: center;
}

.closed {
  padding: 5px;
  border-radius: 4px;
  display: inline;
  margin: auto;
  align-items: center;
  color: #ffffff;
  border: none;
  outline: none;
  background-color: #ff9551;
  justify-content: center;
}

.rescheduled {
  padding: 5px;
  border-radius: 4px;
  display: inline;
  margin: auto;
  align-items: center;
  color: #ffffff;
  border: none;
  outline: none;
  background-color: #9f73ab;
  justify-content: center;
}

.cancelled {
  padding: 5px;
  margin: auto;
  align-items: center;
  color: #ffffff;
  border-radius: 4px;
  border: none;
  outline: none;
  background-color: #ff9551;
}

hr {
  margin-top: 0.6rem !important;
  margin-bottom: 0.6rem !important;
}

.marquee-ht {
  padding: 4px 0;
  color: #fff;
}

.tab-content {
  height: 100%;
}
.card-eqhe {min-height: 126px;}
.card-nbox {
  background: #e1eaf9;
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 8px;
}
.card-nbox .img1 {
  height: 50px;
}

.card-nbox h4 {
  margin: 4px 0;
  padding: 2px 0;
  font-size: 17px;
  font-weight: 600;
  color: #0041b3;
}

.card-nbox h5 {
  margin: 4px 0;
  padding: 2px 0;
  font-size: 15px;
  font-weight: 600;
}

.card-nbox h6 {
  margin: 4px 0;
  padding: 2px 0;
  font-size: 13px;
  font-weight: 600;
}
.card-nbox p {
  margin: 0;
  padding: 2px 0;
  font-size: 13px;
}
.float-right1 {
  float: right;
  margin: 0 !important;
}

.st-pagination ul li.active {
  background-color: blue;
  color: white;
}

.chatlist .active {
  background: #c7dbfd;
  color: #023182;
}
.star-button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 30px !important;
}
.on {
  color: #007fff;
}
.off {
  color: #ccc;
}

.tabbo h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
}

.tabbo h5 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}

.tabbo h6 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}

.tabbo p {
  color: #454545;
}

