@media screen and (max-width: 992px) {
  .coollah_service_card_slide .card {
    width: 100%;
    max-width: 100%;
  }
  .coollah_service_lhs_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .coollah_service_card_sec {
    flex-direction: column;
    gap: 25px;
    align-items: center;
    justify-content: center;
    margin: 42px 0px 109px 0px;
  }

  .coollah_service_main_page {
    margin: 30px 0px;
  }

  .coollah_service_aircon_list {
    margin: 0px;
  }
  .coollah_header_wrapper {
    padding: 0px 35px 0px 35px;
  }
  .collah_header_search_wrapper {
    min-width: 500px;
  }

  .collah_header_search_wrapper .auto_complete_search {
    width: 100%;
  }

  .collah_header_search_wrapper > input[type="text"] {
    width: 100%;
  }

  .navbar-brand {
    display: flex;
    align-items: center;
    width: 120px;
  }

  .collah_header_search_wrapper {
    margin: 0px 15px;
  }

  .coollah_header_nav_links li {
    padding: 10px 0px;
  }
  .coollah_header_nav_links > li > a {
    height: auto;
  }

  .coollah_header_nav_links .collah_nav_hover_active {
    /* background-color: transparent; */
    color: var(--text-white--) !important;
    height: 61px;
    display: flex !important;
    justify-content: center;
  }

  .coollah_achieve_img_sec ul li {
    flex: 0 1 20%;
    max-width: 100%;
  }

  .coollah_service_provider_img_sec > .find_serv_sec:nth-child(1),
  .coollah_service_provider_img_sec > .find_serv_sec:nth-child(3),
  .coollah_service_provider_img_sec > .find_serv_sec:nth-child(5) {
    flex: 0 1 33.33%;
    width: 33.33%;
  }

  .coollah_service_provider_img_sec > .find_serv_sec:nth-child(2),
  .coollah_service_provider_img_sec > .find_serv_sec:nth-child(4) {
    display: none;
  }

  .coollah_freq_ask_accoridan_sec {
    margin: 36px 50px 0px 50px;
  }

  .coollah_major_support_img_wrap {
    margin-top: 30px;
  }

  .coollah_banner_card_sec {
    max-width: 763px;
  }

  .coollah_footer_sec_wrapper .row .col:nth-child(3),
  .coollah_footer_sec_wrapper .row .col:nth-child(4) {
    margin: 30px 0px;
  }

  .coollah_booking_man {
    width: 525px;
    bottom: 2px;
    left: -147px;
  }
  .coollah_booking_card {
    width: 90%;
    left: 0px;
  }
  .coollah_service_chem_img_container > button {width: 45%;
    margin: 0px 28%;}
}

@media screen and (max-width: 767px) {
  /* header */
  .coollah_header_wrapper {
    padding: 0 10px;
  }

  .collah_header_search_wrapper {
    min-width: 0px;
  }
  .collah_header_search_wrapper .auto_complete_search {
    /* width: 195px; */
  }
  .css-1h51icj-MuiAutocomplete-root
    .MuiAutocomplete-inputRoot
    .MuiAutocomplete-input {
    width: 10%;
  }

  .navbar-brand {
    display: flex;
    align-items: center;
    width: 85px;
  }

  .collah_header_search_wrapper {
    margin: 0px;
  }

  .collah_login_link_sec > a {
    font-size: 12px;
  }

  .coollah_header_nav_links > li > a {
    height: auto;
  }

  .coollah_footer_sec_wrapper {
    margin: 30px 15px 28px 15px;
  }
  /* header */

  /* banner */
  .collah_banner_content {
    left: 38px;
  }
  .coollah_achieve_img_sec ul li {
    flex: 0 1 50%;
    max-width: 100%;
    justify-content: center;
    display: flex;
  }
  .coollah_service_provider_img_sec > .find_serv_sec:nth-child(1),
  .coollah_service_provider_img_sec > .find_serv_sec:nth-child(3),
  .coollah_service_provider_img_sec > .find_serv_sec:nth-child(5) {
    flex: 0 1 100%;
    width: 100%;
  }

  .coollah_find_serv_sec_1 {
    margin: auto;
  }

  .map-frame {
    width: 100%;
    height: 400px;
  }

  .coollah_freq_ask_accoridan_sec {
    margin: 0;
  }

  .coollah_gurantee_sec {
    padding: 24px 0px 24px 0px;
  }

  .find_serv_sec {
    margin-bottom: 20px;
  }

  .coollah_app_store_sec {
    padding: 62px 20px 20px 20px;
  }

  .collah_banner_content > h5 {
    font-size: 16px;
  }

  .collah_banner_content > h2 {
    font-size: 26px;
  }

  .coollah_banner_card_sec {
    max-width: 320px;
  }

  .coollah_login_card {
    padding: 32px 15px 32px 32px;
    margin: 10px;
  }
  .coollah_why_choose_content_img_sec {
    width: 80px;
    height: 80px;
  }

  .coollah_contact_us_sec {
    position: absolute;
    right: 20px;
  }

  .footer_responsive_wrapper {
    margin: 31px 0px;
  }
  .coollah_service_main_page {
    margin: 24px 33px;
  }
  .coollah_service_aircon_list {
    margin: 0 33px;
  }

  .coollah_service_absolute_content {
    left: 10px;
  }
  .coollah_service_main_form > input {
    /* width: 75%; */
  }
  .coollah_service_main_form > button {
    width: 100px;
  }
  /* Coollah_booking_Section */
  .coollah_booking_sec_wrapper {
    width: 100%;
    /* height: 1187px; */
  }
  .coollah_booking_man {
    top: 466px;
    left: -147px;
  }
  .coollah_booking_card {
    width: 90%;
    /* height: 1004px; */
    display: block;
  }
  .coollah_booking_card_title {
    padding-top: 38px;
  }
  .coollah_booking_input,
  .coollah_booking_select_input {
    width: 100%;
  }
  .coollah_booking_card_body {
    width: 100%;
    padding: 0px 21px 12px 21px;
  }
}
@media screen and (max-width: 542px) {
  .coollah_booking_card {
    width: 420px;
    margin: 10px;
  }
  .coollah_booking_man {
    left: -207px;
  }
  .bg-dot{display: none;}
}

@media screen and (max-width: 420px) {
  .coollah_booking_card {
    width: 360px;
    margin: 10px;
  }
  .coollah_booking_man {
    left: -207px;
  }
.bg-dot{display: none;}
  .coollah_service_main_content{
    left: -202px;
  }

  .coollah_service_services > ul > li > .residental,
  .coollah_service_services > ul > li > .commercial{
    width: max-content;
    padding: 12px 10px 11px 11px;
    font-size: 10px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .list-itema-ban {width: 50%;}
  .coollah_why_choose_content_img_sec {
    width: 65px;
    height: 65px;
}
.bg-dot{display: none;}
.coollah_why_choose_content_img_sec {
    margin: 0 5px;
}
.coollah_contact_us_sec {
  width: 100%;
  max-width: 420px;
}
.collah_header_search_wrapper {
  width: 150px;
}
}
@media screen and (max-width: 280px) {
  .coollah_booking_card {
    width: 250px;
  }
  .coollah_booking_man {
    width: 400px;
    left: -50px;
  }
}
